<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                学生填报时间
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReportStudent:export'" @click="download">导出</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReportStudent:set'" @click="activation">设置已填报</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReportStudent:importStu'" @click="importDialog = true">导入已填报学生</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReportStudent:insertStu'" @click="addDialog = true">添加学生</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item label="姓名/学号" prop="studentNameOrNum">
                        <el-input v-model="queryBO.studentNameOrNum" placeholder="姓名/学号" style="width: 300px">
                            <el-button type="primary" slot="append" icon="el-icon-search"
                                       @click="queryBasic"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否激活" prop="enabled">
                        <el-select v-model="queryBO.enabled" @change="queryBasic" placeholder="请选择是否激活"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in enabledList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核状态" prop="checkStatus">
                        <el-select v-model="queryBO.checkStatus" @change="queryBasic" placeholder="请选择审核状态"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in checkStatusList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="550px"
                        max-height="550px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                        width="50px"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            prop="studentName"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="rollStatusS"
                            label="学籍状态">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            label="站点名称">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="startTime"
                            show-overflow-tooltip
                            label="开始时间">
                    </el-table-column>
                    <el-table-column
                            prop="endTime"
                            show-overflow-tooltip
                            label="结束时间">
                    </el-table-column>
                    <el-table-column
                            prop="enableds"
                            label="激活状态">
                    </el-table-column>
                    <el-table-column
                            prop="checkStatusS"
                            label="审核状态">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="添加填报信息"
                :visible.sync="addDialog"
                width="30%">
            <div>
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" @click="addDialog = false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveAndClose">保存后关闭</el-button>
                    </el-form-item>
                </el-form>
                <el-form style="width: 100%" :model="saveAndCloseBO" :rules="rulesBySave" ref="ruleFormTwo">
                    <el-form-item label="学号" style="width: 100%" prop="studentNo">
                        <el-input v-model="saveAndCloseBO.studentNo" placeholder="请输入学号"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="导入" :visible.sync="importDialog" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadbutton">下载模板</el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "fillInStudent",

        data() {
            return {
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    checkStatus: null,
                    studentNameOrNum: '',
                    enabled: null
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                checkStatusList: [{id: null, label: '全部'}, {id: 0, label: '等待审核'}, {id: 1, label: '审核通过'}, {
                    id: 2,
                    label: '驳回'
                }],//审核状态
                enabledList: [{id: null, label: '全部'}, {id: 0, label: '否'}, {id: 1, label: '是'}],//是否激活 0否 1是
                tableData: [],//主table
                totalOne: 0,//主table分页
                multipleSelection: [],
                rulesByTime: {
                    time: [
                        {type: 'array', required: true, message: '请选择日期', trigger: 'change'}
                    ]
                },
                addDialog: false,
                saveAndCloseBO: {
                    studentNo: ''
                },
                rulesBySave: {
                    studentNo: [
                        {required: true, message: '请输入学号', trigger: 'blur'}
                    ]
                },
                importDialog: false
            }
        },
        methods: {
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
              this.openLoadingView()
                this.$axios_supermall({
                    method: "post",
                    url: "hnjxjy-core/eduGraduateReportSet/excelImportEduGraduateReport",
                    data: forms,
                }).then((res) => {
                    if (res.data.code === 2000){
                        this.$message.success(res.data.data);
                        this.importDialog = false;
                        this.queryBasic();

                    }
                }).catch((err) => {
                });
            },
            downloadbutton(){
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateReportSet/downloadEduGraduateReportTemplate",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '学生填报时间模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);

                  this.$message.success("操作成功")
                })
            },
            activation() {
                if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
                    this.$message.warning("请至少选择一项");
                    return;
                }
                let ids = '';
                this.multipleSelection.forEach(m => {
                    ids += m.id + ','
                });
                ids = ids.substring(0, ids.length - 1);
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/updateServiceByEduGraduateReport', {ids: ids}).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("激活成功");
                        this.queryBasic();
                    }
                })
            },
            saveAndClose() {
                this.$refs['ruleFormTwo'].validate((valid) => {
                    if (valid) {
                        this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/updateEduGraduateReportByStudentNo', this.saveAndCloseBO).then(response => {
                            if (response.data.code === 2000) {
                                this.$refs.ruleFormTwo.resetFields();
                                this.addDialog = false;
                                this.queryBasic();
                            }
                        })
                    } else {
                        return false;
                    }
                })
            },
            download() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateReportSet/exportAllEduGraduateReport",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '学生填报时间.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.loadingView.close()
                  this.$message.success("操作成功")
                })
            },
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            }
            ,
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGraduateReportStudent:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }
                if (this.getIsNull(this.queryBO.checkStatus)) {
                  bo += '&checkStatus=' + this.queryBO.checkStatus;
                }
                if (this.getIsNull(this.queryBO.studentNameOrNum)) {
                  bo += '&studentNameOrNum=' + this.queryBO.studentNameOrNum;
                }
                if (this.getIsNull(this.queryBO.enabled)) {
                  bo += '&enabled=' + this.queryBO.enabled;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryAllEduGraduateReport?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                    }
                })
            }
            ,
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBasic();//基础查询
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
