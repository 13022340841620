<template>
  <div>
    <div class="header">
      <div class="content">
        <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">缴费管理</div>
        <div class="export">
          <div class="export_left">
            <el-button icon="el-icon-bottom" type="primary" @click="download" v-permission="'eduFeeStudentDetail:export'">导出缴费信息</el-button>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button type="primary" @click="addPrice">添加教材费</el-button>
          </div>
        </div>
        <div class="name_num">
          <el-form :model="search" ref="search" label-width="80px" inline>
            <el-form-item prop="siteId"  label="姓名/学号" >
              <el-input

                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/学号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="siteId"  label="函授站" >
              <el-select
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="siteId"  label="年级" >
              <el-select
                  clearable
                  v-model="search.gradeId"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="siteId"  label="层次" >
              <el-select
                  clearable
                  v-model="search.stageId"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="siteId"  label="专业" >
              <el-select
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="siteId"  label="学籍状态" >
              <el-select  clearable v-model="search.rollStatus"     placeholder="请选择">
                <el-option
                    v-for="item in this.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="siteId"  label="缴费形式" >
              <el-select v-model="search.payStyle" placeholder="请选择">
                <el-option
                    v-for="item in optionss"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="siteId"  label="开始时间" >
              <el-date-picker
                  v-model="search.startTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="siteId"  label="结束时间" >
              <el-date-picker
                  v-model="search.endTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="excle">
          <el-table
            :data="tableData"
            border
            :default-sort="{ prop: 'date', order: 'descending' }"
            slot="empty"
            @selection-change="handleSelectionChange"
            size="medium"
            height="500px"
            max-height="500px"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              :formatter="formatter"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              sortable
            ></el-table-column>
            <el-table-column
              prop="studentNo"
              label="学号"
              sortable
            ></el-table-column>
            <el-table-column
              prop="siteName"
              label="函授站"
              sortable
            ></el-table-column>
            <el-table-column
              prop="gradeName"
              label="年级"
              sortable
            ></el-table-column>
            <el-table-column
              prop="stageName"
              label="层次"
              sortable
            ></el-table-column>
            <el-table-column
              prop="profName"
              show-overflow-tooltip
              label="专业"
              sortable
            ></el-table-column>
            <el-table-column
              prop="confirmTime"
              show-overflow-tooltip
              label="缴费时间"
              sortable
            ></el-table-column>
            <el-table-column
              prop="amount"
              label="金额"
              sortable
            ></el-table-column>
            <el-table-column
              prop="payStyle"
              label="缴费形式"
              sortable
            ></el-table-column>
            <el-table-column
              prop="orderStatus"
              label="订单状态"
              sortable
            ></el-table-column>
            <el-table-column
              prop="remark"
              show-overflow-tooltip
              label="备注"
            ></el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="fyq_Pager">
            <div class="fyq_Pager_div">
              <div style="padding: 1rem">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="search.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
              </div>
              <!--    录入成绩  -->
              <el-dialog
                  title="添加教材费"
                  :visible.sync="dialogVisible"
                  width="40%"
                  style="text-align: left"
              >
                <el-form
                    :model="thearForm"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px"
                >
                  <el-col :span="12">
                    <el-form-item label="教材费：" prop="payAmount">
                      <el-input
                          v-model="thearForm.payAmount"
                          placeholder="请输入"
                          class="el-form-input-width"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-form>
                <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
        >保存后关闭</el-button
        >
      </span>
              </el-dialog>

              <el-dialog class="add" title="添加" :visible.sync="putDialog2"  center :before-close="putDialog2BeforeClose">
                <el-form :model="searchs" ref="searchs" label-width="110px" inline>
                  <el-form-item style="width:100%">
                    <el-button icon="el-icon-circle-plus-outline" type="primary" @click="putDialog2=false;rests(searchs)">关闭</el-button>
                    <el-button icon="el-icon-download" type='primary' @click='sumbit'>保存后关闭</el-button>
                    <el-button type="primary" @click="addPrice">查询</el-button>
                    <el-button type="primary" @click="rests(searchs)">重置</el-button>
                  </el-form-item>
                  <div>
                    <div>
                      <el-divider content-position="left">查询条件</el-divider>
                      <el-form-item label="姓名/学号" prop="name" style="">
                        <el-input  v-model="searchs.name" placeholder="学号/姓名" style="width: 160px"></el-input>
                      </el-form-item>
                      <el-form-item prop="siteId"  label="函授站">
                        <el-select
                            style="width: 160px"
                            clearable
                            v-model="searchs.siteId"
                            placeholder="请选择"
                            class="el-form-input-width"
                        >
                          <el-option
                              v-for="item in site"
                              :key="item.id"
                              :label="item.siteName"
                              :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="gradeId" label="年级" >
                        <el-select
                            style="width: 160px"
                            clearable
                            v-model="searchs.gradeId"
                            placeholder="请选择"
                            class="el-form-input-width"
                        >
                          <el-option
                              v-for="item in option"
                              :key="item.id"
                              :label="item.gradeName"
                              :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="stageId" label="层次">
                        <el-select
                            style="width: 160px"
                            clearable
                            v-model="searchs.stageId"
                            placeholder="请选择"
                            class="el-form-input-width"
                        >
                          <el-option
                              v-for="item in eduStage"
                              :key="item.id"
                              :label="item.stageName"
                              :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="专业" prop="profId" >
                        <el-select
                            style="width: 160px"
                            clearable
                            v-model="searchs.profId"
                            placeholder="请选择"
                        >
                          <el-option
                              v-for="item in eduProfession"
                              :key="item.id"
                              :label="item.profName"
                              :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div>
                      <el-divider content-position="left">添加教材费必填项</el-divider>
                      <el-form-item label="金额">
                        <el-input  v-model="thearForm.payAmount" placeholder="请输入(必填)" style="width: 160px"></el-input>
                      </el-form-item>
                      <el-form-item label="备注" >
                        <el-input  v-model="thearForm.info" placeholder="请输入(必填)" style="width: 160px"></el-input>
                      </el-form-item>
                      <el-form-item label="教材">
                        <el-select
                            style="width: 160px"
                            clearable
                            v-model="thearForm.textBookId"
                            placeholder="请选择"
                            class="el-form-input-width"
                        >
                          <el-option
                              v-for="item in eduTextbook"
                              :key="item.id"
                              :label="item.bookName"
                              :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <el-table :data="tableData2" border
                              size="medium"
                              :header-cell-style="{'text-align':'center'}"
                              :cell-style="{'text-align':'center'}"
                              height="298"
                              style="width: 100%"
                              @selection-change="handleSelectionChange">
                      <el-table-column type="selection" width="55"></el-table-column>
                      <el-table-column type="index" sortable width="60" label="序号">
                      </el-table-column>
                      <el-table-column prop="name" sortable label="姓名">
                      </el-table-column>
                      <el-table-column prop="studentNo" sortable label="学号">
                      </el-table-column>
                      <el-table-column prop="siteName" sortable label="站点名称">
                      </el-table-column>
                      <el-table-column prop="stageName" sortable label="证件号">
                      </el-table-column>
                      <el-table-column prop="gradeName" sortable label="年级">
                      </el-table-column>
                      <el-table-column prop="profName" sortable label="专业">
                      </el-table-column>
                    </el-table>
                  </div>
                  <div style="padding: 1rem" class="bottom">
                    <el-pagination
                        @size-change="handleSizeChange2"
                        @current-change="handleCurrentChange2"
                        :current-page="searchs.page"
                        :page-sizes="[5,10,15,20]"
                        :page-size="searchs.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total2"
                    >
                    </el-pagination>
                  </div>
                </el-form>
              </el-dialog>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>


export default {

  created() {
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
    this.handleSearch()
  },
  data() {
    return {
      addEduTextbook: false,


      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      currentPage: 1,
      value3: "",
      site:[],
      eduStage:[],
      search:{
        name:"",
        siteId:"",
        gradeId:"",
        stageId:"",
        profId:"",
        rollStatus:"",
        payStyle:"",
        startTime:"",
        endTime:"",
        page:1,
        pageSize:10,
      },
      thearForm:{
        textBookId: "",
        payAmount:"",
        info:""
      },
      dialogVisible:false,
      putDialog2:false,
      eduProfession:[],
      searchs: {
        siteId: "",
        name: "",
        money: "",
        remark: "",
        gradeId: "",
        stageId: "",
        profId: "",
        page: 1,
        pageSize: 10,
      },
      option:[],
      tableData2:[],
      total:0,
      total2:0,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "正式生",
        },
        {
          value: "1",
          label: "退学",
        },
        {
          value: "2",
          label: "休学",
        },
        {
          value: "3",
          label: "毕业",
        },
        {
          value: "4",
          label: "肄业",
        },
      ],
      optionss: [
        {
          value: "0",
          label: "学生充值",
        },
        {
          value: "1",
          label: "管理员录入",
        },
      ],
      tableData: [
      ],
      eduTextbook: [],
    };
  },
  methods: {
    handleSearch(){
      this.getPermission('eduFeeStudentDetail:list', () => {
            this.openLoadingView()
      let fromData = new FormData();
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("siteId",this.search.siteId);
      fromData.append("rollStatus",this.search.rollStatus);
      fromData.append("info",this.search.name);
      fromData.append("payStyle",this.search.payStyle);
      if(this.search.startTime!=""&&this.search.startTime!=null){
        let startTime = new Date(this.search.startTime).formatToLocalDateTime("yyyy-MM-dd").toString();
        fromData.append("startTime", startTime);
      }
      if (this.search.endTime!=""&&this.search.endTime!=null){
        let endTime = new Date(this.search.endTime).formatToLocalDateTime("yyyy-MM-dd").toString();
        fromData.append("endTime",endTime);
      }
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduFeeStudentDetail/list",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },
    rests(form){
      this.$refs.searchs.resetFields();
      this.thearForm = {
        textBookId: "",
        payAmount:"",
        info:""
      };
    },
    putDialog2BeforeClose(done){
      done();
      this.$refs.searchs.resetFields();
      this.thearForm = {
        textBookId: "",
        payAmount:"",
        info:""
      };
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    eduTextbookList(){
      this.$axios_supermallData.get('/hnjxjy-core/eduTextbook/list',).then((resp) => {
        this.eduTextbook = resp.data.data.list;
      });
    },
    addPrice(){
      this.eduTextbookList();
      let fromData = new FormData();
      this.openLoadingView()
      fromData.append("gradeId",this.searchs.gradeId);
      fromData.append("stageId",this.searchs.stageId);
      fromData.append("profId",this.searchs.profId);
      fromData.append("siteId",this.searchs.siteId);
      fromData.append("info",this.searchs.name);
      fromData.append("page",this.searchs.page);
      fromData.append("pageSize",this.searchs.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list",fromData).then(resp => {
        if (resp.data.code === 2000) {
          this.tableData2 = resp.data.data.list;
          this.total2 = resp.data.data.total;
          this.putDialog2 = true;
        }
      });
    },
    sumbit(){
      if(this.multipleSelection!=null&&this.multipleSelection!==""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        if(this.thearForm.info!=null&&this.thearForm.info!==""&&this.thearForm.payAmount!=null&&this.thearForm.payAmount!==""&&
        this.thearForm.textBookId!=null&&this.thearForm.textBookId!==""){
          this.$axios({
            url: "hnjxjy-core/eduFeeStudentDetail/insertEduFeeStudentDetail",
            headers: this.loginList,
            method: "post",
            data:{
              ids:arr,
              payAmount:this.thearForm.payAmount,
              textBookId: this.thearForm.textBookId,
              info:this.thearForm.info,
            }
          }).then((res) => {
            if (res.data.code === 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.putDialog2=false
            this.rests(null);
            this.handleSearch()
          })

              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
        }else{
          this.$message({
            type: "error",
            message: "必填项不能为空!",
          });
        }
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条!",
        });
      }

    },
    // 导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      if(this.search.siteId!=null&&this.search.siteId!=""){
        this.$axios({
          method: "get",
          url: "hnjxjy-core/eduFeeStudentDetail/export",
          responseType: "blob",
          headers: objPar,
          params: {
            "siteId":this.search.siteId,
          },
        })
            .then((res) => {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              let url = window.URL.createObjectURL(blob);
              window.location.href = url;
              this.$message.success("操作成功")
              this.loadingView.close()
            })
            .catch((err) => {
            });
      }else{
        this.$message.error("请选择函授站在进行导出操作！");
        this.loadingView.close()
      }
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    // rest(form){
    //   this.$refs.search.resetFields()
    // },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    // 分页器
    handleSizeChange(newPage) {
      this.search.pageSize = newPage;
      this.handleSearch();
    },
    handleCurrentChange(newSize) {
      this.search.page = newSize;
      this.handleSearch();
    },
    handleSizeChange2(vals) {
      this.searchs.pageSize = vals;
      this.addPrice();
    },
    handleCurrentChange2(vals) {
      this.searchs.page = vals;
      this.addPrice();
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },

    formatter(row, column) {
      return row.address;
    },
  },
};
</script>
<style scoped>
.header_top {
  width: 7%;
}
.header {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}
.content {
  text-align: left;
  width: 100%;
}
.course {
  background-color: #f1f1f1;
  line-height: 54px;
  padding-left: 10px;
}
.export {
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(164, 174, 224, 0.11);
  padding-bottom: 15px;
}
.export_left {
  padding-left: 15px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.name_num {
  background-color: rgba(164, 174, 224, 0.11);
}
.name_num >>> .el-input--suffix {
  width: 372px;
}
.excle >>> .el-table td,
.excle >>> .el-table th {
  text-align: center !important;
}
.choose {
  margin-bottom: 15px;
  margin-left: 60px;
}
.demonstration {
  display: inline-block;
  font-size: 16px;
  color: #000;
}
.el-input__suffix {
  right: 5px;
}
.el-input__inner {
  width: 100%;
}
.span {
  display: inline-block;
  width: 44px;
}
.excle {
  padding: 0 20px 20px 20px;
  background-color: rgba(164, 174, 224, 0.11);
}
</style>
