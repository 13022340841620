<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                设置申报时间
            </div>
            <div class="search">
                <el-form inline label-width="80px">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduDeclarationTimeSeting:updateTime'" @click="updateTime">更新时间</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduDeclarationTimeSeting:deletedTime'" @click="clearTime">清空时间</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-form inline label-width="100px">
                    <el-form-item label="申报开始时间">
                        <el-date-picker
                                v-model="timeMessage.applyStartTime"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="申报结束时间">
                        <el-date-picker
                                v-model="timeMessage.applyEndTime"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-tag type="success" style="font-size: 18px;">开始时间和结束时间设置后，当前学校只能在设置的时间段进行学位申报。</el-tag>
            </div>
        </div>
    </div>
</template>

<script>



    export default {
        name: "declarationTimeSetting",

        data() {
            return {
                timeMessage: {
                    applyEndTime:'',
                    applyStartTime:''
                }
            }
        },
        methods: {
            clearTime() {
                let bo = {
                    id: this.timeMessage.id
                };
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateDegreeSet/deleteTimeById', bo).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("清空成功");
                        this.queryBasic();
                    }
                })
            },
            updateTime() {
                // id:this.timeMessage.id,
              if(this.timeMessage.applyEndTime!=null&&this.timeMessage.applyEndTime!=""&&this.timeMessage.applyStartTime!=null&&this.timeMessage.applyStartTime!=""){
                let bo = {
                  applyEndTime:  new Date(this.timeMessage.applyEndTime).formatToLocalDateTime(),
                  applyStartTime: new Date(this.timeMessage.applyStartTime).formatToLocalDateTime()
                };
                  this.openLoadingView();
                  this.$axios_supermall.post('/hnjxjy-core/eduGraduateDegreeSet/updateById', bo).then(response => {
                    if (response.data.code === 2000) {
                      this.$message.success("修改成功");
                      this.queryBasic();
                    }
                  })
              }else{
                this.$message.error("所填时间不能为空")
              }
            },
            queryBasic() {
              this.getPermission('eduDeclarationTimeSeting:query', () => {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateDegreeSet/queryByAll').then(response => {
                  if (response.data.code === 2000) {
                    if (response.data.data!=null){
                      this.timeMessage = response.data.data;
                    }
                  }
                })
              });
            }
        },
        mounted() {
            this.queryBasic();//基础查询
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
