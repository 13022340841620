<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                毕业大作业-批次分配
            </div>
            <div class="search">
                <el-form inline>
                    <el-form-item>
                        <el-dropdown>
                            <el-button v-permission="'eduGraduateBatchDistribution:set'" type="primary">
                                设置批次<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item split-button @click.native="setting(1)">设置部分</el-dropdown-item>
                                <el-dropdown-item split-button @click.native="setting(2)">设置全部</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateBatchDistribution:list'" @click="queryBasic">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="$refs.queryForm.resetFields()">重置</el-button>
                    </el-form-item>
                    <div>
                        <div style="display: inline-block">
                            <el-form ref="queryForm" :model="queryByPageBO" label-width="80px">
                                <el-form-item label="姓名/学号" prop="studentNameOrNum" >
                                    <el-input
                                            style="width: 300px"
                                            v-model="queryByPageBO.studentNameOrNum"
                                            clearable
                                            @input="() => (queryByPageBO.studentNameOrNum = queryByPageBO.studentNameOrNum.trim())"
                                            placeholder="学号/姓名">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="函授站" prop="schoolId">
                                    <el-select v-model="queryByPageBO.schoolId" placeholder="请选择函授站" style="width: 300px">
                                        <el-option
                                                v-for="item in siteList"
                                                :key="item.id"
                                                :label="item.siteName"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="年级" prop="gradeId">
                                    <el-select v-model="queryByPageBO.gradeId" placeholder="请选择年级" style="width: 300px">
                                        <el-option
                                                v-for="item in gradeList"
                                                :key="item.id"
                                                :label="item.gradeName"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="层次" prop="stageId">
                                    <el-select v-model="queryByPageBO.stageId" placeholder="请选择层次" style="width: 300px">
                                        <el-option
                                                v-for="item in stageList"
                                                :key="item.id"
                                                :label="item.stageName"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="专业" prop="professionId">
                                    <el-select v-model="queryByPageBO.professionId" placeholder="请选择专业" style="width: 300px">
                                        <el-option
                                                v-for="item in profList"
                                                :key="item.id"
                                                :label="item.profName"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="设置批次" prop="status">
                                    <el-select v-model="queryByPageBO.status" placeholder="请选择专业" style="width: 300px">
                                        <el-option
                                                v-for="item in [{id:null,label:'全部'},{id:0,label:'未分配'},{id:1,label:'已分配'}]"
                                                :key="item.id"
                                                :label="item.label"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </el-form>
            </div>
            <div class="main_contain">
                <span>共选中 {{multipleSelection.length}} 条数据</span>
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="500px"
                        max-height="500px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                            label="序号"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="studentName"
                            label="姓名"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            label="函授站">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="contactPhone"
                            show-overflow-tooltip
                            label="联系电话">
                    </el-table-column>
                    <el-table-column
                            prop="chooseTime"
                            show-overflow-tooltip
                            label="选课时间">
                    </el-table-column>
                    <el-table-column
                            prop="batchName"
                            show-overflow-tooltip
                            label="毕业大作业批次">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryByPageBO.pageSize"
                        :current-page="queryByPageBO.pageNo"
                        :total="queryByPageBO.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="设置毕业设置批次"
                :visible.sync="dialogVisible"
                width="30%">
            <el-form inline label-width="80px">
                <el-form-item>
                    <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible = false">关闭
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addAndClose">
                        保存后关闭
                    </el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-form label-width="80px">
                    <el-form-item label="批次">
                        <el-select v-model="commitBO.batchId">
                            <el-option
                                    v-for="item in batchList"
                                    :key="item.id"
                                    :label="item.batchName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "bigExamAssign",

        data() {
            return {
                queryByPageBO: {
                    pageNo: 1,
                    pageSize: 10,
                    schoolId: null,
                    gradeId: null,
                    stageId: null,
                    professionId: null,
                    studentNameOrNum: '',
                    status: null,
                    total: 0
                },
                siteList: [],//函授站下拉框列表
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//数据列表
                batchList: [],//批次列表
                dialogVisible: false,
                commitBO: {
                    ids: '',
                    batchId: ''//毕业批次id
                },
                multipleSelection: []
            }
        },
        methods: {
            //region 重置
            resetBO() {
                this.queryByPageBO.siteId = null;
                this.queryByPageBO.gradeId = null;
                this.queryByPageBO.stageId = null;
                this.queryByPageBO.profId = null;
                this.queryByPageBO.studentNameOrNum = '';
            },
            //endregion
            //region 保存后关闭
            addAndClose() {
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateWorkBatch/updateAssignGraduateWorkBatchById', this.commitBO).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("设置成功");
                        this.dialogVisible = false;
                        this.queryBasic();
                    }
                })
            },
            //endregion
            //region 设置批次
            setting(type) {
                let ids = '';
                if (type === 1) {//设置部分
                    if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
                        this.$message.warning("请至少选择一项");
                        return;
                    }
                    this.multipleSelection.forEach(m => {
                        ids += m.id + ",";
                    })
                } else {//设置全部
                    this.tableData.forEach(t => {
                        ids += t.id + ',';
                    })
                }
                this.commitBO.ids = ids.substring(0, ids.length - 1);
                this.dialogVisible = true;
            },
            //endregion
            //region table选中数据
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = response.data.data;
                        this.gradeList.unshift({id: null, gradeName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = response.data.data;
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            },
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = response.data.data;
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            },
            //endregion
            //region 分配批次分页模糊查询
            queryBasic() {
              this.getPermission('eduGraduateBatchDistribution:list', () => {
                let params = '?page=' + this.queryByPageBO.pageNo + '&pageSize=' + this.queryByPageBO.pageSize;
                if (this.getIsNull(this.queryByPageBO.schoolId)) {
                    params += '&schoolId=' + this.queryByPageBO.schoolId
                }
                if (this.getIsNull(this.queryByPageBO.gradeId)) {
                    params += '&gradeId=' + this.queryByPageBO.gradeId
                }
                if (this.getIsNull(this.queryByPageBO.stageId)) {
                    params += '&stageId=' + this.queryByPageBO.stageId
                }
                if (this.getIsNull(this.queryByPageBO.professionId)) {
                    params += '&professionId=' + this.queryByPageBO.professionId
                }
                if (this.getIsNull(this.queryByPageBO.studentNameOrNum)) {
                    params += '&studentNameOrNum=' + this.queryByPageBO.studentNameOrNum;
                }
                if (this.getIsNull(this.queryByPageBO.status)) {
                    params += '&status=' + this.queryByPageBO.status;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/queryAllAssignGraduateWorkBatch' + params).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.queryByPageBO.total = response.data.data.total;
                    }
                })
                });
            },
            //endregion
            //region 查询批次下拉框
            queryBatchList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateWorkBatch/list').then(response => {
                    if (response.data.code === 2000) {
                        this.batchList = response.data.data;
                    }
                })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryByPageBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryByPageBO.pageNo = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.querySiteList();//查询函授站下拉框列表
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBasic();//基础查询
            this.queryBatchList();//查询批次下拉框
        }
    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .main_contain {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }
</style>
