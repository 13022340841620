import { render, staticRenderFns } from "./textbookCategoryManagement.vue?vue&type=template&id=8dfb4e52&scoped=true"
import script from "./textbookCategoryManagement.vue?vue&type=script&lang=js"
export * from "./textbookCategoryManagement.vue?vue&type=script&lang=js"
import style0 from "./textbookCategoryManagement.vue?vue&type=style&index=0&id=8dfb4e52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dfb4e52",
  null
  
)

export default component.exports