<template>
  <div>


    <div>
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        学费标准管理
      </div>
      <div class="search">
        <el-form :model="search" ref="search" class="demo-ruleForm" inline>
          <div class="search_content">
            <el-form-item>
              <el-button
                icon="el-icon-circle-plus-outline"
                type="primary"
                @click="addexam()"
                v-permission="'eduFeeStandard:add'"
                >添加</el-button>
              <el-button icon="el-icon-document-copy" type="primary"
                @click="copys" v-permission="'eduFeeStandard:batchCopy'">批量复制</el-button
              >
              <el-button icon="el-icon-upload2" type="primary" v-permission="'eduFeeStandard:importData'" @click="importExcle">导入</el-button>
              <el-button icon="el-icon-download" type="primary" v-permission="'eduFeeStandard:export'" @click="download">导出</el-button>
              <el-button icon="el-icon-delete" type="primary" v-permission="'eduFeeStandard:remove'" @click="removes">删除</el-button>
            </el-form-item>
          </div>
          <el-form-item prop="siteId"  label="函授站" >
            <el-select
                clearable
                v-model="search.siteId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in site"
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="gradeId" label="年级" >
            <el-select
                clearable
                v-model="search.gradeId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in option"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="stageId" label="层次" >
            <el-select
                clearable
                v-model="search.stageId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduStage"
                  :key="item.id"
                  :label="item.stageName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item  prop="profId" label="专业">
            <el-select
                clearable
                v-model="search.profId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduProfession"
                  :key="item.id"
                  :label="item.profName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="search_right" style="">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button type="primary" @click="rest(search)" class="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap">
          <el-table
          class="multipleTable"
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          size="medium"
          height="550px"
          max-height="550px"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column label="序号"  type="index" width="120">
            </el-table-column>
            <el-table-column prop="siteName" sortable label="函授站">
            </el-table-column>
            <el-table-column prop="gradeName" label="年级" sortable show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="stageName" label="层次" sortable ></el-table-column>
            <el-table-column prop="profName" label="专业" sortable ></el-table-column>
            <el-table-column prop="paymentType" label="缴费方式" sortable></el-table-column>
            <el-table-column prop="feeTotalAmount" label="应缴学费总额" sortable ></el-table-column>
            <el-table-column prop="paymentAmount" label="每次缴费金额" sortable ></el-table-column>
            <el-table-column  label="操作" width="170">
              <template slot-scope="scope">
              <el-button type="primary" @click="editor(scope.row)" v-permission="'eduFeeStandard:getOne'">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        <div class="fyq_Pager">
          <div class="fyq_Pager_div">
            <div style="padding: 1rem">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="search.page"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="search.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 添加 -->
      <el-dialog
          title="添加"
          :visible.sync="dialogVisible"
          width="50%"
          style="text-align: left"
      >
        <el-form
            size="small"
            :model="bo"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
        >
          <el-button style="margin-left: 50px" @click="offs" type="primary">关闭</el-button>
          <el-button style="margin-bottom: 40px" @click="add" type="primary">保存后关闭</el-button>
          <el-row>
            <el-col :span="12">
              <el-form-item  label="函授站"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.siteId"
                    placeholder="请选择（必填）"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="年级"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.gradeId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in this.option"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="层次"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.stageId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="专业"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.profId"
                    placeholder="请选择（必填）"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="应缴学费总额：" >
                <el-input-number
                    style="width: 200px;"
                    v-model="bo.feeTotalAmount"
                    :min=0
                    placeholder="请输入（必填）"
                    class="el-form-input-width"
                >
                  <el-button slot="append" icon="el-icon-plus"></el-button>
                  <el-button slot="append" icon="el-icon-minus"></el-button>
                </el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="每次缴费金额：">
                <el-input-number
                    style="width: 200px;"
                    v-model="bo.paymentAmount"
                    :min=0
                    placeholder="请输入（必填）"
                    class="el-form-input-width"
                >
                  <el-button slot="append" icon="el-icon-plus"></el-button>
                  <el-button slot="append" icon="el-icon-minus"></el-button>
                </el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 批量复制 -->
      <el-dialog
          title="批量复制"
          :visible.sync="dialogVisibles"
          width="40%"
          style="text-align: left;height: 500px"
      >
        <el-form
            size="small"
            :model="bo"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
        >
          <el-button style="margin-left: 50px" @click="offes" type="primary">关闭</el-button>
          <el-button style="margin-bottom: 40px" @click="copysadd" type="primary">保存后关闭</el-button>
          <el-row>
            <el-col :span="12">
              <el-form-item  label="年级"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.gradeId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in this.option"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="层次"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.stageId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <span style="margin-left: 270px; font-size: 20px; margin-top: 0px">批量复制到</span>
            <el-col style="margin-top: 20px" :span="12">
              <el-form-item  label="年级"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.toGradeId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in this.option"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col style="margin-top: 20px" :span="12">
              <el-form-item  label="层次"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.toStageId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 编辑 -->
      <el-dialog
        title="编辑"
        :visible.sync="dialogVisibleUpload1"
        width="40%"
      >
        <el-form
            size="small"
            :model="bo"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
        >
          <el-button style="margin-left: 50px" @click="offss" type="primary">关闭</el-button>
          <el-button style="margin-bottom: 40px" @click="updatepay" v-permission="'eduFeeStandard:edit'" type="primary">保存后关闭</el-button>
          <el-row>
            <el-col :span="12">
              <el-form-item  label="函授站"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.siteId"
                    placeholder="请选择（必填）"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="年级"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.gradeId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in this.option"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="层次"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.stageId"
                    class="el-form-input-width"
                    placeholder="请选择（必填）">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="专业"  prop="jobNo">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="bo.profId"
                    placeholder="请选择（必填）"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="应缴学费总额：" >
                <el-input-number
                    style="width: 200px;"
                    v-model="bo.feeTotalAmount"
                    :min=0
                    placeholder="请输入（必填）"
                    class="el-form-input-width"
                >
                  <el-button slot="append" icon="el-icon-plus"></el-button>
                  <el-button slot="append" icon="el-icon-minus"></el-button>
                </el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="每次缴费金额：">
                <el-input-number
                    style="width: 200px;"
                    v-model="bo.paymentAmount"
                    :min=0
                    placeholder="请输入（必填）"
                    class="el-form-input-width"
                >
                  <el-button slot="append" icon="el-icon-plus"></el-button>
                  <el-button slot="append" icon="el-icon-minus"></el-button>
                </el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton" v-permission="'eduFeeStandard:exportMB'">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>

    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch();
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
  },
  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisibleUpload1: false,
      dialogVisibleUploads: false,
      dialogVisible: false,
      dialogVisibles: false,
      radio: "1",
      title: "",
      site:[],
      eduStage:[],
      eduProfession:[],
      option:[],
      search: {
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        page: 1,
        pageSize: 10,
      },
      bo:{
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        toStageId: "",
        toGradeId: "",
        id: "",
        feeTotalAmount: "",
        paymentAmount: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableData: [],
        multipleSelection: [],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 1,
      currentPage1: 4,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },

  methods: {
    offs(){
      this.dialogVisible = false
    },
    offes(){
      this.dialogVisibles = false
    },
    offss(){
      this.dialogVisibleUpload1 = false
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    handleSearch() {
      this.getPermission('eduFeeStandard:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("siteId",this.search.siteId);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduFeeStandard/list",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },
    add(){
      let fromData = new FormData();
      fromData.append("siteId",this.bo.siteId);
      fromData.append("gradeId",this.bo.gradeId);
      fromData.append("stageId",this.bo.stageId);
      fromData.append("profId",this.bo.profId);
      fromData.append("feeTotalAmount",this.bo.feeTotalAmount);
      fromData.append("paymentAmount",this.bo.paymentAmount);
      if(this.bo.siteId!=null&&this.bo.siteId!=""&&this.bo.stageId!=null&&this.bo.stageId!=""&&this.bo.gradeId!=null&&this.bo.gradeId!=""&&this.bo.profId!=null&&this.bo.profId!=""){
        this.$axios_supermall.post("hnjxjy-core/eduFeeStandard/add",fromData).then(resp => {
          if (resp.data.code == 2000){
            this.$message.success("操作完成");
            this.handleSearch();
            this.dialogVisible = false;
          }
        })
      }else{
        this.$message.error("必填项不能为空");
      }
    },
    copysadd(){
      let fromData = new FormData();
      fromData.append("stageId",this.bo.stageId);
      fromData.append("gradeId",this.bo.gradeId);
      fromData.append("toStageId",this.bo.toStageId);
      fromData.append("toGradeId",this.bo.toGradeId);
      if(this.bo.toStageId!=null&&this.bo.toStageId!=""&&this.bo.stageId!=null&&this.bo.stageId!=""&&this.bo.gradeId!=null&&this.bo.gradeId!=""&&this.bo.toGradeId!=null&&this.bo.toGradeId!=""){
        this.$axios_supermall.post("hnjxjy-core/eduFeeStandard/batchCopy",fromData).then(resp => {
          if (resp.data.code == 2000){
            this.$message.success("操作完成");
            this.handleSearch();
            this.dialogVisibles = false;
          }
        })
      }else{
        this.$message.error("必填项不能为空");
      }
    },
    updatepay(){
      let fromData = new FormData();
      fromData.append("siteId",this.bo.siteId);
      fromData.append("gradeId",this.bo.gradeId);
      fromData.append("stageId",this.bo.stageId);
      fromData.append("profId",this.bo.profId);
      fromData.append("id",this.bo.id);
      fromData.append("feeTotalAmount",this.bo.feeTotalAmount);
      fromData.append("paymentAmount",this.bo.paymentAmount);
      if(this.bo.siteId!=null&&this.bo.siteId!=""&&this.bo.stageId!=null&&this.bo.stageId!=""&&this.bo.gradeId!=null&&this.bo.gradeId!=""&&this.bo.profId!=null&&this.bo.profId!=""){
        this.$axios_supermall.post("hnjxjy-core/eduFeeStandard/edit",fromData).then(resp => {
          if (resp.data.code == 2000){
            this.$message.success("操作完成");
            this.handleSearch();
            this.dialogVisibleUpload1 = false;
          }
        })
      }else{
        this.$message.error("必填项不能为空");
      }
    },
    removes(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduFeeStandard/remove",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.handleSearch()
          }
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条!",
        });
      }
    },
    copys(){
      this.dialogVisibles = true
    },
    importExcle(){
      this.dialogVisibleUploads = true
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      this.openLoadingView();
      this.$axios_supermall({
        method: "post",
        url: "hnjxjy-core/eduFeeStandard/importData",
        data: forms,
        responseType: 'blob'
      }).then((res) => {
        if (res.data.type === "text/xml"){
          this.dialogVisibleUploads = false;
          this.handleSearch();
          this.$message.success("处理成功");
        }else if (res.data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          this.$message.error("处理失败");
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '问题数据.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        this.loadingView.close();
        this.dialogVisibleUploads = false;
      }).catch((err) => {
        this.dialogVisibleUploads = false;
        this.handleSearch();
        this.$message.error(err)
      });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduFeeStandard/exportMB",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    // 导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduFeeStandard/export",
        responseType: "blob",
        headers: objPar,
        params: {
          "siteId":this.search.siteId,
          "gradeId":this.search.gradeId,
          "stageId":this.search.stageId,
          "profId":this.search.profId,
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {
      this.dialogVisible= true;
      this.bo.siteId=""
      this.bo.profId=""
      this.bo.stageId=""
      this.bo.gradeId=""
    },
    editor(row) {
     this.openLoadingView()
      this.$axios_supermall.get("hnjxjy-core/eduFeeStandard/getOne?id="+row.id
      ).then(resp => {
        this.dialogVisibleUpload1 = true;
        this.bo = resp.data.data;

      })
    },
    toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
}
.demo-ruleForm {
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  overflow-y: scroll;
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
/* search按钮布局 */
.search_content {
  display: flex;
  justify-content: space-between;
}
/* 右边按钮微调布局 */
.search_right {
  margin-right: 100px;
}
/* 重置按钮 */
.reset {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}
/* 下拉菜单样式 */
.el-dropdown {
  width: 75%;
  margin-left: 10px;
  border: 1px solid #ccc;
  line-height: 36px;
  background-color: #fff;
}
/* 下拉菜单向下的箭头调整 */
.el-icon-arrow-down {
  float: right;
  padding-right: 20px;
  line-height: 36px;
}
.all{
  color:rgb(64, 158, 255);
  display: block;
  text-align: left;
  font-size: 14px;
}
/* 底部分页样式 */
.bottom{
  background-color: #fff;
  text-align: left;
}
.el-pagination >>> .btn-prev {
  background-color: rgb(244, 244, 245);
  border: 1px solid rgb(244, 244, 245);
  padding-right: 3px;
  border-radius: 3px;
  color: rgb(197, 198, 205);
}
.el-pagination >>> .btn-next {
  background-color: rgb(244, 244, 245);
  border-radius: 3px;
  border: 1px solid rgb(244, 244, 245);
  padding-left: 3px;
  color: rgb(197, 198, 205);
}
/* 分页栏页面数字 */
.bottom>>>.el-pager li {
    margin: 0 5px 0 5px;
  background-color: rgb(244, 244, 245);
}
.bottom>>>.el-pager li.active {
    margin: 0 5px 0 5px;
  background-color: rgb(24, 144, 255);
  color: #fff;
}
.rigth>>>.el-table td,.rigth>>>.el-table th{
  text-align: center;
}
element.style {
  /* height: 60px; */
}
</style>
