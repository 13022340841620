<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                毕业大作业-免做审核
            </div>
            <div class="search">
                <el-form inline label-width="80px" :model="queryBO" ref="queryForm">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateWorkBigExamine:export'" @click="downLoad">导出</el-button>
                    </el-form-item>
                    <div style="float: right">
                        <el-form-item>
                            <el-button v-permission="'eduGraduateWorkBigExamine:list'" type="primary" @click="queryBasic">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="$refs.queryForm.resetFields()">重置</el-button>
                        </el-form-item>
                    </div>
                    <br/>
                    <el-form-item label="姓名/学号" prop="studentNameOrNum">
                        <el-input v-model="queryBO.studentNameOrNum" style="width: 300px"
                                  placeholder="姓名/学号"></el-input>
                    </el-form-item>
                    <el-form-item label="审批状态" prop="checkStatus">
                        <el-select v-model="queryBO.checkStatus" placeholder="请选择审批状态" style="width: 300px">
                            <el-option
                                    v-for="item in checkStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="函授站" prop="siteId">
                        <el-select v-model="queryBO.siteId" placeholder="请选择函授站" style="width: 300px">
                            <el-option
                                    v-for="item in siteList"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" placeholder="请选择专业" style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                        type="index"
                        width="60"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            label="审核">
                        <template slot-scope="scope">
                            <el-link  v-if="eduScoreStudentedit" type="primary" @click="examine(scope.row)">审核</el-link>
                          <span v-if="!eduScoreStudentedit">暂无权限</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            prop="schoolName"
                            label="函授站">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            show-overflow-tooltip
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="batchName"
                            show-overflow-tooltip
                            label="毕业设计批次">
                    </el-table-column>
                    <el-table-column
                            prop="teacherName"
                            label="指导教师">
                    </el-table-column>
                    <el-table-column
                            prop="checkStatus"
                            label="审核状态">
                        <template slot-scope="scope">
                            <span v-show="scope.row.checkStatus==0">等待审核</span>
                            <span v-show="scope.row.checkStatus==1">审核通过</span>
                            <span v-show="scope.row.checkStatus==2">审核驳回</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="免做审核"
                :visible.sync="dialog"
                width="50%">
            <div>
                <el-form inline label-width="80px">
                    <el-form-item>
                        <el-button @click="dialog = false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveAndClose">保存并关闭</el-button>
                    </el-form-item>
                </el-form>
                <el-form label-width="80px">
                    <el-form-item label="免做材料:">
                        <el-link type="primary" @click="look(1)">下载</el-link>
                        <el-link type="primary" style="margin-left: 150px" @click="look(2)">预览</el-link>
                    </el-form-item>
                    <el-form-item label="评语:">
                        <el-input
                            style="width: 200px"
                                v-model="examineCommit.rejectReason"
                                type="textarea"
                                placeholder="请输入内容">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="审核状态:">
                        <el-select  v-model="examineCommit.checkStatus">
                            <el-option
                                       v-for="item in [ {value: 0, label: '等待审核'}, {value: 1, label: '审核通过'},{value: 2, label: '审核驳回'}]"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="成绩:">
                        <el-input-number controls-position="right" v-model="examineCommit.freeScore"
                                         :min="0"></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "auditExamine",

        data() {
            return {
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    studentNameOrNum: '',
                    siteId: null,
                    gradeId: null,
                    stageId: null,
                    profId: null,
                    checkStatus: null//免做审核状态 0等待审核 1审核通过 2审核驳回
                },
                siteList: [],//函授站下拉框列表
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                checkStatusList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '等待审核'},
                    {value: 1, label: '审核通过'},
                    {value: 2, label: '审核驳回'}
                ],//免做审核状态
                totalOne: 0,
                examineBO: {},
                dialog: false,
              eduScoreStudentedit: false,
                examineCommit: {
                    checkStatus: 0, //审核状态 0待审核 1审核通过 2驳回
                    freeScore: 1, //成绩
                    rejectReason: "" //驳回原因
                }
            }
        },
      created() {
        this.getPermission('eduGraduateWorkBigExamine:set', () => {
          this.eduScoreStudentedit=true;
        })
      },
        methods: {
            look(type){
                if (type ==1){
                    window.open(this.examineBO.attachmentUrl)
                    // window.open(this.examineBO.paperStatusS)
                }else if (type ==2 ){
                    window.open(this.examineBO.attachmentUrl)
                }
            },
            //region 保存并关闭
            saveAndClose() {
              let bo = {
                "id": this.examineBO.id, //主键id
                "checkStatus": this.examineCommit.checkStatus, //审核状态 0待审核 1审核通过 2驳回
                "freeScore": this.examineCommit.freeScore, //成绩
                "rejectReason": this.examineCommit.rejectReason, //驳回原因
                "studentId": this.examineBO.studentId //学生id
              };
              this.openLoadingView();
              this.$axios_supermall.post('/hnjxjy-core/eduGraduateWorkBatch/examineEduGraduateBatchByEduScoreFree',bo).then(response=>{
                if (response.data.code === 2000){
                  this.dialog = false;
                  this.queryBasic();
                }
              });
            },
            //endregion
            //region 查询详情
            examine(row) {
              if (row.checkStatus !== 0){
                return this.$message.error("该学生已经审核!");
              }
              this.openLoadingView();
              this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/queryEduGraduateWorkStudentById?id=' + row.id).then(response => {
                if (response.data.code === 2000) {
                  this.examineBO = response.data.data;
                  this.examineCommit.rejectReason = this.examineBO.stuffInfo;
                  this.examineCommit.checkStatus = this.examineBO.checkStatus;
                  this.examineCommit.freeScore = this.examineBO.score;
                  if (this.examineBO.checkStatus==null){
                    this.examineCommit.checkStatus = 0;
                  }
                  this.examineCommit.checkStatus = row.checkStatus
                  this.dialog = true;
                }
              })
            },
            //endregion
            //region 导出
            downLoad() {
              this.openLoadingView()
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateWorkBatch/exportCheckExamineEduGraduateBatch",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '免做审核列表.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.loadingView.close()
                  this.$message.success("操作成功")
                })
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGraduateWorkBigExamine:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;

                if (this.getIsNull(this.queryBO.studentNameOrNum)) {
                    bo += '&studentNameOrNum=' + this.queryBO.studentNameOrNum;
                }
                if (this.getIsNull(this.queryBO.siteId)) {
                    bo += '&siteId=' + this.queryBO.siteId;
                }

                if (this.getIsNull(this.queryBO.gradeId)) {
                    bo += '&gradeId=' + this.queryBO.gradeId;
                }

                if (this.getIsNull(this.queryBO.stageId)) {
                    bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                    bo += '&profId=' + this.queryBO.profId;
                }
                if (this.getIsNull(this.queryBO.checkStatus)) {
                    bo += '&checkStatus=' + this.queryBO.checkStatus;
                }

                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/checkExamineEduGraduateBatch?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.totalOne = response.data.data.total;
                    }
                })
                });
            },
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = response.data.data;
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = response.data.data;
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = response.data.data;
                        this.gradeList.unshift({id: null, gradeName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.querySiteList();//查询函授站下拉框列表
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBasic();
        }
    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .main_contain {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }
</style>
