<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                毕业大作业-指导教师统计
            </div>
            <div class="search">
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" @click="download" v-permission="'eduGraduateBigTeacher:export'">导出</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item label="姓名/工号">
                        <el-input v-model="queryBO.nameOrCode" placeholder="教师姓名/教师工号" style="width: 500px" clearable>
                            <el-button v-permission="'eduGraduateBigTeacher:list'" type="primary" slot="append" icon="el-icon-search"
                                       @click="queryBasic"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item v-permission="'eduGraduateBetach:queryList'" label="毕业大作业批次">
                        <el-select  v-model="queryBO.batchId" style="width: 500px" @change="queryBasic">
                          <el-option :key="null" label="全部" :value="null">
                          </el-option>
                            <el-option
                                    v-for="item in batchList"
                                    :key="item.id"
                                    :label="item.batchName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                        type="index"
                            fixed
                        width="60"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            fixed
                            prop="name"
                            label="教师姓名">
                    </el-table-column>
                    <el-table-column
                            prop="jobNo"
                            label="教师工号">
                    </el-table-column>
                    <el-table-column
                            prop="sex"
                            label="性别">
                    </el-table-column>
                    <el-table-column
                            prop="teacherType"
                            label="教师类型">
                    </el-table-column>
                    <el-table-column
                            prop="tearcherAttr"
                            label="教师身份">
                    </el-table-column>
                    <el-table-column
                            prop="phoneNum"
                            label="移动电话">
                    </el-table-column>
                    <el-table-column
                            prop="telNum"
                            label="固定电话">
                    </el-table-column>
                    <el-table-column
                            prop="email"
                            label="邮箱">
                    </el-table-column>
                    <el-table-column
                            prop="guideNum"
                            label="指导人数">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>



    export default {
        name: "teacherStatistics",

        data() {
            return {
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    nameOrCode: '',
                    batchId: null
                },
                batchList: [],//批次列表
                tableData: [],
                total: 0
            }
        },
        methods: {
            //region 导出
            download() {
              let objPar = JSON.parse(localStorage.getItem("loginStrage"));
              this.openLoadingView()
              this.$axios({
                method: "get",
                url: "hnjxjy-core/eduGraduateWorkBatch/exportStatisticsDistributionOfTeachers",
                responseType: "blob",
                headers: objPar,
              })
                  .then((res) => {
                    let blob = new Blob([res.data], {
                      type: "application/vnd.ms-excel",
                    });
                    let url = window.URL.createObjectURL(blob);
                    window.location.href = url;
                    this.loadingView.close()
                    this.$message.success("操作成功")
                  })
                  .catch((err) => {
                  });
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGraduateBigTeacher:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.queryBO.nameOrCode != null && this.queryBO.nameOrCode != '') {
                    bo += '&nameOrCode=' + this.queryBO.nameOrCode;
                }
                if (this.queryBO.batchId != null && this.queryBO.batchId != '') {
                    bo += '&batchId=' + this.queryBO.batchId;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/statisticsDistributionOfTeachers?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.total = response.data.data.total;
                    }
                })
                });
            },
            //endregion
            //region 查询批次下拉框
            queryBatchList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateWorkBatch/list').then(response => {
                    if (response.data.code === 2000) {
                        this.batchList = response.data.data;
                    }
                })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.queryBatchList();
            this.queryBasic();
        }
    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .main_contain {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }

    .table_header .el-checkbox {
        display: none !important
    }
</style>
