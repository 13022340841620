<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                毕业生管理
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationStudentInfo:cancel'" @click="setGraduationById">取消毕业</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationStudentInfo:import'" @click="importDialog=true">导入毕业信息</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'exportScoreExcelByStudentId:score'" @click="download(1)">导出成绩单</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'exportScoreExcelByStudentId:registration'" @click="download(2)">导出毕业登记表</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'exportScoreExcelByStudentId:one'" @click="download(3)">导出学籍卡</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'exportScoreExcelByStudentId:exportStudentInfo'" @click="download(4)">导出</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'exportScoreExcelByStudentId:summary'" @click="statisticsDialog=true">汇总统计</el-button>
                    </el-form-item>
                    <!--                    <el-form-item>-->
                    <!--                        <el-button type="primary" @click="download">到处毕业证书发放表</el-button>-->
                    <!--                    </el-form-item>-->
                    <el-form-item>
                        <el-button type="primary" v-permission="'exportScorreExcelBySudentId:exportScore'" @click="download(5)">导出成绩汇总表</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item label="姓名/学号">
                        <el-input  v-model="queryBO.studentNameOrCode" placeholder="姓名/学号"
                                  style="width: 350px">
                            <el-button type="primary" slot="append" icon="el-icon-search"
                                       @click="queryBasic"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="函授站">
                        <el-select clearable v-model="queryBO.siteId" placeholder="请选择函授站" @change="queryBasic"
                                   style="width: 350px">
                            <el-option
                                    v-for="item in siteList"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select clearable v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 350px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select clearable v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 350px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" clearable @change="queryBasic" placeholder="请选择专业"
                                   style="width: 350px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始-结束 时间" prop="time">
                        <el-date-picker
                            style="width: 350px;"
                                v-model="queryBO.time"
                                type="datetimerange"
                                range-separator="至"
                                @change="queryBasic"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="500px"
                        max-height="500px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        width="80"
                        type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                        width="120"
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                        width="100"
                            prop="studentName"
                            label="姓名">
                        <template slot-scope="scope">
                            <el-link type="primary" @click="look(scope.row.studentId)">{{scope.row.studentName}}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="60"
                            label="性别">
                        <template slot-scope="scope">
                            <span v-show="scope.row.sex ==0">男</span>
                            <span v-show="scope.row.sex ==1">女</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="contactPhone"
                            label="手机号">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="certificateNo"
                            label="身份证号码">
                    </el-table-column>
                    <el-table-column
                        width="100"
                        show-overflow-tooltip
                            prop="siteName"
                            label="站点名称">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                        width="80"
                        show-overflow-tooltip
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="degreeCertNo"
                            label="证书号码">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="candidateNo"
                            label="考生号码">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="entranceTime"
                            label="入学时间">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="graduateDate"
                            label="毕业时间">
                    </el-table-column>
                    <el-table-column
                        width="160"
                            prop="graduateCondition"
                            label="是否符合毕业条件">
                    </el-table-column>
                    <el-table-column
                        width="160"
                            prop="applyCondition"
                            label="是否已应用毕业条件">
                    </el-table-column>
                    <el-table-column
                        width="100"
                            prop="graduateStatus"
                            label="毕业状态">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="createTime"
                            label="创建时间">
                    </el-table-column>
                    <el-table-column
                        width="120"
                            fixed="right"
                            label="设置毕业信息">
                        <template slot-scope="scope">
                            <el-link type="primary" v-if="eduScoreStudentedit" @click="settingInfo(scope.row)">设置毕业信息</el-link>
                          <span v-else>暂无权限</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="毕业信息"
                :visible.sync="dialog"
                width="60%">
            <div>
                <el-card class="box-card" shadow="always">
                    <div class="infinite-list-wrapper" style="overflow:auto;height:500px;">
                        <el-form label-width="80px" v-infinite-scroll infinite-scroll-disabled="disabled" class="list">
                            <el-form inline>
                                <el-form-item style="color: red;width: 23%">课程信息</el-form-item>
                                <el-form-item style="width: 23%">课程总数: {{studentInfo.courseNum}}</el-form-item>
                                <el-form-item style="color: green;width: 23%">已通过: {{studentInfo.okCourseNum}}
                                </el-form-item>
                                <el-form-item style="color: red;width: 23%">未通过: {{studentInfo.noCourseNum}}
                                </el-form-item>
                                <div v-if="studentInfo.eduFeeTuition!=null">
                                    <el-form-item style="color: red;width: 23%">费用信息</el-form-item>
                                    <el-form-item style="width: 23%">
                                        应缴费用: {{studentInfo.eduFeeTuition.amount}}
                                    </el-form-item>
                                    <el-form-item style="width: 23%">
                                        已交费用: {{studentInfo.eduFeeTuition.payAmount}}
                                    </el-form-item>
                                    <el-form-item style="width: 23%">
                                        未缴费用: {{studentInfo.eduFeeTuition.unpaidAmount}}
                                    </el-form-item>
                                </div>
                            </el-form>
                            <el-table
                                    border
                                    :data="studentInfo.eduCourseVos"
                                    style="width: 98%"
                                    size="medium"
                                    height="400px"
                                    max-height="400px"
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                            >
                                <el-table-column
                                        prop="courseName"
                                        label="课程名称">
                                </el-table-column>
                                <el-table-column
                                        prop="score"
                                        label="课程分数">
                                </el-table-column>
                                <el-table-column
                                        prop="period"
                                        label="学时">
                                </el-table-column>
                                <el-table-column
                                        prop="passFlagS"
                                        label="是否通过">
                                </el-table-column>
                                <el-table-column
                                        prop="semester"
                                        label="学期">
                                </el-table-column>
                                <el-table-column
                                        prop="stuScore"
                                        label="学分">
                                </el-table-column>
                            </el-table>
                        </el-form>
                    </div>
                </el-card>
            </div>
            <div slot="footer" class="dialog-footer" style="margin-top: 30px">
                <el-button type="primary" @click="dialog = false">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="导入" :visible.sync="importDialog" width="30%" :before-close="importDialogBeforeClose">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadTemplate">下载模板</el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
          <div style="margin-top: 10px" v-if="processingRecords.isTrue === true">
            <span style="margin-left: 35%;margin-right: 20%;">处理成功记录: {{processingRecords.successNum}}</span>
            <div style="margin-top: 10px;"></div>
            <span style="margin-left: 35%;margin-right: 20%;">处理失败记录: {{processingRecords.errNum}}</span>
            <div style="margin-top: 10px;"></div>
            <span style="margin-left: 35%;">是否处理完成: {{processingRecords.isComplete}}</span>
            <div style="margin-top: 10px;"></div>
            <span style="margin-left: 33%;margin-right: 20%;" v-if="processingRecords.isSuccess === 0">
              <el-button @click="uploadErreo" type="primary">下载处理失败记录</el-button>
            </span>
          </div>
        </el-dialog>
        <el-dialog
                title="设置毕业详情"
                :visible.sync="settingDialog"
                width="40%">
            <div>
                <el-card class="box-card" shadow="always">
                    <div class="infinite-list-wrapper" style="overflow:auto;">
                        <el-form label-width="80px" v-infinite-scroll infinite-scroll-disabled="disabled" class="list">
                            <el-form-item label="学号">
                                <el-input v-model="rowInfo.studentNo" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="姓名">
                                <el-input v-model="rowInfo.studentName" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="学籍状态">
                                <el-select v-model="settingBO.rollStatus">
                                    <el-option
                                            v-for="item in [
                                                {value:0,label:'正式生'},
                                                {value:1,label:'退学'},
                                                {value:2,label:'休学'},
                                                {value:3,label:'毕业'},
                                                {value:4,label:'肄业'}]"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="证书号码">
                                <el-input v-model="settingBO.degreeCertNo"></el-input>
                            </el-form-item>
                            <el-form-item label="入学日期">
                                <el-date-picker
                                        v-model="settingBO.admissionTime"
                                        type="datetime"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="毕业日期">
                                <el-date-picker
                                        v-model="settingBO.graduateDate"
                                        type="datetime"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="身份证号">
                                <el-input v-model="settingBO.certificateNo"></el-input>
                            </el-form-item>
                            <el-form-item label="性别">
                                <el-select v-model="settingBO.sex">
                                    <el-option
                                            v-for="item in [{value:0,label:'男'},{value:1,label:'女'}]"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>

                    </div>
                </el-card>
            </div>
            <div slot="footer" class="dialog-footer" style="margin-top: 30px">
                <el-button type="primary" @click="settingDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveAndClose">保 存</el-button>
            </div>
        </el-dialog>
        <el-dialog
                title="提示"
                :visible.sync="statisticsDialog"
                width="40%">
            <el-transfer
                    :titles="['未选中', '已选中']"
                    v-model="statisticsValue"
                    :data="statisticsData">
                <el-button class="transfer-footer" slot="right-footer" style="margin-left: 15px" type="primary"
                           size="small" @click="openDetails">统计
                </el-button>
            </el-transfer>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="statisticsDialog = false">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
                title="提示"
                :visible.sync="statisticsDetailsDialog"
                width="40%">
<!--                v-show="statisticsValue.find(s=>s=='姓名') !=null"-->
            <div>
                <el-table
                        :data="detailsTableDate"
                        border
                        style="width: 100%"
                        size="medium"
                        height="400px"
                        max-height="400px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
<!--                    <el-table-column-->
<!--                            prop="name"-->
<!--                            label="姓名">-->
<!--                    </el-table-column>-->
<!--                    <el-table-column-->
<!--                            prop="studentNo"-->
<!--                            label="学号">-->
<!--                    </el-table-column>-->
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="graduateNum"
                            label="已毕业学生数">
                    </el-table-column>
                    <el-table-column
                            prop="noGraduateNum"
                            label="未毕业学生数">
                    </el-table-column>
                    <el-table-column
                            prop="graduateRate"
                            label="毕业率">
                    </el-table-column>
                    <el-table-column
                            prop="totalNum"
                            label="总数">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChangeDetails"
                        @current-change="handleCurrentChangeDetails"
                        :page-size="detailsBO.pageSize"
                        :current-page="detailsBO.page"
                        :total="detailsBO.total">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="download(6)">导 出</el-button>
                <el-button type="primary" @click="statisticsDetailsDialog = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>



    export default {
        name: "graduateManagement",

        data() {
            return {
                eduScoreStudentedit: false,
                statisticsDetailsDialog: false,
                statisticsValue: [],
                statisticsData: [
                    // {key: '姓名', label: '姓名'},
                    // {key: '学号', label: '学号'},
                    {key: '年级', label: '年级'},
                    {key: '专业', label: '专业'},
                    {key: '层次', label: '层次'}
                ],
                settingBO: {
                    id: '',
                    sex: 0,
                    rollStatus: 0,
                    certificateNo: 0,
                    admissionTime: null,
                    graduateDate: null,
                    degreeCertNo: null,
                },
                rowInfo: {},
                settingDialog: false,
                importDialog: false,
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    siteId: null,
                    studentNameOrCode: '',
                    applyCondition: null,
                    time: []
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                siteList: [],//函授站下拉框列表
                totalOne: 0,//主table分页
                multipleSelection: [],
                dialog: false,
                applyConditionList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '否'},
                    {value: 1, label: '是'}
                ],
                studentInfo: {
                    eduFeeTuition: {}
                },
                statisticsDialog: false,
                detailsBO: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                detailsTableDate: [],
              processingRecords: {
                isTrue: false,
                isComplete: 0, //是否处理完成
                successNum: 0, //成功条数
                errNum: 0, //失败条数
                isSuccess: 1, //是否显示下载错误信息 0显示 1不显示
                allNum: 0 //总条数
              }
            }
        },
      created() {
        this.getPermission('eduGraduateYReport:see', () => {
          this.eduScoreStudentedit = true;
        })
      },
        methods: {
            queryDetails() {
                let str = ''
                this.statisticsValue.forEach(s => {
                    str += s + ',';
                });
                str = str.substring(0, str.length - 1);
                let formData = new FormData();
                formData.append("page",this.detailsBO.page)
                formData.append("pageSize",this.detailsBO.pageSize)
                formData.append("str",str)
                this.openLoadingView();
                this.$axios_supermallData.post('/hnjxjy-core/eduGraduateStudent/sumStatList?',formData).then(response => {
                    if (response.data.code === 2000) {
                        this.detailsTableDate = response.data.data.list;
                        this.detailsBO.total = response.data.data.total;
                    }
                })
            },
            openDetails() {
                if (this.statisticsValue == null || this.statisticsValue.length <= 0) {
                    this.$message.warning("请至少选中一行");
                    return;
                }
                this.statisticsDetailsDialog = true;
                this.queryDetails();
            },
            saveAndClose() {
              let formData = new FormData();
              formData.append("id",this.settingBO.id)
              formData.append("sex",this.settingBO.sex)
              formData.append("rollStatus",this.settingBO.rollStatus)
              formData.append("certificateNo",this.settingBO.certificateNo)
              let reg=new RegExp('-','g')
              if(this.settingBO.admissionTime!=null&&this.settingBO.admissionTime!==""){
                let date = new Date(this.settingBO.admissionTime);
                let admissionTime = date.formatToLocalDateTime().toString().replace(reg,'/');
                formData.append("admissionTime",admissionTime);
              }
              if(this.settingBO.graduateDate!=null&&this.settingBO.graduateDate!==""){
                let date = new Date(this.settingBO.graduateDate);
                let graduateDate = date.formatToLocalDateTime().toString().replace(reg,'/');
                formData.append("graduateDate",graduateDate);
              }
              formData.append("degreeCertNo",this.settingBO.degreeCertNo)
                this.$axios_supermallData.post('/hnjxjy-core/eduGraduateReportSet/setGraduationRegistrationById',formData).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("处理成功");
                        this.settingDialog = false;
                        this.queryBasic();
                    }
                })
            },
            settingInfo(row) {
                this.rowInfo = row;
                this.settingBO.id = row.id;
                this.settingBO.sex = row.sex;
                this.settingBO.rollStatus = row.rollStatus;
                this.settingBO.certificateNo = row.certificateNo;
                this.settingBO.admissionTime = row.entranceTime;
                this.settingBO.graduateDate = row.graduateDate;
                this.settingBO.degreeCertNo = row.degreeCertNo;
                this.settingDialog = true;
            },
            look(id) {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryStudentGraduationByStudentId?studentId=' + id).then(response => {
                    if (response.data.code === 2000) {
                        this.studentInfo = response.data.data;
                        this.dialog = true;
                    }
                })
            },
            beforeUpload(file) {
              this.processingRecords.isTrue = true;
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },

          uploadErreo(){
            this.openLoadingView()
            this.$axios_supermall({
              method: "get",
              url: "/hnjxjy-core/eduGraduateReportSet/downloadErrorGraduationInfo",
              responseType: "blob"
            }).then(res => {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              // let url = window.URL.createObjectURL(blob);
              // window.location.href = url;
              let downloadElement = document.createElement('a');
              let href = window.URL.createObjectURL(blob);
              downloadElement.href = href;
              downloadElement.download = '失败信息.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
              document.body.appendChild(downloadElement);
              downloadElement.click();
              document.body.removeChild(downloadElement);
              window.URL.revokeObjectURL(href);
              this.loadingView.close()
              this.$message.success("操作成功")
            })
          },

            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
              this.openLoadingView();
                this.$axios_supermall({
                    method: "post",
                    url: "hnjxjy-core/eduGraduateReportSet/exportDownloadTemplateGraduation",
                    data: forms,
                }).then((res) => {
                    if (res.data.code === 2000 ) {
                      this.processingRecords.isSuccess = res.data.data.isSuccess;
                      this.processingRecords.successNum = res.data.data.successNum;
                      this.processingRecords.errNum = res.data.data.errNum;
                      this.processingRecords.allNum = res.data.data.allNum;
                      this.processingRecords.isComplete = 1;
                      this.queryBasic();
                      this.loadingView.close();
                      this.$message.success("操作成功")
                    }
                }).catch((err) => {
                  this.processingRecords.isComplete = 0;
                  this.loadingView.close();
                });
            },
          importDialogBeforeClose(done) {
              done();
              this.processingRecords = {
                isTrue: false,
                isComplete: 0, //是否处理完成
                successNum: 0, //成功条数
                errNum: 0, //失败条数
                isSuccess: 1, //是否显示下载错误信息 0显示 1不显示
                allNum: 0 //总条数
            }
          },
            setEduGraduateConditionSet() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection)) {
                    this.$message.warning("请至少选择一列");
                    return;
                }
                let ids = this.getIds(this.multipleSelection, 'id');
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/applicationGraduationById', {ids: ids}).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("操作成功");
                        this.queryBasic();
                    }
                })
            },
            setGraduationById() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection)) {
                    this.$message.warning("请至少选择一列");
                    return;
                }
                this.$confirm('此操作将取消学生毕业, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = this.getIds(this.multipleSelection, 'id');
                    this.openLoadingView();
                    this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/setNoGraduationById', {ids: ids}).then(response => {
                        if (response.data.code === 2000) {
                            this.$message.success("操作成功");
                            this.queryBasic();
                        }
                    })
                }).catch(() => {

                });

            },
            download(type) {
                let url = '';
                let requestType = '';
                let fileName = '';
                let fileType = 'application/vnd.ms-excel';
                if (type == 1) {//导出成绩单，单个导出
                    if (this.getMultipleSelectionIsNull(this.multipleSelection)) {
                        this.$message.warning("条件导出,请至少选择一项");
                        return;
                    }
                    let ids = this.getIds(this.multipleSelection, 'studentId');
                    url = '/hnjxjy-core/eduGraduateReportSet/exportScoreExcelByStudentId?studentIds=' + ids;
                    requestType = 'get';
                    fileType = 'application/x-zip-compressed;charset=utf-8';
                    fileName = '成绩单.zip'
                } else if (type == 2) {//导出毕业登记表
                    if (this.multipleSelection == null || this.multipleSelection.length != 1) {
                        this.$message.warning("单次导出,请选择一项");
                        return;
                    }
                    url = '/hnjxjy-core/eduGraduateReportSet/exportGraduationRegistration?studentId=' + this.multipleSelection[0].studentId;
                    requestType = 'get';
                    fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'
                    fileName = '毕业登记表.doc'
                } else if (type == 3) {//导出学籍卡
                    if (this.multipleSelection == null || this.multipleSelection.length != 1) {
                        this.$message.warning("单次导出,请选择一项");
                        return;
                    }
                    url = '/hnjxjy-core/eduGraduateReportSet/exportStudentStatusCard?studentId=' + this.multipleSelection[0].studentId;
                    requestType = 'get';
                    fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'
                    fileName = '学籍卡.doc'
                } else if (type == 4) {//导出
                    url = '/hnjxjy-core/eduGraduateReportSet/exportAllGraduationStudentBySiteId';
                    requestType = 'get';
                    fileName = '毕业学生设置.xlsx'
                } else if (type == 5) {//导出成绩汇总表
                    url = '/hnjxjy-core/eduGraduateStudent/exportScoreHZ';
                    requestType = 'get';
                    fileName = '成绩汇总.xls'
                } else if (type == 6) {//汇总
                    let str = '';
                    this.statisticsValue.forEach(s => {
                        str += s + ',';
                    });
                    str = str.substring(0, str.length - 1);
                    url = '/hnjxjy-core/eduGraduateStudent/exportSumStat?str='+str;
                    requestType = 'get';
                    fileName = '汇总统计.xls'
                }
                this.openLoadingView();
                this.$axios_supermall({
                    method: requestType,
                    url: url,
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: fileType,
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = fileName;　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            downloadTemplate() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateReportSet/downloadTemplateGraduation",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '证书信息.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGrduationStudentInfo:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }
                if (this.getIsNull(this.queryBO.siteId)) {
                  bo += '&siteId=' + this.queryBO.siteId;
                }
                if (this.getIsNull(this.queryBO.studentNameOrCode)) {
                  bo += '&studentNameOrCode=' + this.queryBO.studentNameOrCode;
                }
                if (this.queryBO.time != null && this.queryBO.time.length == 2) {
                  bo += '&startDate=' + this.queryBO.time[0].formatToLocalDateTime('yyyy/MM/dd HH:mm:ss');
                  bo += '&endDate=' + this.queryBO.time[1].formatToLocalDateTime('yyyy/MM/dd HH:mm:ss');
                }

                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryAllGraduationStudentBySiteId?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                      this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                    }
                })
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            },
            //endregion
            // region 分页size
            handleSizeChangeDetails(val) {
                this.detailsBO.pageSize = val;
                this.queryDetails();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChangeDetails(val) {
                this.detailsBO.page = val;
                this.queryDetails();
            },
            //endregion
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.querySiteList();
            this.queryBasic();//基础查询
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
