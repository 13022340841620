<template>
  <div>


    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        已填报列表
      </div>
      <div class="search">
        <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
          <el-form-item>
            <el-button type="primary" v-permission="'eduGraduateYReport:yes'" @click="updateServiceCheck(1)">通过</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-permission="'eduGraduateYReport:no'" @click="updateServiceCheck">驳回</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-permission="'eduGraduateYReport:export'"  @click="download">导出</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"  v-permission="'eduGraduateYReport:list'" @click="queryBasic">查询</el-button>
          </el-form-item>
          <br/>
          <el-form-item label="姓名/学号" prop="studentNameOrNum">
            <el-input v-model="queryBO.studentNameOrNum" placeholder="姓名/学号"
                      style="width: 300px">

            </el-input>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                       style="width: 300px">
              <el-option
                  v-for="item in gradeList"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="层次" prop="stageId">
            <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                       style="width: 300px">
              <el-option
                  v-for="item in stageList"
                  :key="item.id"
                  :label="item.stageName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业" prop="profId">
            <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                       style="width: 300px">
              <el-option
                  v-for="item in profList"
                  :key="item.id"
                  :label="item.profName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否更改" prop="modifyStatus">
            <el-select v-model="queryBO.modifyStatus" @change="queryBasic" placeholder="请选择是否更改"
                       style="width: 300px">
              <el-option
                  v-for="item in modifyStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核状态" prop="checkStatus">
            <el-select v-model="queryBO.checkStatus" @change="queryBasic" placeholder="请选择审核状态"
                       style="width: 300px">
              <el-option
                  v-for="item in checkStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="main_contain">
        <el-table
            border
            @selection-change="selectionChange"
            :data="tableData"
            style="width: 100%"
            size="medium"
            height="500px"
            max-height="500px"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              type="index"
              width="50px"
              label="序号">
          </el-table-column>
          <el-table-column
              prop="studentNo"
              label="学号">
          </el-table-column>
          <el-table-column
              prop="studentName"
              label="姓名">
          </el-table-column>
          <el-table-column
              prop="siteName"
              label="站点名称">
          </el-table-column>
          <el-table-column
              prop="gradeName"
              label="年级">
          </el-table-column>
          <el-table-column
              prop="stageName"
              label="层次">
          </el-table-column>
          <el-table-column
              prop="profName"
              label="专业">
          </el-table-column>
          <el-table-column
              prop="modifyStatusS"
              label="是否更改">
          </el-table-column>
          <el-table-column
              prop="checkStatusS"
              label="审核状态">
          </el-table-column>
          <el-table-column
              label="预览">
            <template slot-scope="scope">
              <el-link v-if="eduScoreStudentedit" type="primary" @click="look(scope.row.studentId)">预览</el-link>
              <span v-if="!eduScoreStudentedit">暂无权限</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 30, 50, 100]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="queryBO.pageSize"
            :current-page="queryBO.page"
            :total="totalOne">
        </el-pagination>
      </div>
    </div>
    <el-dialog
        title="学生信息"
        :visible.sync="dialog"
        width="60%">
      <div>
        <el-card class="box-card" shadow="always">
          <div class="infinite-list-wrapper" style="overflow:auto;height:500px;">
            <el-form label-width="80px" v-infinite-scroll infinite-scroll-disabled="disabled" class="list">
              <div >
                <el-form-item label="函授站:">
                  <span>{{studentInfo.siteName}}</span>
                </el-form-item>
                <el-form-item label="年级:">
                  <span>{{studentInfo.gradeName}}</span>
                </el-form-item>
                <el-form-item label="层次:">
                  <span>{{studentInfo.stageName}}</span>
                </el-form-item>
                <el-form-item label="专业:">
                  <span>{{studentInfo.profName}}</span>
                </el-form-item>
                <el-form-item label="学号:">
                  <span>{{studentInfo.studentNo}}</span>
                </el-form-item>
              </div>
              <div >
                <el-card class="box-card" style="width:98%;" shadow="always">
                  <el-form-item label="学生头像:">
                    <img class="portrait_image"  :src="studentInfo.headerImg" style="width:15%;"  alt/>
                  </el-form-item>
                  <el-form-item label="姓名:">
                    <span>{{studentInfo.name}}</span>
                  </el-form-item>
                  <el-form-item label="性别:">
                    <span>{{studentInfo.sexS}}</span>
                  </el-form-item>
                  <el-form-item label="民族:">
                    <span>{{studentInfo.clan}}</span>
                  </el-form-item>
                  <el-form-item label="出生日期:">
                    <span>{{studentInfo.birthday}}</span>
                  </el-form-item>
                  <el-form-item label="证件类型:">
                    <span>{{studentInfo.certificateTypeS}}</span>
                  </el-form-item>
                  <el-form-item label="证件号码:">
                    <span>{{studentInfo.certificateNo}}</span>
                  </el-form-item>
                  <el-form-item label="政治面貌:">
                    <span v-if="studentInfo.politicalStatus==0">共青团员</span>
                    <span v-if="studentInfo.politicalStatus==1">中共党员</span>
                    <span v-if="studentInfo.politicalStatus==2">群众</span>
                    <span v-if="studentInfo.politicalStatus==3">中共预备党员</span>
                    <span v-if="studentInfo.politicalStatus==4">民革会员</span>
                    <span v-if="studentInfo.politicalStatus==5">民盟会员</span>
                    <span v-if="studentInfo.politicalStatus==6">民建会员</span>
                    <span v-if="studentInfo.politicalStatus==7">民进会员</span>
                    <span v-if="studentInfo.politicalStatus==8">农工党党员</span>
                    <span v-if="studentInfo.politicalStatus==9">致公党党员</span>
                    <span v-if="studentInfo.politicalStatus==10">九三学社社员</span>
                    <span v-if="studentInfo.politicalStatus==11">台盟盟员</span>
                    <span v-if="studentInfo.politicalStatus==12">无党派民主人士</span>
                  </el-form-item>
                  <el-form-item label="通信地址:">
                    <span>{{studentInfo.contactAddr}}</span>
                  </el-form-item>
                  <el-form-item label="邮政编码:">
                    <span>{{studentInfo.postCode}}</span>
                  </el-form-item>
                  <el-form-item label="工作单位:">
                    <span>{{studentInfo.workUnit}}</span>
                  </el-form-item>
                  <el-form-item label="联系电话:">
                    <span>{{studentInfo.contactPhone}}</span>
                  </el-form-item>
                </el-card>
              </div>
              <div style="text-align: center;margin-top: 30px;margin-bottom: 15px">家庭主要成员和社会关系</div>
              <el-table
                  border
                  :data="studentInfo.eduStudentFamilyList"
                  style="width: 98%"
                  size="medium"
                  height="300px"
                  max-height="300px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
              >
                <el-table-column
                    prop="memberName"
                    label="姓名">
                </el-table-column>
                <el-table-column
                    prop="workCompany"
                    label="工作单位">
                </el-table-column>
                <el-table-column
                    prop="contractNum"
                    label="联系电话">
                </el-table-column>
                <el-table-column
                    prop="relateTypeS"
                    label="与本人关系">
                </el-table-column>
              </el-table>
              <div style="text-align: center;margin-top: 30px;margin-bottom: 15px">本人学历及社会经历</div>
              <el-table
                  border
                  :data="studentInfo.eduStudentExperiences"
                  style="width: 98%"
                  size="medium"
                  height="300px"
                  max-height="300px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
              >
                <el-table-column
                    prop="startTime"
                    label="开始日期">
                </el-table-column>
                <el-table-column
                    prop="endTime"
                    label="截止日期">
                </el-table-column>
                <el-table-column
                    prop="experienceInfo"
                    label="经历信息">
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: 30px">
        <el-button type="primary" @click="dialog = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="驳回原因"
        :visible.sync="dialogreasons"
        width="30%"
    >
      <div >
        <div style="margin-bottom: 20px; text-align: center">
          原因：<el-input v-model="teacherForm.reason"  placeholder="请输入内容" style="width:40%"></el-input>
        </div>
      </div>
      <div style="margin-bottom: 20px; text-align: center">
        <el-button @click="dialogreasons = false"
        >关闭</el-button
        >
        <el-button type="primary" style="margin-left: 50px"
                   @click="submit(2)">确定</el-button
        >
      </div>
    </el-dialog>

  </div>
</template>

<script>



export default {
  name: "fillOutStudent.vue",

  data() {
    return {
      queryBO: {
        page: 1,
        pageSize: 10,
        gradeId: null,//年级id
        stageId: null,//层次id
        profId: null,//专业id
        checkStatus: null,
        studentNameOrNum: '',
        modifyStatus: null
      },
      gradeList: [],//年级下拉框列表
      stageList: [],//层次下拉框列表
      profList: [],//专业下拉框列表
      tableData: [],//主table
      totalOne: 0,//主table分页
      multipleSelection: [],
      dialog: false,
      eduScoreStudentedit: false,
      dialogreasons: false,
      modifyStatusList: [
        {value: null, label: '全部'},
        {value: 0, label: '否'},
        {value: 1, label: '是'}
      ],
      checkStatusList: [
        {value: null, label: '全部'},
        {value: 0, label: '等待审核'},
        {value: 1, label: '审核通过'},
        {value: 2, label: '驳回'}
      ],
      studentInfo: {},
      teacherForm: {
        reason:""
      }
    }
  },
  created() {
    this.getPermission('eduGraduateYReport:see', () => {
      this.eduScoreStudentedit=true;
    })
  },
  methods: {
    look(studentId) {
      this.openLoadingView();
      this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryStudentVoById?studentId=' + studentId).then(response => {
        if (response.data.code === 2000) {
          this.studentInfo = response.data.data;
          this.dialog = true;
        }
      })
    },
    updateServiceCheck(type) {
      if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
        this.$message.warning("请至少选择一项");
        return;
      }else{
        if(type==1){
          let ids = '';
          this.multipleSelection.forEach(m => {
            ids += m.id + ','
          });
          ids = ids.substring(0, ids.length - 1);
          this.openLoadingView();
          let bo = {
            ids: ids,
            checkStatus: type,
          }
          this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/updateServiceCheck', bo).then(response => {
            if (response.data.code === 2000) {
              this.$message.success("处理成功");
              this.queryBasic();
            }
          })
        }else{
          this.teacherForm.reason=""
          this.dialogreasons=true
        }
      }
    },
    submit(type){
      let ids = '';
      this.multipleSelection.forEach(m => {
        ids += m.id + ','
      });
      ids = ids.substring(0, ids.length - 1);
      this.openLoadingView();
      if(type==1){
        this.teacherForm.reason = undefined
      }
      let bo = {
        ids: ids,
        checkStatus: type,
        info: this.teacherForm.reason
      }
      this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/updateServiceCheck', bo).then(response => {
        if (response.data.code === 2000) {
          this.$message.success("处理成功");
          this.dialogreasons = false
          this.queryBasic();
        }
      })
    },
    download() {
      this.openLoadingView();
      this.$axios_supermall({
        method: "get",
        url: "/hnjxjy-core/eduGraduateReportSet/exportStudentVo",
        responseType: "blob"
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        // let url = window.URL.createObjectURL(blob);
        // window.location.href = url;
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '已填报列表.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功")
      })
    },
    //region
    selectionChange(val) {
      this.multipleSelection = val;
    },
    //endregion
    //region 基础查询
    queryBasic() {
      this.getPermission('eduGraduateYReport:list', () => {
        let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
        if (this.getIsNull(this.queryBO.studentNameOrNum)) {
          bo += '&studentNameOrNum=' + this.queryBO.studentNameOrNum;
        }
        if (this.getIsNull(this.queryBO.gradeId)) {
          bo += '&gradeId=' + this.queryBO.gradeId;
        }
        if (this.getIsNull(this.queryBO.stageId)) {
          bo += '&stageId=' + this.queryBO.stageId;
        }
        if (this.getIsNull(this.queryBO.profId)) {
          bo += '&profId=' + this.queryBO.profId;
        }
        if (this.getIsNull(this.queryBO.modifyStatus)) {
          bo += '&modifyStatus=' + this.queryBO.modifyStatus;
        }
        if (this.getIsNull(this.queryBO.checkStatus)) {
          bo += '&checkStatus=' + this.queryBO.checkStatus;
        }
        this.openLoadingView();
        this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryAllYEduGraduateReport?' + bo).then(response => {
          if (response.data.code === 2000) {
            this.tableData = response.data.data.list;
            this.totalOne = response.data.data.total;
          }
        })
      });
    },
    //endregion
    // region 查询专业下拉框列表
    queryProfList() {
      this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
        if (response.data.code === 2000) {
          this.profList = JSON.parse(JSON.stringify(response.data.data));
          this.profList.unshift({id: null, profName: "全部"})
        }
      })
    }
    ,
    //endregion
    //region 查询层次下拉框列表
    queryStageList() {
      this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
        if (response.data.code === 2000) {
          this.stageList = JSON.parse(JSON.stringify(response.data.data));
          this.stageList.unshift({id: null, stageName: "全部"})
        }
      })
    }
    ,
    //endregion
    //region 查询年级下拉框列表
    queryGradeList() {
      this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
        if (response.data.code === 2000) {
          this.gradeList = JSON.parse(JSON.stringify(response.data.data));
          this.gradeList.unshift({id: null, gradeName: "全部"});
        }
      })
    }
    ,
    //endregion
    //region 分页size
    handleSizeChange(val) {
      this.queryBO.pageSize = val;
      this.queryBasic();
    }
    ,
    //endregion
    //region 分页Current
    handleCurrentChange(val) {
      this.queryBO.page = val;
      this.queryBasic();
    },
    //endregion
  },
  mounted() {
    this.queryGradeList();//查询年级下拉框列表
    this.queryStageList();//查询层次下拉框列表
    this.queryProfList();//查询专业下拉框列表
    this.queryBasic();//基础查询
  }
}
</script>

<style scoped>

.left {
  width: 7%;
  display: inline-block;
}

.yingyong {
  margin-right: 100px;
  text-align: left;
}

.right {
  float: right;
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);

  text-align: left;
}

.left {
  width: 7%;
  display: inline-block;
}

.yingyong {
  font-weight: bold;
  color: #555;
}

#aaaaa {
  color: lightskyblue;
}

.top_btn {
  margin: 10px 10px 10px 10px;
  text-align: left;
}

.search {
  margin: 20px 10px 30px 10px;
  text-align: left;
}

.main_contain {
  margin: 20px 10px 30px 10px;
  text-align: left;
}
</style>
