<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                毕业条件设置
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationConditions:set'" @click="setConditions">设置毕业条件</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationConditions:yes'" @click="setEduGraduateConditionSet">应用毕业条件</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationConditions:exmport'" @click="download">导出</el-button>
                    </el-form-item>
                    <br/>
                    <!--                    <el-form-item>-->
                    <!--                        <el-input v-model="queryBO.studentNameOrNum" placeholder="姓名/学号" style="width: 410px">-->
                    <!--                            <el-button type="primary" slot="append" icon="el-icon-search"-->
                    <!--                                       @click="queryBasic"></el-button>-->
                    <!--                        </el-input>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        ref="multipleTable"
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        width="80"
                        type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="applyFlag"
                            label="是否应用 ">
                    </el-table-column>
                    <el-table-column
                            label="毕业条件 ">
                        <template slot-scope="scope">
                            <el-link type="primary" v-if="eduScoreStudentedit" @click="setting(scope.row)">毕业条件</el-link>
                          <span v-else>暂无权限</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                :before-close="handleClose"
                title="设置毕业条件"
                :visible.sync="dialog"
                width="30%">
            <div>
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" @click="dialog = false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveAndClose">保存后关闭</el-button>
                    </el-form-item>
                </el-form>
                <el-form style="width: 100%" :model="saveAndCloseBO" inline ref="ruleFormTwo">
                    <el-form-item prop="condition1Flag">
                        <el-checkbox v-model="saveAndCloseBO.condition1Flag" :true-label="1" :false-label="0">必修课准许不通过
                        </el-checkbox>
                    </el-form-item>
                    <el-form-item prop="condition1Val">
                        <el-input v-model="saveAndCloseBO.condition1Val"></el-input>
                    </el-form-item>
                    <el-form-item>门课程</el-form-item>
                    <br/>
                    <el-form-item prop="condition2Flag">
                        <el-checkbox v-model="saveAndCloseBO.condition2Flag" :true-label="1" :false-label="0">必修课准许不修完
                        </el-checkbox>
                    </el-form-item>
                    <el-form-item prop="condition2Val">
                        <el-input v-model="saveAndCloseBO.condition2Val"></el-input>
                    </el-form-item>
                    <el-form-item>门课程</el-form-item>
                    <br/>
                    <el-form-item prop="condition3Flag">
                        <el-checkbox v-model="saveAndCloseBO.condition3Flag" :true-label="1" :false-label="0">
                            通过全国统考公共课程
                        </el-checkbox>
                    </el-form-item>
                    <br/>
                    <el-form-item prop="condition4Flag">
                        <el-checkbox v-model="saveAndCloseBO.condition4Flag" :true-label="1" :false-label="0">全额缴纳学费
                        </el-checkbox>
                    </el-form-item>
                    <br/>
                    <el-form-item prop="condition5Flag">
                        <el-checkbox v-model="saveAndCloseBO.condition5Flag" :true-label="1" :false-label="0">选修课准许不修完
                        </el-checkbox>
                    </el-form-item>
                    <br/>
                    <el-form-item label="通过学分" prop="stuScore">
                        <el-input v-model="saveAndCloseBO.stuScore"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "graduationConditions",

        data() {
            return {
              eduScoreStudentedit:false,
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    checkStatus: null,
                    studentNameOrNum: '',
                    enabled: null
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                totalOne: 0,//主table分页
                multipleSelection: [],
                saveAndCloseBO: {
                    condition1Flag: 0,
                    condition1Val: 0,
                    condition2Flag: 0,
                    condition2Val: 0,
                    condition3Flag: 0,
                    condition4Flag: 0,
                    condition5Flag: 0,
                    stuScore: 0
                },
                rulesBySave: {
                    message: [
                        {required: true, message: '请输入内容', trigger: 'blur'}
                    ]
                },
                dialog: false
            }
        },
      created() {
        this.getPermission('eduGrduationConditions:query', () => {
          this.eduScoreStudentedit = true;
        })
      },
        methods: {
            setConditions() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection)) {
                    this.$message.warning("请至少选择一项");
                    return;
                }
                this.saveAndCloseBO = {
                    condition1Flag: 0,
                    condition1Val: 0,
                    condition2Flag: 0,
                    condition2Val: 0,
                    condition3Flag: 0,
                    condition4Flag: 0,
                    condition5Flag: 0,
                    stuScore: 0
                }
                this.dialog = true;
            },
            setting(row) {
                this.$refs.multipleTable.clearSelection();
                this.$refs.multipleTable.toggleRowSelection(row);
                let data = JSON.parse(JSON.stringify(row));
                this.saveAndCloseBO.condition1Flag = data.condition1Flag;
                this.saveAndCloseBO.condition1Val = data.condition1Val;
                this.saveAndCloseBO.condition2Flag = data.condition2Flag;
                this.saveAndCloseBO.condition2Val = data.condition2Val;
                this.saveAndCloseBO.condition3Flag = data.condition3Flag;
                this.saveAndCloseBO.condition4Flag = data.condition4Flag;
                this.saveAndCloseBO.condition5Flag = data.condition5Flag;
                this.saveAndCloseBO.stuScore = data.stuScore;
                this.dialog = true;
            },
            handleClose(done) {
                this.$refs.ruleFormTwo.resetFields();
                done();
            },
            setEduGraduateConditionSet() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection)) {
                    this.$message.warning("请至少选择一项");
                    return;
                }
                let ids = this.getIds(this.multipleSelection, 'id');
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateConditionSet/setEduGraduateConditionSet', {ids: ids}).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success(response.data.data);
                        this.queryBasic();
                    }
                })
            },
            saveAndClose() {
                this.openLoadingView();
                let ids = this.getIds(this.multipleSelection, 'id');
                this.$set(this.saveAndCloseBO, 'ids', ids);
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateConditionSet/updateServiceEduGraduateConditionSet', this.saveAndCloseBO).then(response => {
                    if (response.data.code === 2000) {
                        this.$refs.ruleFormTwo.resetFields();
                        this.dialog = false;
                        this.$message.success("操作成功")
                        this.queryBasic();
                    }else{
                      this.dialog = false;
                      this.$message.error(response.data.message)
                    }
                })
            },
            download() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateConditionSet/exportEduGraduateConditionSet",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '毕业条件设置.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGrduationConditions:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateConditionSet/queryAllEduGraduateConditionSet?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                    }
                })
            }
            ,
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            },
            //endregion
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBasic();//基础查询
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
