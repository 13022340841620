<template>
    <div id="contain">


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                学费管理
            </div>
            <div class="search">
                <el-form :model="search" ref="search" label-width="80px" inline>
                    <el-form-item style="width:100%">
                        <el-button icon="el-icon-circle-plus-outline" type="primary" v-permission="'eduFeeTuition:add'" @click="add">添加</el-button>
                        <el-button icon="el-icon-download" type='primary' @click='download' v-permission="'eduFeeTuition:export'">导出</el-button>
                        <el-button icon="el-icon-upload2" type='primary' @click='importExcle' v-permission="'eduFeeTuition:importData'">导入</el-button>
                      <el-button type="primary" @click="handleSearch">查询</el-button>
                      <el-button type="primary" @click="rest(search)">重置</el-button>
                    </el-form-item>
                    <div>
                            <el-form-item label="姓名/学号" >
                                <el-input v-model="search.name" placeholder="学号/姓名"  style="width: 200px"></el-input>
                            </el-form-item>
                          <el-form-item prop="siteId"  label="函授站" >
                            <el-select
                                style="width: 200px"
                                clearable
                                v-model="search.siteId"
                                placeholder="请选择"
                                class="el-form-input-width"
                            >
                              <el-option
                                  v-for="item in site"
                                  :key="item.id"
                                  :label="item.siteName"
                                  :value="item.id"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item prop="gradeId" label="年级" >
                            <el-select
                                style="width: 200px"
                                clearable
                                v-model="search.gradeId"
                                placeholder="请选择"
                                class="el-form-input-width"
                            >
                              <el-option
                                  v-for="item in option"
                                  :key="item.id"
                                  :label="item.gradeName"
                                  :value="item.id"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item prop="stageId" label="层次" >
                            <el-select
                                style="width: 200px"
                                clearable
                                v-model="search.stageId"
                                placeholder="请选择"
                                class="el-form-input-width"
                            >
                              <el-option
                                  v-for="item in eduStage"
                                  :key="item.id"
                                  :label="item.stageName"
                                  :value="item.id"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                            <el-form-item label="专业" prop="profId">

                              <el-select
                                  style="width: 200px"
                                  clearable
                                  v-model="search.profId"
                                  placeholder="请选择"
                              >
                                <el-option
                                    v-for="item in eduProfession"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>

                        <el-table :data="tableData" border
                                  size="medium"
                                  height="590px"
                                  max-height="590px"
                                  :header-cell-style="{'text-align':'center'}"
                                  :cell-style="{'text-align':'center'}"
                                  style="width: 100%">
                          <el-table-column type="index" width="80" label="序号">
                          </el-table-column>
                            <el-table-column prop="name" sortable label="姓名">
                            </el-table-column>
                            <el-table-column prop="studentNo" sortable label="学号">
                            </el-table-column>
                            <el-table-column prop="siteName" sortable label="函授站">
                            </el-table-column>
                            <el-table-column prop="stageName" sortable label="层次">
                            </el-table-column>
                            <el-table-column prop="gradeName" sortable label="年级">
                            </el-table-column>
                          <el-table-column prop="profName" sortable label="专业">
                          </el-table-column>
                          <el-table-column prop="amount" sortable label="应交">
                          </el-table-column>
                          <el-table-column prop="payAmount" sortable label="已交">
                          </el-table-column>
                          <el-table-column prop="unpaidAmount" sortable label="未交">
                          </el-table-column>
                          <el-table-column prop="contactPhone" :show-overflow-tooltip="true" sortable label="联系方式">
                          </el-table-column>
                          <el-table-column prop="remark" :show-overflow-tooltip="true" sortable label="备注">
                          </el-table-column>

                          <el-table-column label="操作" width="180">
                            <template slot-scope="scope">
                              <el-button  type="text"
                                          size="small" v-permission="'eduFeeTuition:seleltBystudentid'" @click="selectXQ(scope.row)">
                                详情
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                    </div>
                </el-form>
            </div>
            <!-- 共页数 -->
          <div style="padding: 1rem" class="bottom">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10,15,20]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
          </div>
        </div>

      <el-dialog class="add" title="添加" :visible.sync="putDialog2"  center>
        <el-form :model="searchs" ref="searchs" label-width="110px" inline>
          <el-form-item style="width:100%">
            <el-button icon="el-icon-circle-plus-outline" type="primary" @click="add">关闭</el-button>
            <el-button icon="el-icon-download" type='primary' @click='sumbit'>保存后关闭</el-button>
            <el-button type="primary" @click="add">查询</el-button>
<!--            <el-button type="primary" @click="rests(searchs)">重置</el-button>-->
          </el-form-item>
          <div>
              <el-form-item label="姓名/学号" prop="name" style="">
                <el-input  v-model="searchs.name" placeholder="学号/姓名" style="width: 200px"></el-input>
              </el-form-item>
              <el-form-item prop="siteId"  label="函授站" style="margin-bottom: 0;">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="searchs.siteId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="gradeId" label="年级" >
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="searchs.gradeId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in option"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="stageId" label="层次" style="margin-bottom: 0;">
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="searchs.stageId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业" prop="profId" >
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="searchs.profId"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="缴费金额" style="margin-bottom:0">
                <el-input  v-model="searchs.money" placeholder="请输入(必填)" style="width: 200px"></el-input>
              </el-form-item>
              <el-form-item label="备注" >
                <el-input  v-model="searchs.remark" placeholder="请输入(必填)" style="width: 200px"></el-input>
              </el-form-item>
            <el-table :data="tableData2" border
                      size="medium"
                      :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}"
                      style="width: 100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column type="index" sortable width="60" label="序号">
              </el-table-column>
              <el-table-column prop="name" sortable label="姓名">
              </el-table-column>
              <el-table-column prop="studentNo" sortable label="学号">
              </el-table-column>
              <el-table-column prop="siteName" sortable label="性别">
              </el-table-column>
              <el-table-column prop="stageName" sortable label="证件号">
              </el-table-column>
              <el-table-column prop="gradeName" sortable label="年级">
              </el-table-column>
              <el-table-column prop="profName" sortable label="专业">
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 1rem" class="bottom">
            <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="searchs.page"
                :page-sizes="[5,10,15,20]"
                :page-size="searchs.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total2"
            >
            </el-pagination>
          </div>
        </el-form>
      </el-dialog>
      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton" v-permission="'eduFeeTuition:exportMB'">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>

      <el-dialog title="详情" :visible.sync="PrintingVisible" width="50%">
        <div class="printing">
          <div class="printing_btn">
            <el-button
                icon="el-icon-circle-close"
                type="primary"
                @click="PrintingVisible = false"
            >关闭</el-button
            >
            <el-button
                icon="el-icon-download"
                type="primary"
                @click="removes"
                v-permission="'eduFeeTuition:revoke'"
            >撤销</el-button
            >
          </div>
          <div>
            <el-table
                ref="multipleTable"
                :data="printingData"
                border
                id="el-table_print"
                width="100%"
                size="medium"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="序号" type="index" width="60">
              </el-table-column>
              <el-table-column label="缴费时间" prop="confirmTime"> </el-table-column>
              <el-table-column label="金额" prop="amount">
              </el-table-column>
              <el-table-column label="收支类型" prop="payStyle">
              </el-table-column>
              <el-table-column label="备注" prop="remark"> </el-table-column>
            </el-table>
          </div>
        </div>
  </el-dialog>

    </div>
</template>

<script>


    export default {

      created() {
        this.handleSearch();
        this.queryEduStage();
        this.queryEduProfession();
        this.queryEduSite();
        this.gradList();
      },
        data() {
            return {
              site:[],
              total2:0,
              total:0,
              eduStage:[],
              eduProfession:[],
              tableData:[],
              currentPage4: 1,
              printingData:[],
              PrintingVisible:false,
              option:[],
                options: [{
                    value: '选项1',
                    label: '全部'
                }, {
                    value: '选项2',
                    label: '(1001)浙江'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                value: '',
              search: {
                siteId: "",
                name: "",
                gradeId: "",
                stageId: "",
                profId: "",
                page: 1,
                pageSize: 10,
              },
              searchs: {
                siteId: "",
                name: "",
                money: "",
                remark: "",
                gradeId: "",
                stageId: "",
                profId: "",
                page: 1,
                pageSize: 10,
              },
                dialogVisibleUpload: false,
              dialogVisibleUploads: false,
              putDialog2: false,
              tableData2: [],
              id:0
            }
        },
        methods: {
          //查询年级
          gradList() {
            this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
              if (resp.data.code == 2000) {
                this.option = resp.data.data;
              }
            })
          },
          rest(form){
            this.$refs.search.resetFields()
          },
          rests(form){
            this.$refs.searchs.resetFields()
          },
          //查询层次
          queryEduStage() {
            this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
              if (resp.data.code == 2000) {
                this.eduStage = resp.data.data;
              }
            })
          },
          //查询专业
          queryEduProfession() {
            this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
              if (resp.data.code == 2000) {
                this.eduProfession = resp.data.data;
              }
            })
          },
          //查询函授站下拉
          queryEduSite() {
           let formData = new FormData();
              formData.append("isAll","1");
            this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
              if (resp.data.code == 2000) {
                this.site = resp.data.data;
              }
            })
          },
          selectStudent(val) {
            this.selectStu = val;
          },
          handleSearch() {
            this.getPermission('eduFeeTuition:list', () => {
            this.openLoadingView()
            let fromData = new FormData();
            fromData.append("gradeId",this.search.gradeId);
            fromData.append("stageId",this.search.stageId);
            fromData.append("profId",this.search.profId);
            fromData.append("siteId",this.search.siteId);
            fromData.append("info",this.search.name);
            fromData.append("page",this.search.page);
            fromData.append("pageSize",this.search.pageSize);
            this.$axios_supermall.post("/hnjxjy-core/eduFeeTuition/list",fromData).then(resp => {
              if (resp.data.code == 2000) {
                this.tableData = resp.data.data.list;
                this.total = resp.data.data.total;
              }
            })
            });
          },
          selectXQ(row){
            this.PrintingVisible = true
            this.$axios_supermall.get("/hnjxjy-core/eduFeeTuition/seleltBystudentid?id="+row.studentId).then(resp => {
                 this.printingData=resp.data;
            })
            this.id= row.id
          },
          selectXQs(){
            this.PrintingVisible = true
            this.openLoadingView()
            this.$axios_supermall.get("/hnjxjy-core/eduFeeTuition/seleltBystudentid?id="+this.id).then(resp => {
              this.printingData=resp.data;
            })
          },
          add(){
            this.putDialog2 = true
            this.searchs.money=""
            this.searchs.remark=""
            let fromData = new FormData();
            this.openLoadingView()
            fromData.append("gradeId",this.searchs.gradeId);
            fromData.append("stageId",this.searchs.stageId);
            fromData.append("profId",this.searchs.profId);
            fromData.append("siteId",this.searchs.siteId);
            fromData.append("info",this.searchs.name);
            fromData.append("page",this.searchs.page);
            fromData.append("pageSize",this.searchs.pageSize);
            this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list",fromData).then(resp => {
              if (resp.data.code == 2000) {
                this.tableData2 = resp.data.data.list;
                this.total2 = resp.data.data.total;
              }
            })
          },
          handleSelectionChange(val) {
            this.multipleSelection = val;
          },
          sumbit(){
            if(this.multipleSelection!=null&&this.multipleSelection!=""){
              if(this.searchs.money!=null&&this.searchs.money!=""&&this.searchs.remark!=null&&this.searchs.remark!=""){
                // if(!Number.isInteger(this.searchs.money)){
                //   this.$message.error("缴费金额必须为数字!")
                // }else{
                //
                // }
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                  array.push(this.multipleSelection[i].id);
                }
                let arr = array.toString();
                this.$axios({
                  url: "hnjxjy-core/eduFeeTuition/add",
                  headers: this.loginList,
                  method: "post",
                  params: {
                    ids: arr,
                    money: this.searchs.money,
                    remark: this.searchs.remark,
                  },
                }).then((res) => {
                  if (res.data.code == 2000) {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                    });
                    this.putDialog2 = false
                    this.handleSearch()
                  }
                })

                    .catch(() => {
                      this.$message({
                        type: "info",
                        message: "操作失败",
                      });
                    });
              }else{
                this.$message.error("必填项不能为空")
              }
            }else{
              this.$message({
                type: "error",
                message: "请至少选择一条!",
              });
            }
          },
          removes(){
            if(this.multipleSelection!=null&&this.multipleSelection!=""){
              this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
              let array = [];
              for (let i = 0; i < this.multipleSelection.length; i++) {
                array.push(this.multipleSelection[i].id);
              }
              let arr = array.toString();
              this.$axios({
                url: "hnjxjy-core/eduFeeTuition/revoke",
                headers: this.loginList,
                method: "post",
                params: {
                  ids: arr,
                },
              }).then((res) => {
                if (res.data.code == 2000) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.selectXQs();
                }
              })

                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "操作失败",
                    });
                  });
            }else{
              this.$message({
                type: "error",
                message: "请至少选择一条!",
              });
            }
          },
          importExcle(){
            this.dialogVisibleUploads = true
          },
          beforeUpload(file) {
            const isXls = file.type === "application/vnd.ms-excel";
            const isXlsx =
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (!isXls && !isXlsx) {
              this.$message.error("上传文件只能是XLS/XLSX格式！");
              return false;
            }
            return isXls || isXlsx;
          },
          uploadFile(file){
            let fileDate = file.file;
            let forms = new FormData();
            forms.append("file", fileDate);
            let objPar = JSON.parse(localStorage.getItem("loginStrage"));
            this.openLoadingView()
            this.$axios({
              method: "post",
              url: "hnjxjy-core/eduFeeTuition/importData",
              data: forms,
              headers: objPar,
            }).then((res) => {
              if (res.data.code === 2000){
                this.dialogVisibleUploads = false;
                this.handleSearch();
                this.loadingView.close()
                this.$message.success("操作成功")
              }else {
                this.dialogVisibleUploads = false;
                this.handleSearch();
                this.$message.error(res.data.message)
              }
            }).catch((err) => {
              this.dialogVisibleUploads = false;
              this.handleSearch();
              this.loadingView.close()

              this.$message.error(err)
            });
          },
          downloadbutton(){
            let objPar = JSON.parse(localStorage.getItem("loginStrage"));
            this.openLoadingView()
            this.$axios({
              method: "get",
              url: "hnjxjy-core/eduFeeTuition/exportMB",
              responseType: "blob",
              headers: objPar,
              params:{
                status:1
              }
            })
                .then((res) => {
                  let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel",
                  });
                  let url = window.URL.createObjectURL(blob);
                  window.location.href = url;
                  this.loadingView.close()
                  this.$message.success("操作成功")
                })
                .catch((err) => {
                });
          },
          download(){
            let objPar = JSON.parse(localStorage.getItem("loginStrage"));
            this.openLoadingView()
            this.$axios({
              method: "get",
              url: "hnjxjy-core/eduFeeTuition/export",
              responseType: "blob",
              headers: objPar,
              params: {
                "info":this.search.name,
                "siteId":this.search.siteId,
                "gradeId":this.search.gradeId,
                "stageId":this.search.stageId,
                "profId":this.search.profId,
              },
            })
                .then((res) => {
                  let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel",
                  });
                  let url = window.URL.createObjectURL(blob);
                  window.location.href = url;
                  this.loadingView.close()
                  this.$message.success("操作成功")
                })
                .catch((err) => {
                });
          },
          handleSizeChange(val) {
            this.search.pageSize = val;
            this.handleSearch();
          },
          handleCurrentChange(val) {
            this.search.page = val;
            this.handleSearch();
          },
          handleSizeChange2(vals) {
            this.searchs.pageSize = vals;
            this.add();
          },
          handleCurrentChange2(vals) {
            this.searchs.page = vals;
            this.add();
          },
            upload() {
                this.dialogVisibleUpload = true;
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .el-form-input-width {
        width: 100%;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .block {
        display: inline-block;
    }
    .py{
    }
</style>
