<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                学位外语审核
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduEnglishDegree:y'" @click="openExamine(1)">审核</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduEnglishDegree:n'" @click="openExamine(2)">驳回</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduEnglishDegree:export'" @click="importDialog=true">导入申请名单</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduEnglishDegree:import'" @click="download2">导出</el-button>
                        <el-button type="primary" v-permission="'set:setuseTJ'" @click="dialogVisibleTrue">设置并应用学位条件</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item prop="studentNameOrNum" label="姓名/学号">
                        <el-input v-model="queryBO.studentNameOrNum" placeholder="姓名/学号" style="width: 300px">
                            <el-button type="primary" slot="append" icon="el-icon-search"
                                       @click="queryBasic"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="审核状态" prop="checkStatus">
                        <el-select v-model="queryBO.checkStatus" placeholder="请选择审核状态" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in checkStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学籍状态" prop="rollStatus">
                        <el-select v-model="queryBO.rollStatus" placeholder="请选择学籍状态" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in rollStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="成绩类型" prop="gradeType">
                        <el-select v-model="queryBO.gradeType" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="函授站" prop="schoolId">
                        <el-select v-model="queryBO.schoolId" placeholder="请选择函授站" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in siteList"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="startTime">
                        <el-date-picker
                                @change="queryBasic"
                                style="width: 300px"
                                v-model="queryBO.startTime"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间" prop="endTime">
                        <el-date-picker
                                @change="queryBasic"
                                style="width: 300px"
                                v-model="queryBO.endTime"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="500px"
                        max-height="500px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                        width="60"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="checkStatuss"
                            label="审核状态">
                    </el-table-column>
                    <el-table-column
                        width="120"
                            prop="studentName"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="rollStatuss"
                            label="学籍状态">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            width="100px"
                            show-overflow-tooltip
                            prop="schoolName"
                            label="站点名称">
                    </el-table-column>
                    <el-table-column
                        width="100px"
                        show-overflow-tooltip
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                        width="100px"
                        show-overflow-tooltip
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            width="200px"
                            show-overflow-tooltip
                            prop="certificateNo"
                            label="证件号">
                    </el-table-column>
                    <el-table-column
                        width="100px"
                            prop="areaName"
                            label="报考省份">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="contactPhone"
                            label="联系方式">
                    </el-table-column>
                    <el-table-column
                            width="300px"
                            show-overflow-tooltip
                            prop="gradeTypes"
                            label="成绩类型">
                    </el-table-column>
                    <el-table-column
                            prop="score"
                            label="成绩">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="applyTime"
                            label="申请时间">
                    </el-table-column>
                    <el-table-column
                            width="100px"
                            prop="checkName"
                            label="审核人">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="checkTime"
                            label="审核时间">
                    </el-table-column>
                    <el-table-column
                            prop="checkSuggestion"
                            show-overflow-tooltip
                            label="审核意见">
                    </el-table-column>
                    <el-table-column
                        width="120"
                        show-overflow-tooltip
                            prop="certNo"
                            label="证书编号">
                    </el-table-column>
                  <el-table-column prop="enclosureUrl" label="附件" align="center">
                    <template slot-scope="scope" v-if="scope.row.enclosureUrl">
                      <el-button type="text" @click="onAttachment(scope.row)">
                        查看附件
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="导入" :visible.sync="importDialog" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="download">下载模板</el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
        <el-dialog
                title="审核"
                :visible.sync="examineDialog"
                width="30%">
            <div>
                <el-form label-width="80px">
                    <el-form-item label="审核意见">
                        <el-input v-model="examineBO.checkSuggestion"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select disabled v-model="examineBO.checkStatus" >
                            <el-option
                                    v-for="item in checkStatusList2"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="examineDialog = false">取 消</el-button>
                <el-button type="primary" @click="examineCommit">确 定</el-button>
            </div>
        </el-dialog>

      <el-dialog
          title="设置并应用学位条件"
          :visible.sync="dialogVisible"
          width="50%"
          style="text-align: left"
      >
        <el-form
            size="small"
            :model="bo"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
        >
          <el-button style="margin-left: 50px;margin-bottom: 40px" @click="setTheDegree" type="primary">设置学位条件</el-button>
          <el-button style="margin-bottom: 40px" @click="applicationDegree" v-if="this.bos!=null" type="primary">应用学位条件</el-button>
          <el-row>
            <el-col :span="12">
              <el-form-item  class="item"  label="教学计划内课程平均分达" >
                <el-checkbox v-model="bo.condition1Flag" style="margin-left: 5px;margin-right: 10px"></el-checkbox>
                <el-input
                    v-model="bo.condition1Val"
                    clearable
                    style="width: 100px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  class="item"  label="学位专业课每门成绩达" >
                <el-checkbox v-model="bo.condition2Flag" style="margin-left: 5px;margin-right: 10px"></el-checkbox>
                <el-input
                    v-model="bo.condition2Val"
                    clearable
                    style="width: 100px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item style="width: 600px"  class="item"  label="毕业答辩成绩达" >
                <el-checkbox v-model="bo.condition3Flag" style="margin-left: 5px;margin-right: 10px"></el-checkbox>
                <el-input
                    v-model="bo.condition3Val"
                    clearable
                    style="width: 100px"
                ></el-input>
                <el-select
                    style="width: 100px;margin-left: 10px; display: inline-block"
                    v-model="condition3Value"
                    class="el-form-input-width"
                    @change="condition3ValueChange"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
              <el-col :span="12">
              <el-form-item  class="item"  label="学位外语成绩合格" >
                <el-checkbox v-model="bo.condition4Flag" style="margin-left: 5px;margin-right: 10px"></el-checkbox>
              </el-form-item>
              </el-col>
                <el-col :span="12">
              <el-form-item  class="item"  label="允许补考课程数" >
                <el-checkbox v-model="bo.condition5Flag" style="margin-left: 5px;margin-right: 10px"></el-checkbox>
                <el-input
                    v-model="bo.condition5Val"
                    clearable
                    style="width: 100px"
                ></el-input>
              </el-form-item>
                </el-col>
                  <el-col :span="12">
              <el-form-item  class="item"  label="毕业生选择范围" >
                <el-checkbox v-model="bo.condition6Flag" style="margin-left: 5px;margin-right: 10px"></el-checkbox>
                <el-select
                    style="width: 100px; display: inline-block"
                    v-model="bo.condition6Val"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in optionss"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "fillOutStudent.vue",

        data() {
            return {
              bos:null,
              bo:{
                condition1Flag: true, //是否启用教学计划内课程平均分 0否 1是
                condition1Val: 1, //教学计划内课程平均分值
                condition2Flag: true, //是否启用学位专业课每门成绩 0否 1是
                condition2Val: 1, //学位专业课每门成绩值
                condition3Flag: true, //是否启用毕业答辩成绩 0否 1是
                condition3Val: 1, //毕业答辩成绩值
                condition4Flag: true, //是否启用学位外语成绩合格 0否 1是
                condition5Flag: 1, //是否启用允许补考课程 0否 1是
                condition5Val: 1, //允许补考课程数
                condition6Flag: true, //是否启用毕业生选择范围 0否 1是
                condition6Val: 1, //毕业生选择范围值
              },
              condition3Value: null,
                importDialog: false,
              dialogVisible: false,
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    schoolId: null,
                    studentNameOrNum: '',
                    checkStatus: null,
                    rollStatus: null,
                    gradeType: null,
                    startTime: '',
                    endTime: ''
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                siteList: [],//函授站下拉框列表
                totalOne: 0,//主table分页
                multipleSelection: [],
                dialog: false,
              options: [
                {
                  value: 0,
                  label: "优",
                },
                {
                  value: 1,
                  label: "良",
                },
                {
                  value: 2,
                  label: "及格",
                },
                {
                  value: 3,
                  label: "不及格",
                },
              ],
              optionss: [
                {
                  value: "无",
                  label: "无",
                },
                {
                  value: "今年",
                  label: "今年",
                },
                {
                  value: "去年",
                  label: "去年",
                }
              ],
                checkStatusList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '等待审核'},
                    {value: 1, label: '审核通过'},
                    {value: 2, label: '审核驳回'}
                ],
                checkStatusList2: [
                    {value: 0, label: '等待审核'},
                    {value: 1, label: '审核通过'},
                    {value: 2, label: '审核驳回'}
                ],
                rollStatusList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '正式生'},
                    {value: 1, label: '退学'},
                    {value: 2, label: '休学'},
                    {value: 3, label: '毕业'},
                    {value: 4, label: '肄业'}
                ],
                gradeTypeList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '省级学位外语考试'},
                    {value: 1, label: '全国英语考试等级三级以上'},
                    {value: 2, label: '全国英语等级四级'},
                    {value: 3, label: '全国大学英语六级'}
                ],
                examineBO: {
                    checkSuggestion: '',
                    checkStatus: 0
                },
                examineDialog: false
            }
        },
        methods: {
            examineCommit(){
                let ids = this.getIds(this.multipleSelection,'id');
              let bo = {};
                if (this.examineBO.checkStatus === 1){
                  bo = {
                    ids:ids,
                    checkStatus:this.examineBO.checkStatus,
                  };
                }else {
                  bo = {
                    ids:ids,
                    checkStatus:this.examineBO.checkStatus,
                    checkSuggestion:this.examineBO.checkSuggestion
                  };
                }
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateDegreeSet/updateByPrimaryKeySelective',bo).then(response=>{
                    if (response.data.code === 2000){
                        this.$message.success("处理成功");
                        this.queryBasic();
                        this.examineDialog = false;
                    }else {
                      this.loadingView.close();
                      this.$message.error("处理失败")
                      this.examineDialog = false;
                    }
                })
            },
            openExamine(type){
                if (this.getMultipleSelectionIsNull(this.multipleSelection)){
                    this.$message.warning("请至少选择一行");
                    return;
                }
                this.examineBO.checkStatus=type;
                if (type === 1){
                  this.examineCommit();
                }else {
                  this.examineDialog = true;
                }
            },
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
              this.openLoadingView()
                this.$axios_supermall({
                    method: "post",
                    url: "hnjxjy-core/eduGraduateDegreeSet/importEduGraduateDegreeEnglishTemplate",
                    data: forms,
                }).then((res) => {
                    if (res.data.code === 2000) {
                        this.$message.success(res.data.data);
                        this.importDialog = false;
                        this.queryBasic();
                    }
                }).catch((err) => {
                });
            },
            download() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateDegreeSet/exportEduGraduateDegreeEnglishTemplate",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '导入合格名单模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            download2() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateDegreeSet/exportEduGraduateDegreeEnglishVo",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '学位外语审核.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduEnglishDegree:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;

                if (this.getIsNull(this.queryBO.schoolId)) {
                  bo += '&schoolId=' + this.queryBO.schoolId;
                }

                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }

                if (this.getIsNull(this.queryBO.checkStatus)) {
                  bo += '&checkStatus=' + this.queryBO.checkStatus;
                }
                if (this.getIsNull(this.queryBO.rollStatus)) {
                  bo += '&rollStatus=' + this.queryBO.rollStatus;
                }
                if (this.getIsNull(this.queryBO.gradeType)) {
                  bo += '&gradeType=' + this.queryBO.gradeType;
                }
                if (this.getIsNull(this.queryBO.studentNameOrNum)) {
                  bo += '&studentNameOrNum=' + this.queryBO.studentNameOrNum;
                }
                if (this.getIsNull(this.queryBO.startTime)) {
                  bo += '&startTime=' + this.queryBO.startTime.formatToLocalDateTime('yyyy/MM/dd HH:mm:ss');
                }
                if (this.getIsNull(this.queryBO.endTime)) {
                  bo += '&endTime=' + this.queryBO.endTime.formatToLocalDateTime('yyyy/MM/dd HH:mm:ss');
                }

                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateDegreeSet/queryAllByPage?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                    }
                })
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            },
          condition3ValueChange(){
            if (this.condition3Value === 0){
              this.bo.condition3Val = 90;
            }
            if (this.condition3Value === 1){
              this.bo.condition3Val = 80;
            }
            if (this.condition3Value === 2){
              this.bo.condition3Val = 65;
            }
            if (this.condition3Value === 3){
              this.bo.condition3Val = 50;
            }
          },
          onAttachment(row) {
            if (row) {
              window.open(row.enclosureUrl);
            }
          },
          queryConditionById(){
            this.openLoadingView();
            this.$axios_supermallData.get("/hnjxjy-core/eduGraduateDegreeSet/queryConditionById")
                .then(resp => {
                  if (resp.data.code === 2000&&resp.data.data!=null){
                    this.bo = resp.data.data;
                    this.bos = 1;
                    this.bo.condition1Flag = resp.data.data.condition1Flag === 1 ? true : false;
                    this.bo.condition2Flag = resp.data.data.condition2Flag === 1 ? true : false;
                    this.bo.condition3Flag = resp.data.data.condition3Flag === 1 ? true : false;
                    this.bo.condition4Flag = resp.data.data.condition4Flag === 1 ? true : false;
                    this.bo.condition5Flag = resp.data.data.condition5Flag === 1 ? true : false;
                    this.bo.condition6Flag = resp.data.data.condition6Flag === 1 ? true : false;
                    let thisYear = new Date().getFullYear()
                    let lastYear = thisYear - 1;
                    if (null == resp.data.data.condition6Val){
                      this.bo.condition6Val = "无";
                    }
                    if (thisYear === resp.data.data.condition6Val){
                      this.bo.condition6Val = "今年";
                    }
                    if (lastYear === resp.data.data.condition6Val){
                      this.bo.condition6Val = "去年";
                    }
                    this.loadingView.close();
                  }else {
                    this.loadingView.close();
                  //  this.getElementsByClassName("shows").hide()
                  }
                })
                .catch(err => {
                  this.$message.error(err)
                  this.loadingView.close();
                });
          },
          dialogVisibleTrue(){
            this.dialogVisible = true;
            setTimeout(() => {
              this.queryConditionById();
            }, 100);
          },
          setTheDegree(){
            if (this.bo.condition6Flag === true && "无" === this.bo.condition6Val){
             return this.$message.error("毕业生选择范围值不可为空")
            }
            let data = {
               condition1Flag : this.bo.condition1Flag === true ? 1 : 0, //是否启用教学计划内课程平均分 0否 1是
               condition1Val : this.bo.condition1Val, //教学计划内课程平均分值
               condition2Flag : this.bo.condition2Flag === true ? 1 : 0, //是否启用学位专业课每门成绩 0否 1是
               condition2Val : this.bo.condition2Val, //学位专业课每门成绩值
               condition3Flag : this.bo.condition3Flag === true ? 1 : 0, //是否启用毕业答辩成绩 0否 1是
               condition3Val : this.bo.condition3Val, //毕业答辩成绩值
               condition4Flag : this.bo.condition4Flag === true ? 1 : 0, //是否启用学位外语成绩合格 0否 1是
               condition5Flag : this.bo.condition5Flag === true ? 1 : 0, //是否启用允许补考课程 0否 1是
               condition5Val : this.bo.condition5Val, //允许补考课程数
               condition6Flag : this.bo.condition6Flag === true ? 1 : 0, //是否启用毕业生选择范围 0否 1是
               condition6Val : null, //毕业生选择范围值
            };

            if (this.bo.condition1Flag === true){
              if (null == this.bo.condition1Val || "" === this.bo.condition1Val){
                data.condition1Val = 0;
              }
            }
            if (this.bo.condition2Flag === true){
              if (null == this.bo.condition2Val || "" === this.bo.condition2Val){
                data.condition2Val = 0;
              }
            }
            if (this.bo.condition3Flag === true){
              if (null == this.bo.condition3Val || "" === this.bo.condition3Val){
                data.condition3Val = 0;
              }
            }
            if (this.bo.condition5Flag === true){
              if (null == this.bo.condition5Val || "" === this.bo.condition5Val){
                data.condition5Val = 0;
              }
            }
            let thisYear = new Date().getFullYear()
            let lastYear = thisYear - 1;
            if ("无" === this.bo.condition6Val){
              data.condition6Val = thisYear;
            }
            if ("今年" === this.bo.condition6Val){
              data.condition6Val = thisYear;
            }
            if ("去年" === this.bo.condition6Val){
              data.condition6Val = lastYear;
            }
            this.openLoadingView();
            this.$axios_supermall.post("/hnjxjy-core/eduGraduateDegreeSet/updateEduGraduateDegreeConditionBySchoolId",data)
            .then(resp => {
              if (resp.data.code === 2000){
                this.$message.success("设置成功")
                this.queryConditionById();
                this.loadingView.close();
              }else {
                this.$message.error("设置失败")
                this.loadingView.close();
              }
            }).catch(err => {
              this.$message.error(err)
              this.loadingView.close();
            });
          },
          applicationDegree(){
            if (this.bo.condition6Flag === true && "无" === this.bo.condition6Val){
              return this.$message.error("毕业生选择范围值不可为空")
            }
            let data = {
              condition1Flag : this.bo.condition1Flag === true ? 1 : 0, //是否启用教学计划内课程平均分 0否 1是
              condition1Val : this.bo.condition1Val, //教学计划内课程平均分值
              condition2Flag : this.bo.condition2Flag === true ? 1 : 0, //是否启用学位专业课每门成绩 0否 1是
              condition2Val : this.bo.condition2Val, //学位专业课每门成绩值
              condition3Flag : this.bo.condition3Flag === true ? 1 : 0, //是否启用毕业答辩成绩 0否 1是
              condition3Val : this.bo.condition3Val, //毕业答辩成绩值
              condition4Flag : this.bo.condition4Flag === true ? 1 : 0, //是否启用学位外语成绩合格 0否 1是
              condition5Flag : this.bo.condition5Flag === true ? 1 : 0, //是否启用允许补考课程 0否 1是
              condition5Val : this.bo.condition5Val, //允许补考课程数
              condition6Flag : this.bo.condition6Flag === true ? 1 : 0, //是否启用毕业生选择范围 0否 1是
            };
            if (this.bo.condition1Flag === true){
              if (null == this.bo.condition1Val || "" === this.bo.condition1Val){
                data.condition1Val = 0;
              }
            }
            if (this.bo.condition2Flag === true){
              if (null == this.bo.condition2Val || "" === this.bo.condition2Val){
                data.condition2Val = 0;
              }
            }
            if (this.bo.condition3Flag === true){
              if (null == this.bo.condition3Val || "" === this.bo.condition3Val){
                data.condition3Val = 0;
              }
            }
            if (this.bo.condition5Flag === true){
              if (null == this.bo.condition5Val || "" === this.bo.condition5Val){
                data.condition5Val = 0;
              }
            }
            let thisYear = new Date().getFullYear()
            let lastYear = thisYear - 1;
            if ("无" === this.bo.condition6Val){
              data.condition6Val = thisYear;
            }
            if ("今年" === this.bo.condition6Val){
              data.condition6Val = thisYear;
            }
            if ("去年" === this.bo.condition6Val){
              data.condition6Val = lastYear;
            }
            this.openLoadingView();
            this.$axios_supermall.post("/hnjxjy-core/eduGraduateDegreeSet/updateConditionById",data)
                .then(resp => {
                  if (resp.data.code === 2000){
                    this.$message.success("设置成功")
                    this.queryConditionById();
                    this.loadingView.close();
                  }else {
                    this.$message.error("设置失败")
                    this.loadingView.close();
                  }
                }).catch(err => {
              this.$message.error(err)
              this.loadingView.close();
            });
          },
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.querySiteList();
            this.queryBasic();//基础查询
        },

    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }
    .item .el-form-item__label{
      font-size: 12px;
    }
    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
    .el-form-item__content{
      width: 600px;
    }
    .item .el-form-item__label{
      color: blue;
    }
</style>
