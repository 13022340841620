<template>
  <div>


    <div>
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        登录统计日志
      </div>
      <!-- 按钮-->
      <div class="search">
        <el-button icon="el-icon-download" type="primary" v-permission="'sysLoginLog:export'"
          @click="download">导出</el-button>
        <el-button icon="el-icon" type="primary" @click="rest(search)">重置</el-button>

        <el-form :model="search" ref="search" class="demo-ruleForm" inline style="margin-top: 1rem;width:100%">

          <el-form-item label="账号类型：" prop="accountType">
            <el-select clearable @change="handleSearch" v-model="search.accountType" placeholder="请选择"
              class="el-form-input-width">
              <el-option v-for="item in accountType" :key="item.id" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!--                    <el-form-item label="用户角色：">-->
          <!--                      <el-select-->
          <!--                          @change="handleSearch"-->
          <!--                          v-model="search.roleId"-->
          <!--                          placeholder="请选择"-->
          <!--                          class="el-form-input-width"-->
          <!--                      >-->
          <!--                        <el-option-->
          <!--                            v-for="item in roleType"-->
          <!--                            :key="item.id"-->
          <!--                            :label="item.name"-->
          <!--                            :value="item.id"-->
          <!--                        >-->
          <!--                        </el-option>-->
          <!--                      </el-select>-->
          <!--                    </el-form-item>-->
          <el-form-item style="margin-bottom: 0" prop="name">
            <div>
              <el-input placeholder="登录账号(学生账号/教师工号)" v-model="search.name" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="函授站" prop="siteId" style="margin-bottom: 0">
            <el-select @change="handleSearch" clearable v-model="search.siteId" placeholder="请选择"
              class="el-form-input-width">
              <el-option v-for="item in site" :key="item.id" :label="item.siteName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间" prop="startTime" style="margin-bottom: 0">
            <el-date-picker clearable @change="clear" v-model="search.startTime" type="datetime" placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="endTime" style="margin-bottom: 0">

            <el-date-picker clearable @change="handleSearch" v-model="search.endTime" type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table border ref="tableData" :data="tableData" style="width: 100%" size="medium" height="600px"
          max-height="600px" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">

          <el-table-column label="序号" width="100" type="index">
          </el-table-column>
          <el-table-column label="账号" width="180" prop="loginAccount" sortable>
          </el-table-column>
          <el-table-column label="姓名" width="180" prop="name" sortable>
          </el-table-column>
          <el-table-column label="函授站" width="200" prop="siteName" sortable>
          </el-table-column>
          <el-table-column label="站点编号" width="200" prop="siteCode" sortable>
          </el-table-column>
          <el-table-column label="IP" width="140" prop="loginIp">
          </el-table-column>
          <el-table-column label="时间" width="160" prop="loginTime">
          </el-table-column>
          <el-table-column label="浏览器名称" width="280" prop="browser">
          </el-table-column>
          <el-table-column label="版本" width="140" prop="browserEdition" sortable>
          </el-table-column>
          <el-table-column label="操作系统" width="160" prop="operatingSystem">
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="search.page" :page-sizes="[10, 20, 50, 100]" :page-size="search.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="this.total">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>
<script>


export default {

  created() {
    this.queryAllUserType();
    this.queryEduSite();
  },
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      currentPage4: 4,
      textarea: "",
      tableData: [],
      search: {
        accountType: "",
        name: "",
        roleId: "",
        siteId: "",
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        name: "",
      },
      tableChecked: [],
      roleType: [],
      accountType: [
        {
          value: 0,
          label: "函授站"
        },
        {
          value: 1,
          label: "教师"
        },
        {
          value: 2,
          label: "学生"
        }
      ],
      site: [],
      value: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('sysLoginLog:list', () => {
        this.openLoadingView()
        let fromData = new FormData();
        fromData.append("siteId", this.search.siteId);
        // fromData.append("roleId",this.search.roleId);
        fromData.append("accountType", this.search.accountType);
        fromData.append("loginAccount", this.search.name);
        fromData.append("page", this.search.page);
        fromData.append("pageSize", this.search.pageSize);
        let reg = new RegExp('-', 'g')
        if (this.search.startTime != null && this.search.startTime != "") {
          let startTime = new Date(this.search.startTime).formatToLocalDateTime("yyyy/MM/dd").toString();
          fromData.append("startTime", startTime);
        }
        if (this.search.endTime != null && this.search.endTime != "") {
          let endTime = new Date(this.search.endTime).formatToLocalDateTime("yyyy/MM/dd").toString();
          fromData.append("endTime", endTime);
        }
        this.$axios_supermall.post("/hnjxjy-admin/sysLoginLog/list", fromData).then(resp => {
          this.tableData = resp.data.data.list.reverse(); //表格列表
          this.total = resp.data.data.total
        })
      });
    },
    clear() {
      this.search.endTime = ""
    },
    //查询角色
    queryAllUserType() {
      this.$axios_supermall.get("/hnjxjy-admin/sysRole/querySysRole").then(resp => {
        if (resp.data.code == 2000) {
          this.roleType = resp.data.data.list;
          this.search.roleId = 49
          this.handleSearch();
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll", "1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    rest(form) {
      this.$refs.search.resetFields()
    },
    // 点击导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-admin/sysLoginLog/export",
        responseType: "blob",
        headers: objPar,
        params: {
          "siteId": this.search.siteId,
          "roleId": this.search.roleId,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
          this.loadingView.close()
          this.$message.success("操作成功")
        })
        .catch((err) => {
        });
    },
    handleSelectionChange(val) {
      this.tableChecked = val;
    },
    // 点击修改
    editor(row) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  /* margin-left: 1rem; */
  padding: 1rem;
}

.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}

.el-form-input-width {
  width: 100%;
}

.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}
</style>
