<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                毕业学生设置
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationConditionsStu:set'" @click="setGraduationById">设置毕业</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationConditionsStu:yes'" @click="setEduGraduateConditionSet">应用毕业条件</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGrduationConditionsStu:import'" @click="importDialog=true">导入毕业库</el-button>
                    </el-form-item>
                    <!--                    <el-form-item>-->
                    <!--                        <el-button type="primary" @click="download">导出</el-button>-->
                    <!--                    </el-form-item>-->
                    <br/>
                    <!--                    <el-form-item>-->
                    <!--                        <el-input v-model="queryBO.studentNameOrNum" placeholder="姓名/学号"-->
                    <!--                                  style="width: 410px">-->
                    <!--                            <el-button type="primary" slot="append" icon="el-icon-search"-->
                    <!--                                       @click="queryBasic"></el-button>-->
                    <!--                        </el-input>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="函授站">
                        <el-select v-model="queryBO.siteId" placeholder="请选择函授站" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in siteList"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="毕业条件" prop="modifyStatus">
                        <el-select v-model="queryBO.applyCondition" @change="queryBasic" placeholder="请选择是否更改"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in applyConditionList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="500px"
                        max-height="500px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        width="80"
                        type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            prop="studentName"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            label="站点名称">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="graduateCondition"
                            label="是否符合毕业条件">
                    </el-table-column>
                    <el-table-column
                            prop="applyCondition"
                            label="是否已应用毕业条件">
                    </el-table-column>
                    <el-table-column
                            prop="graduateStatus"
                            label="毕业状态">
                    </el-table-column>
                    <el-table-column
                            prop="createTime"
                            show-overflow-tooltip
                            label="入学时间">
                    </el-table-column>
                    <el-table-column
                            label="毕业详情">
                        <template slot-scope="scope">
                            <el-link type="primary" v-if="eduScoreStudentedit" @click="look(scope.row.studentId)">毕业详情</el-link>
                          <span v-else>暂无权限</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="学生信息"
                :visible.sync="dialog"
                width="60%">
            <div>
                <el-card class="box-card" shadow="always">
                    <div class="infinite-list-wrapper" style="overflow:auto;height:500px;">
                        <el-form label-width="80px" v-infinite-scroll infinite-scroll-disabled="disabled" class="list">
                            <el-form inline>
                                <el-form-item style="color: red;width: 23%">课程信息</el-form-item>
                                <el-form-item style="width: 23%">课程总数: {{studentInfo.courseNum}}</el-form-item>
                                <el-form-item style="color: green;width: 23%">已通过: {{studentInfo.okCourseNum}}
                                </el-form-item>
                                <el-form-item style="color: red;width: 23%">未通过: {{studentInfo.noCourseNum}}
                                </el-form-item>
                                <div v-if="studentInfo.eduFeeTuition!=null">
                                    <el-form-item style="color: red;width: 23%">费用信息</el-form-item>
                                    <el-form-item style="width: 23%">
                                        应缴费用: {{studentInfo.eduFeeTuition.amount}}
                                    </el-form-item>
                                    <el-form-item style="width: 23%">
                                        已交费用: {{studentInfo.eduFeeTuition.payAmount}}
                                    </el-form-item>
                                    <el-form-item style="width: 23%">
                                        未缴费用: {{studentInfo.eduFeeTuition.unpaidAmount}}
                                    </el-form-item>
                                </div>
                            </el-form>
                            <el-table
                                    border
                                    :data="studentInfo.eduCourseVos"
                                    style="width: 98%"
                                    size="medium"
                                    height="400px"
                                    max-height="400px"
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                            >
                                <el-table-column
                                        prop="courseName"
                                        label="课程名称">
                                </el-table-column>
                                <el-table-column
                                        prop="score"
                                        label="课程分数">
                                </el-table-column>
                                <el-table-column
                                        prop="period"
                                        label="学时">
                                </el-table-column>
                                <el-table-column
                                        prop="passFlagS"
                                        label="是否通过">
                                </el-table-column>
                                <el-table-column
                                        prop="semester"
                                        label="学期">
                                </el-table-column>
                                <el-table-column
                                        prop="stuScore"
                                        label="学分">
                                </el-table-column>
                            </el-table>
                        </el-form>
                    </div>
                </el-card>
            </div>
            <div slot="footer" class="dialog-footer" style="margin-top: 30px">
                <el-button type="primary" @click="dialog = false">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="导入" :visible.sync="importDialog" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="download">下载模板</el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "fillOutStudent.vue",

        data() {
            return {
              eduScoreStudentedit: false,
                importDialog: false,
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    siteId: null,
                    // studentNameOrNum: '',
                    applyCondition: null
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                siteList: [],//函授站下拉框列表
                totalOne: 0,//主table分页
                multipleSelection: [],
                dialog: false,
                applyConditionList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '否'},
                    {value: 1, label: '是'}
                ],
                studentInfo:{
                    eduFeeTuition:{}
                }
            }
        },
      created() {
        this.getPermission('eduGrduationConditionsStu:info', () => {
          this.eduScoreStudentedit = true;
        })
      },
        methods: {
            look(id) {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryStudentGraduationByStudentId?studentId=' + id).then(response => {
                    if (response.data.code === 2000) {
                        this.studentInfo = response.data.data;
                        this.dialog = true;
                    }
                })
            },
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
              this.openLoadingView()

              this.$axios_supermall({
                    method: "post",
                    url: "hnjxjy-core/eduGraduateReportSet/importGraduationById",
                    data: forms,
                }).then((res) => {
                        this.importDialog = false;
                        this.queryBasic();
                      this.loadingView.close()
                      this.$message.success("操作成功")
                }).catch((err) => {
                });
            },
            setEduGraduateConditionSet() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection)) {
                    this.$message.warning("请至少选择一列");
                    return;
                }
                let ids = this.getIds(this.multipleSelection, 'id');
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/applicationGraduationById', {ids: ids}).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("操作成功");
                        this.queryBasic();
                    }
                })
            },
            setGraduationById() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection)) {
                    this.$message.warning("请至少选择一列");
                    return;
                }
                let ids = this.getIds(this.multipleSelection, 'id');
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/setGraduationById', {ids: ids}).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success("操作成功");
                        this.queryBasic();
                    }
                })
            },
            download() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateReportSet/downloadTemplate",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '毕业库模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGrduationConditionsStu:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }
                if (this.getIsNull(this.queryBO.siteId)) {
                  bo += '&siteId=' + this.queryBO.siteId;
                }
                if (this.getIsNull(this.queryBO.applyCondition)) {
                  bo += '&applyCondition=' + this.queryBO.applyCondition;
                }

                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryAllEduGraduateStudentBySiteId?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                    }
                })
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            },
            //endregion
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.querySiteList();
            this.queryBasic();//基础查询
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
