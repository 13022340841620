<template>
  <div>
    <!-- 公共页面 -->
    <!-- 局部页面 -->
    <div>
      <!-- 可删除标签 -->
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        个人标准管理
      </div>
      <!-- 个人标签管理主页面 -->
      <div class="search">
        <div style="margin-left: 30px; margin-bottom: 10px;">
          <el-button
              icon="el-icon-upload2"
              type="primary"
              @click="importExcle"
              size="mini"
              v-permission="'eduFeeStandardStudent:importData'"
          >导入</el-button
          >
          <el-button
              icon="el-icon-download"
              type="primary"
              @click="downloads()"
              size="mini"
              v-permission="'eduFeeStandardStudent:export'"
          >导出</el-button
          >
          <el-button type="primary" @click="handleSearch()" size="mini"
          >查询</el-button
          >
          <el-button @click="angin(search)" size="mini">重置</el-button>
        </div>
        <el-form inline :model="search" ref="search" class="demo-ruleForm">
            <el-form-item prop="name"  label="姓名/学号" label-width="100px">
              <el-input
                  style="width: 300px"
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/学号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="siteId" label="函授站" label-width="100px">
              <el-select
                  style="width: 300px"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="gradeId" label="年级" label-width="100px">
              <el-select
                  style="width: 300px"
                  clearable
                  v-model="search.gradeId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  prop="rollStatus" label="学籍状态" label-width="100px">
              <el-select style="width: 300px" clearable v-model="search.rollStatus"    class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="stageId" label="层次" label-width="100px">
              <el-select
                  style="width: 300px"
                  clearable
                  v-model="search.stageId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  prop="profId" label="专业" label-width="100px">
              <el-select
                  style="width: 300px"
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
        </el-form>
      </div>

      <!-- 列表 -->
      <div class="table-wrap">
        <div class="checkNum">共选中 {{ checkNumber }} 条数据</div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          v-model="selectionNum"
          @selection-change="handleSelectionChange()"
          border
          size="medium"
          height="550px"
          max-height="550px"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            label="序号"
            sortable
            width="80"
            type="index"
          ></el-table-column>
          <el-table-column label="姓名" sortable prop="name" width="180">
          </el-table-column>
          <el-table-column label="学号" sortable prop="studentNo" width="180">
          </el-table-column>
          <el-table-column
            label="函数站"
            sortable
            prop="siteName"
            width="180"
          >
          </el-table-column>
          <el-table-column label="年级" sortable prop="gradeName" width="180">
          </el-table-column>
          <el-table-column label="层次" sortable prop="stageName" width="150">
          </el-table-column>
          <el-table-column label="专业" sortable prop="profName" width="130">
          </el-table-column>
          <el-table-column label="应交学费总额" sortable width="230">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.feeTotalAmount"
                controls-position="right"
                @change="updateFun(scope.row)"
                :min="0"
                size="mini"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="函数站分成(%)" width="230">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.brokerage"
                controls-position="right"
                @change="updateFun(scope.row)"
                :min="0"
                size="mini"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="每次缴费金额" width="230">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.paymentAmount"
                controls-position="right"
                @change="updateFun(scope.row)"
                :min="0"
                size="mini"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="缴费方式"  width="230">
            <template slot-scope="scope">
            <el-select   v-model="scope.row.paymentType"  @change="updateFun(scope.row)" placeholder="请选择" size="mini">
              <el-option
                v-for="item in typeOpt"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作日志" width="100">
            <template slot-scope="scope">
            <button id="el-button" v-permission="'eduFeeStandardStudentLog:listOne'" @click="Printing(scope.row)">
              日志
            </button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="fyq_Pager fixScrollbar">
          <div class="fyq_Pager_div">
            <div style="padding: 1rem">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 打印时的对话框 -->
      <div class="dialog_box">
        <el-dialog title="打印信息" :visible.sync="PrintingVisible" width="50%">
          <div class="printing">
            <div class="printing_btn">
              <el-button
                icon="el-icon-circle-close"
                type="primary"
                @click="PrintingVisible = false"
                >关闭</el-button
              >
              <el-button
                icon="el-icon-download"
                type="primary"
                @click="download"
                v-permission="'eduFeeStandardStudentLog:exportOne'"
                >导出</el-button
              >
            </div>
            <div>
              <el-table
                ref="multipleTable"
                :data="printingData"
                border
                id="el-table_print"
                width="100%"
                size="medium"
                height="350px"
                max-height="350px"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
              >
                <el-table-column label="序号" type="index" width="80">
                </el-table-column>
                <el-table-column label="时间" prop="operateTime"> </el-table-column>
                <el-table-column label="操作类型" prop="operateType">
                </el-table-column>
                <el-table-column label="操作人" prop="operate">
                </el-table-column>
                <el-table-column label="备注" prop="remark"> </el-table-column>
              </el-table>
            </div>
            <div class="person_Pager">
              <div style="padding: 1rem">
                <el-pagination
                  @size-change="handleSizeChange1"
                  @current-change="handleCurrentChange1"
                  :current-page="search.page"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="search.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totals"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </el-dialog>

        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
          <div style="margin-bottom: 20px">
            <el-button type="primary" @click="downloadbutton" v-permission="'eduFeeStandardStudent:exportMB'">下载模板</el-button>
            <span style="color: red"
            >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
            >
          </div>
          <el-upload
              class="upload-demo"
              drag
              action="#"
              :show-file-list="false"
              :http-request="uploadFile"
              :before-upload="beforeUpload"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              只能上传excel文件，且不超过50M
            </div>
          </el-upload>
        </el-dialog>

      </div>
    </div>
  </div>
</template>
<script>

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
export default {
  created() {
    this.handleSearch();
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
  },
  data() {
    return {
      // 点击框
      personStandardTag: [{ name: "个人标准管理", type: "" }],
      //   标签
      search: {
        name: "",
        siteId: "",
        stageId: "",
        gradeId: "",
        profId: "",
        rollStatus: "",
        page: 1,
        pageSize: 10,
      },
      //   列表数据
      tableData: [],
      printingData: [],
      site:[],
      eduStage:[],
      eduProfession:[],
      option:[],
      //   分页器
      currentPage4: 1,
      total: 0,
      totals: 0,
      typeOpt: [{
        value: 0,
        label: '预交费模式'
      }, {
        value: 1,
        label: '实缴费模式'
      }, {
        value: 2,
        label: '年缴费模式'
      }, ],
      //   下拉数据
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "正式生",
        },
        {
          value: "1",
          label: "退学",
        },
        {
          value: "2",
          label: "休学",
        },
        {
          value: "3",
          label: "毕业",
        },
        {
          value: "4",
          label: "肄业",
        },
      ],
      value: "",
      standardStudentId: "",
      //   共选中数据
      checkNumber: 0,
      // 选中事件
      selectionNum: false,
      //   打印对话框
      PrintingVisible: false,
      dialogVisibleUploads: false,
      //   选中数组
      checkNumberArray: [],
      //   导入对话框
      importVisible: false,
      // 导入对话框下载模板成功数
      importDialog: {
        downloadSuccess: 0,
        downloadError: 0,
        downloadNum: 0,
      },
    };
  },
  methods: {
    handleSearch(){
      this.getPermission('eduFeeStandardStudent:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("siteId",this.search.siteId);
      fromData.append("rollStatus",this.search.rollStatus);
      fromData.append("info",this.search.name);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduFeeStandardStudent/list",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
          this.tableData.forEach(t=>{
            this.$set(t,"paymentType",parseInt(t.paymentType))
          })
        }
      })
      });
    },
    Printing(row) {
      this.PrintingVisible = true;
      let fromData = new FormData();
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduFeeStandardStudentLog/listOne?standardStudentId="+row.id,fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.printingData = resp.data.data.list;
          this.totals = resp.data.data.total;
        }
      })
      this.standardStudentId = row.id
    },
    Printings() {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduFeeStandardStudentLog/listOne?standardStudentId="+this.standardStudentId,fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.printingData = resp.data.data.list;
          this.totals = resp.data.data.total;
        }
      })
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduFeeStandardStudent/importData",
        data: forms,
        headers: objPar,
      }).then((res) => {
        if (res.data.code === 2000){
          this.dialogVisibleUploads = false;
          this.handleSearch();
          this.$message.success(res.data.message)
        }else {
          this.dialogVisibleUploads = false;
          this.handleSearch();
          this.$message.error(res.data.message)
        }
      }).catch((err) => {
        this.dialogVisibleUploads = false;
        this.handleSearch();
        this.$message.error(err)
      });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduFeeStandardStudent/exportMB",
        responseType: "blob",
        headers: objPar,
        params:{
          status:1
        }
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    // 点击下载
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduFeeStandardStudentLog/exportOne?standardStudentId="+this.standardStudentId,
        responseType: "blob",
        headers: objPar,

      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
          })
          .catch((err) => {
          });
    },
    // 导出
    downloads() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      if(this.search.siteId!=null&&this.search.gradeId!=null&&this.search.siteId!=""&&this.search.gradeId!=""){
        this.$axios({
          method: "get",
          url: "hnjxjy-core/eduFeeStandardStudent/export",
          responseType: "blob",
          headers: objPar,
          params: {
            "siteId":this.search.siteId,
            "gradeId":this.search.gradeId,
            "stageId":this.search.stageId,
            "profId":this.search.profId,
            "rollStatus":this.search.rollStatus,
            "info":this.search.name,
          },
        })
            .then((res) => {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              let url = window.URL.createObjectURL(blob);
              window.location.href = url;
              this.loadingView.close()
              this.$message.success("操作成功")
            })
            .catch((err) => {
            });
      }else{
        this.$message.error("请选择函授站和年级，在进行导出操作！");
        this.loadingView.close()
      }
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },














    // Tag关闭
    async closeTag() {
      const closeTagconfirm = await this.$confirm("是否关闭页面?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (closeTagconfirm !== "confirm") {
        return this.$message.info("已取消!");
      }
      this.$router.push("/basic");
    },
    // 点击导入
    ImportD() {
      this.importVisible = true;
    },

    // 分页器
    handleSizeChange(newPage) {
      this.search.pageSize = newPage;
      this.handleSearch();
    },
    handleCurrentChange(newSize) {
      this.search.page = newSize;
      this.handleSearch();
    },
    // 分页器
    handleSizeChange1(newPages) {
      this.search.pageSize = newPages;
      this.Printings()
    },
    handleCurrentChange1(newSizes) {
      this.search.page = newSizes;
      this.Printings()
    },
    // 进度条事件
    format(percentage) {
      return percentage === 100 ? "完成" : `${percentage}%`;
    },

    // 点击导出
    exportD() {
    },
    // 点击查询
    putLook() {
    },
    // 点击重置
    angin(form) {
      this.$refs.search.resetFields()
    },
    // 点击打印

    importExcle(){
      this.dialogVisibleUploads = true
    },
    // // 总金额改变事件
    Allchange(e) {
    },
    Danchange(e) {
    },
    updateFun(row) {
      this.getPermission('eduFeeStandardStudent:edit', () => {
      let formData = new FormData();
      if (row!=null){
      formData.append("id", row.id);
      formData.append("feeTotalAmount", row.feeTotalAmount);
      formData.append("paymentAmount", row.paymentAmount);
      formData.append("paymentType", row.paymentType);
      formData.append("brokerage", row.brokerage);
      }
      this.$axios_supermallData.post("/hnjxjy-core/eduFeeStandardStudent/edit", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("修改成功");
        }
      })
      });
    },
    // 下拉菜单改变
    handleSelectionChange(e) {
    },
  },
};
</script>
<style scoped>
html {
  font-size: 16px !important;
}
/* 隐藏分页在火狐浏览器中存在的滚动跳 */
.fixScrollbar {
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}
.search {
  padding-top: 0.5rem;
}
.table-wrap {
  overflow: auto;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 3.3rem);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
  padding: 1rem;
}
.left {
  width: 7%;
  height: calc(100vh - 3.3rem);
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 1rem;
  border-radius: 0.4rem;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 1rem;
  border-radius: 0.4rem;
}
.checkInput {
  width: 100%;
  font-weight: 600;
  display: flex;
  font-size: .8rem;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0 0 0;
}
.delBq {
  text-align: left;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
.el-form-item {
  width: 33%;
  margin-right: 0 !important;
  justify-content: flex-start;
  font-size: 1rem;
}
.checkInput .row div span {
  width: 5rem;
  display: inline-block;
  text-align: right;
  padding: 0 0.4rem 0 0;
}
.checkInput .row div >>> .el-form-item__content {
  display: flex;
}
.el-form-item .el-input,
.el-form-item .el-select {
  width: 25rem;
}

.checkbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-wrap >>> .el-table th > .cell {
  text-align: center !important;
}
.table-wrap >>> .el-table .cell {
  text-align: center !important;
}
.table-wrap >>> .el-input-number {
  width: 100% !important;
}
.table-wrap >>> .el-table th {
  padding: 0.3rem 0 !important;
}
#el-button {
  color: #409eff !important;
  outline: none;
  border: none !important;
}

.printing >>> .el-table th > .cell {
  text-align: center !important;
}
.printing >>> .el-table .cell {
  text-align: center !important;
}

.printing_btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0.7rem 0;
}
.printing_btn .el-button {
  font-size: 0.9rem;
  padding: 0.4rem;
}
.row div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* 分页器 */
.fyq_Pager {
  font-size: 1rem;
  width: 100%;
  background: #fff;
  overflow-x: scroll;
}
.fyq_Pager::-webkit-scrollbar {
  height: 0;
  width: 0;
}
/* 导入对话框 */
.download {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.download span {
  color: #f00;
  margin: 0 0 0 0.5rem;
}
.download_down {
  margin: 1rem 0 0;
}
@media (max-width: 580px) {
  .checkbtn .el-button {
    margin: 0.2rem 0;
  }
  .el-form-item .el-input,
  .el-form-item .el-select {
    width: 10rem !important;
  }
  /* 下载 */
  .download {
    display: flex;
    flex-flow: column nowrap;
  }
  .download_down >>> .el-upload-dragger {
    width: 100% !important;
  }
  /* 对话框 */
  .dialog_box {
    width: 100% !important;
  }
  .dialog_box >>> .el-dialog {
    width: 100% !important;
  }
  /* 分页器 */
  .person_Pager {
    font-size: 1rem;
    width: 100%;
    background: #fff;
    overflow-x: scroll;
  }
  .person_Pager::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}
.importDialog_btn {
  width: 100%;
  display: flex;
}
.importDialog_btn span {
  line-height: 2.6rem;
}
.eTishi {
  margin: 0 0 2.6rem 0;
}
.ImportnumDiv {
  margin: 0.7rem 0 0 0;
}
.checkNum {
  width: 100%;
  height: 30px;
  color: #409eff;
  line-height: 30px;
  text-align: left;
}
.chuan {
  width: 50%;
  height: 9rem;
  margin: 0 auto;
  border: 1px dashed #999;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.chuan p:nth-child(1) {
  font-size: 5rem;
  margin: 0.7rem 0 0 0;
}
.chuan p:nth-child(2) {
  width: 100%;
  position: relative;
}
.chuan p:nth-child(2) span {
  color: #409eff;
}
.chuan p:nth-child(2) input:hover {
  cursor: pointer;
}
.chuan p:nth-child(2) input {
  width: 8rem;
  opacity: 0;
  position: absolute;
  top: -0.4rem;
  right: 16rem;
}
.el-input-number.is-controls-right .el-input-number__decrease {
  color: #000 !important;
}

.table-wrap >>> .el-icon-arrow-down {
  color: #606266 !important;
}
.table-wrap >>> #el-button {
  background: rgba(0, 0, 0, 0);
}
.fyq {
  background: #fff;
}
</style>
