<template>
  <div id="app">
    <!-- 引用头部样式 -->
    <div class="header">
      <div class="content">
        <!-- 右侧边栏导航部分 -->
        <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
          教材类别管理
        </div>

        <!-- 搜索 添加 导出 -->
        <div style="margin-top: 10px;margin-left: 10px;margin-bottom: 10px">
          <el-input placeholder="类型编号/类型名称" v-model="search.name" style="width: 300px">
            <el-button slot="append" icon="el-icon-search" class="append" @click="handleSearch"></el-button>
          </el-input>
          <el-button type="primary" v-permission="'eduTextbookClass:add'" @click="add"
            style="margin-left: 10px">添加</el-button>
          <el-button @click="download" type="primary" v-permission="'eduTextbookClass:export'"
            style="background-color: rgb(24, 144, 255); color: #fff">导出</el-button>
        </div>
        <!-- 表单 -->
        <div class="table-wrap">
          <el-table class="multipleTable" ref="multipleTable" border :data="tableData" tooltip-effect="dark"
            style="width: 98%;   margin-left: 10px;" size="medium" height="650px" max-height="650px"
            :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
            <el-table-column label="序号" type="index" width="80">

            </el-table-column>
            <el-table-column prop="className" label="类别名称">
            </el-table-column>
            <el-table-column prop="classNo" label="类别编号" show-overflow-tooltip width="202">
            </el-table-column>
            <el-table-column prop="discount" label="折扣（可编辑）">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.discount" @change="handleChange(scope.row)" :max="10"
                  label="描述文字"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column prop="discountRemark" label="备注（可编辑）">
              <template slot-scope="scope">
                <el-input @change="handleChange(scope.row)" v-model="scope.row.remark" placeholder="请输入内容"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="boolen" label="操作" width="124">
              <template slot-scope="scope">
                <el-button type="primary" v-permission="'eduTextbookClass:edit'"
                  @click="editor(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- </el-table> -->
          <div style="padding: 1rem;   margin-left: 10px;" class="bottom">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="search.page" :page-sizes="[10, 20, 50, 100]" :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="this.total">
            </el-pagination>
          </div>
        </div>

        <!-- 添加 -->
        <el-dialog title="添加类别" :visible.sync="dialogVisibleUploadAdd" width="35%" style="height: 580px" class="dialog">
          <div style="text-align: center;">
            <div style="margin-bottom: 20px">
              <span class="star">*</span><span style=" color: #64666A;
        font-weight: bold;
     "> 类别名称：</span><el-input v-model="uodatebook.className" placeholder="请输入内容" style="width: 250px"></el-input>
            </div>
            <div style="margin-bottom: 30px">
              <span class="star">*</span> <span style=" color: #64666A;
        font-weight: bold;
     ">类别编号：</span><el-input v-model="uodatebook.classNo" placeholder="请输入内容" style="width: 250px"></el-input>
            </div>
            <div>
              <span style=" color: #64666A;
        font-weight: bold;
     ">备注：</span><el-input type="textarea" :rows="3" style="
                  margin-left: -30px;
                  width: 250px;
                  vertical-align: top;
                  list-style: none;
                  outline: none;
                " placeholder="请输入内容" v-model="uodatebook.remark">
              </el-input>
            </div>
          </div>
          <div style="margin-left: 150px;margin-top: 50px">
            <el-button @click="dialogVisibleUploadAdd = false">关闭</el-button>
            <el-button type="primary" @click="submitAdd">保存后关闭</el-button>
          </div>
        </el-dialog>

        <!-- 编辑 -->
        <el-dialog title="修改类别" :visible.sync="dialogVisibleUpload1" width="35%" style="height: 580px" class="dialog">
          <div style="text-align: center;">
            <div style="margin-bottom: 20px">
              <span class="star">*</span><span style=" color: #64666A;
        font-weight: bold;
     "> 类别名称：</span><el-input v-model="uodatebook.className" placeholder="请输入内容" style="width: 250px"></el-input>
            </div>
            <div style="margin-bottom: 30px">
              <span class="star">*</span> <span style=" color: #64666A;
        font-weight: bold;
     ">类别编号：</span><el-input v-model="uodatebook.classNo" placeholder="请输入内容" style="width: 250px"></el-input>
            </div>
            <div>
              <span style=" color: #64666A;
        font-weight: bold;
     ">备注：</span><el-input type="textarea" :rows="3" style="
                margin-left: -30px;
                  width: 250px;
                  vertical-align: top;
                  list-style: none;
                  outline: none;
                " placeholder="请输入内容" v-model="uodatebook.remark">
              </el-input>
            </div>
          </div>
          <div style="margin-left: 150px;margin-top: 50px">
            <el-button @click="dialogVisibleUpload1 = false">关闭</el-button>
            <el-button type="primary" @click="updatebook">保存后关闭</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch();
  },
  data() {
    return {
      title: "",
      search: {
        name: "",
        page: 1,
        pageSize: 10,
      },
      uodatebook: {
        className: "",
        classNo: "",
        remark: "",
        discount: 0,
        discountRemark: "",
        id: "",
      },
      num: 1,
      total: 0,
      textarea: "",
      dialogVisibleUpload1: false,
      dialogVisibleUploadAdd: false,
      tableData: [],
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduTextbookClass:list', () => {
        this.openLoadingView()
        this.$axios_supermall.get("/hnjxjy-core/eduTextbookClass/list?&page=" + this.search.page + "&pageSize=" + this.search.pageSize + "&info=" + this.search.name).then(resp => {
          this.tableData = resp.data.data.list; //表格列表
          this.total = resp.data.data.total;
        })
      });
    },
    add() {
      this.uodatebook.className = ""
      this.uodatebook.classNo = ""
      this.uodatebook.remark = ""
      this.dialogVisibleUploadAdd = true;
    },
    submitAdd() {
      let formData = new FormData();
      if (this.uodatebook.className.length == 0 || this.uodatebook.classNo.length == 0 ) {

        this.$message.warning("带*号的数据不能为空")
        return 
      }

      if(this.uodatebook.remark.trim().length >20){
        this.$message.warning("备注不能超过20个字符");
        return
      }


      if (this.uodatebook.className.trim().length != 0) {
        formData.append("className", this.uodatebook.className);
      }
      if (this.uodatebook.classNo.trim().length != 0) {
        formData.append("classNo", this.uodatebook.classNo);
      }

      if (this.uodatebook.remark.trim().length != 0) {
        formData.append("remark", this.uodatebook.remark);
      }

      this.$axios_supermallData.post("hnjxjy-core/eduTextbookClass/add", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作完成");
          this.handleSearch();
          this.dialogVisibleUploadAdd = false;
        }
      })
    },

    handleChange(row) {
      this.getPermission('eduTextbookClass:edit', () => {
        let formData = new FormData();
        formData.append("id", row.id)
        formData.append("className", row.className);
        formData.append("classNo", row.classNo);
        formData.append("remark", row.remark);
        formData.append("discount", row.discount);
        this.$axios_supermallData.post("/hnjxjy-core/eduTextbookClass/edit", formData).then(resp => {
          if (resp.data.code == 2000) {
            this.$message.success("修改成功");
            this.handleSearch();
          }
        })
      });
    },
    editor(row) {
      this.dialogVisibleUpload1 = true;
      this.$axios_supermall.get("/hnjxjy-core/eduTextbookClass/getOne?id=" + row.id).then(resp => {
        if (resp.data.code == 2000) {
          this.uodatebook = resp.data.data;
        }
      })
    },
    updatebook(row) {
      let fromData = new FormData();
      fromData.append("className", this.uodatebook.className);
      fromData.append("classNo", this.uodatebook.classNo);
      fromData.append("remark", this.uodatebook.remark);
      fromData.append("id", this.uodatebook.id);
      this.$axios_supermall.post("hnjxjy-core/eduTextbookClass/edit", fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作完成");
          this.handleSearch();
          this.dialogVisibleUpload1 = false;
        }
      })
    },
    // 导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduTextbookClass/export",
        responseType: "blob",
        headers: objPar,
        params: {
          "info": this.search.name,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
          this.loadingView.close()
          this.$message.success("操作成功")
        })
        .catch((err) => {
        });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
/* 通用样式 */
html {
  font-size: 16px !important;
}

/* 头部下面侧边栏css样式 */
/* 左侧 */
.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.header_top {
  width: 7%;
}

/* 右侧 */
.content {
  width: 100%;
}

/* 右侧导航栏css */
.course {
  line-height: 3.4rem;
  text-align: left;
}

/* 搜索 添加 导出 */
.export {
  line-height: 3.1rem;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0.9rem;
  padding-top: 1rem;
}

/* 搜索框样式 */
.export>>>.el-input {
  width: 33.2rem;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.export>>>.el-input {
  margin-right: 1rem;
}

.export>>>.el-input .el-input__inner::-webkit-input-placeholder {
  font-size: 1rem;
}

.append {
  background-color: rgb(24, 144, 255) !important;
  color: #fff !important;

}

/* 表单 */
.table-wrap {
  height: 750px;
  background-color: rgba(164, 174, 224, 0.11);
}

/* 底部分页 */
.bottom {
  width: 98%;
  /* background-color: #f00; */
  display: flex;
  justify-content: flex-start;
}

.table-wrap>>>.el-table th,
.table-wrap>>>.el-table td {
  text-align: center;
}

/* 编辑页面样式 */
.dialog {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.dialog>>>.el-textarea__inner {
  resize: none !important;
  margin-left: 35px;
}

.star {
  color: #f00;
}
</style>
