<template>
  <div>
    <!-- 共用左边导航栏 -->
    <main class="main">
      <!-- 右边teachingmaterial页面 -->
      <article class="right">
        <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
          教材列表
        </div>
        <div class="boxss">
          <!-- 添加-导出 -->
          <el-button type="primary" icon="el-icon-edit" v-permission="'eduTextbook:add'" @click="add">添加</el-button>
          <el-button type="primary" icon="el-icon-upload2" v-permission="'eduTextbook:importData'"
            @click="importData">导入</el-button>
          <el-button type="primary" icon="el-icon-download" v-permission="'eduTextbook:export'"
            @click="download">导出</el-button>
        </div>
        <!-- 按钮 -->
        <div class="boxs row">
          <!-- 搜索框 -->
          <el-input style="width: 300px" placeholder="请输入教材名称/教材编号/ISBN/主编" v-model="search.name">
            <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
        </div>
        <!-- 表格 -->
        <div class="sheet">
          <el-table :data="tableData" border style="width: 100%" size="medium" height="600px" max-height="600px"
            :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="bookNo" label="教材编号" sortable>
            </el-table-column>
            <el-table-column prop="bookName" label="教材名称" sortable>
            </el-table-column>
            <el-table-column prop="mainEditor" label="主编" sortable>
            </el-table-column>
            <el-table-column prop="price" label="单价" sortable>
            </el-table-column>
            <el-table-column prop="pressName" label="出版社" sortable>
            </el-table-column>
            <el-table-column prop="edition" label="版次" sortable>
            </el-table-column>
            <el-table-column prop="isbn" label="ISBN" sortable>
            </el-table-column>
            <el-table-column prop="className" label="类别" sortable>
            </el-table-column>
            <el-table-column prop="discount" label="折扣" sortable>
            </el-table-column>
            <el-table-column prop="sellPrice" label="售价(元)" sortable>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div style="padding: 1rem">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="search.page" :page-sizes="[10, 20, 50, 100]" :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="this.total">
            </el-pagination>
          </div>
        </div>

        <el-dialog title="添加" :visible.sync="dialogVisibleAdd" width="50%" style="text-align: left">
          <el-form size="small" :model="bo" ref="ruleForm" class="demo-ruleForm" label-width="120px" :rules="formRules">
            <el-row>
              <el-col :span="12">
                <el-form-item label="教材名称：" prop="bookName">
                  <el-input style="width: 200px" v-model="bo.bookName" placeholder="请输入（必填）" class="el-form-input-width"
                    @input="() => (bo.bookName = bo.bookName.trim())"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="教材编号：" prop="bookNo">
                  <el-input style="width: 200px" v-model="bo.bookNo" placeholder="请输入（必填）" class="el-form-input-width"
                    @input="() => (bo.bookNo = bo.bookNo.trim())"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="类别：" prop="bookClassId">
                  <el-select style="width: 200px" clearable v-model="bo.bookClassId" placeholder="请输入（必填）"
                    class="el-form-input-width">
                    <el-option v-for="item in classType" :key="item.id" :label="item.className" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="折扣：" prop="discount">
                  <el-input-number @change="sellPriceChange" :max="10" :min="0" style="width: 200px;"
                    placeholder="请输入（必填）" value="0" v-model="bo.discount" class="el-form-input-width">
                    <!--                    <el-button slot="append" icon="el-icon-plus"></el-button>-->
                    <!--                    <el-button slot="append" icon="el-icon-minus"></el-button>-->
                  </el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
            </el-row>
            <el-row>

            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出版社：" prop="pressName">
                  <el-input style="width: 200px" v-model="bo.pressName" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="版次：" prop="edition">
                  <el-input style="width: 200px" v-model="bo.edition" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="主编：" prop="mainEditor">
                  <el-input style="width: 200px" v-model="bo.mainEditor" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="ISBN：" prop="isbn">
                  <el-input style="width: 200px" v-model="bo.isbn" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="单价：" prop="price">
                  <el-input-number @change="sellPriceChange" style="width: 200px;" v-model="bo.price" :min=0
                    placeholder="请输入（必填）" value="0" class="el-form-input-width">
                    <el-button slot="append" icon="el-icon-plus"></el-button>
                    <el-button slot="append" icon="el-icon-minus"></el-button>
                  </el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售价：" prop="schoolCode">
                  <el-input style="width: 200px" disabled="disabled" :rows="2" v-model="bo.sellPrice">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="备注：" prop="remark">
                  <el-input class="remark" style="width: 70.5vh; " :rows="2" placeholder="请输入内容" v-model="bo.remark">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisibleAdd = false">取 消</el-button>
            <el-button size="small" type="primary" @click="sumbitAdd('ruleForm')">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" style="text-align: left">
          <el-form size="small" :model="bo" ref="ruleForm" class="demo-ruleForm" label-width="120px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="教材名称：" prop="bookName">
                  <el-input style="width: 200px" v-model="bo.bookName" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="教材编号：" prop="bookNo">
                  <el-input style="width: 200px" v-model="bo.bookNo" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="类别：" prop="bookClassId">
                  <el-select style="width: 200px" clearable v-model="bo.bookClassId" placeholder="请选择"
                    class="el-form-input-width">
                    <el-option v-for="item in classType" :key="item.id" :label="item.className" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="折扣：" prop="discount">
                  <el-input-number @change="sellPriceChange" style="width: 200px;" v-model="bo.discount" :max="10"
                    :min="0" placeholder="请输入" class="el-form-input-width">
                    <el-button slot="append" icon="el-icon-plus"></el-button>
                    <el-button slot="append" icon="el-icon-minus"></el-button>
                  </el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
            </el-row>
            <el-row>

            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出版社：" prop="pressName">
                  <el-input style="width: 200px" v-model="bo.pressName" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="版次：" prop="edition">
                  <el-input style="width: 200px" v-model="bo.edition" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="主编：" prop="mainEditor">
                  <el-input style="width: 200px" v-model="bo.mainEditor" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="ISBN：" prop="isbn">
                  <el-input style="width: 200px" v-model="bo.isbn" placeholder="请输入"
                    class="el-form-input-width"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="单价：" prop="price">
                  <el-input-number @change="sellPriceChange" style="width: 200px;" v-model="bo.price" :min="0"
                    placeholder="请输入" class="el-form-input-width">
                    <el-button slot="append" icon="el-icon-plus"></el-button>
                    <el-button slot="append" icon="el-icon-minus"></el-button>
                  </el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售价：" prop="sellPrice">
                  <el-input style="width: 200px" disabled="disabled" :rows="2" v-model="bo.sellPrice">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="备注：" prop="remark">
                  <el-input class="remark" style="width: 70.5vh " :rows="2" placeholder="请输入内容" v-model="bo.remark">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button size="small" type="primary" v-permission="'eduTextbook:edit'" @click="handleSave('ruleForm')">确
              定</el-button>
          </span>
        </el-dialog>

        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
          <div style="margin-bottom: 20px">
            <el-button type="primary" @click="downloadbutton">下载模板</el-button>
            <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
          </div>
          <el-upload class="upload-demo" drag action="#" :show-file-list="false" :http-request="uploadFile"
            :before-upload="beforeUpload">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              只能上传excel文件，且不超过50M
            </div>
          </el-upload>
        </el-dialog>

      </article>
    </main>
  </div>
</template>
<script>
//import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.min.js";
export default {
  created() {
    this.handleSearch();
    this.queryTextbookClass()
  },
  data() {
    return {
      total: 0, //输入框
      bo: {
        id: "",
        bookName: "",
        bookNo: "",
        bookClassId: "",
        price: "",
        sellPrice: "",
        discount: "",
        pressName: "",
        edition: "",
        mainEditor: "",
        isbn: "",
        remark: "",
      }, //输入框
      tableData: [], //表格
      classType: [], //表格
      search: {
        name: "",
        page: 1,
        pageSize: 10,
      },
      dialogVisible: false, //判断编辑
      dialogVisibleAdd: false, //判断编辑
      dialogVisibleUploads: false, //判断编辑
      form: {
        teachingname: '',
        sort: "",
      },
      formLabelWidth: "120px",
      formRules: {
        bookName: [{ required: true, trigger: 'blur', message: '请输入教材名称' }],
        bookNo: [{ required: true, trigger: 'blur', message: '请输入教材编号' },],
        bookClassId: [{ required: true, message: '请选择类别', trigger: 'blur' }],
        price: [{ required: true, trigger: 'blur', message: '请输入单价' },],
        sellPrice: [{ required: true, trigger: 'blur', message: '请输入售价' },],
        discount: [{ required: true, trigger: 'blur', message: '请输入折扣' },],
        pressName: [{ required: true, trigger: 'blur', message: '请输入出版社' },],
        edition: [{ required: true, trigger: 'blur', message: '请输入版次' },],
        mainEditor: [{ required: true, trigger: 'blur', message: '请输入主编' },],
        isbn: [{ required: true, trigger: 'blur', message: '请输入ISBN' },],
        remark: [{ required: true, trigger: 'blur', message: '请输入备注' },],
      },
      ruleForm: ""
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduTextbook:list', () => {
        this.openLoadingView()
        this.$axios_supermall.get("/hnjxjy-core/eduTextbook/list?&page=" + this.search.page + "&pageSize=" + this.search.pageSize + "&info=" + this.search.name).then(resp => {
          this.tableData = resp.data.data.list; //表格列表
          this.total = resp.data.data.total;
        })
      });
    },
    sellPriceChange() {
      this.bo.sellPrice = this.bo.discount / 10 * this.bo.price
      this.bo.sellPrice = Number(this.bo.sellPrice).toFixed(2)
    },
    //查询类别
    queryTextbookClass() {
      this.$axios_supermall.get("/hnjxjy-core/eduTextbookClass/list").then(resp => {
        if (resp.data.code == 2000) {
          this.classType = resp.data.data;
        }
      })
    },
    handleClick(row) {
      this.dialogVisible = true
      this.openLoadingView()
      this.$axios_supermall.get("/hnjxjy-core/eduTextbook/getOne?id=" + row.id).then(resp => {
        if (resp.data.code == 2000) {
          this.bo = resp.data.data;
        }
      })
    },
    handleSave(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          let fromData = new FormData();
          fromData.append("bookName", this.bo.bookName);
          fromData.append("bookNo", this.bo.bookNo);
          fromData.append("id", this.bo.id);
          fromData.append("bookClassId", this.bo.bookClassId);
          fromData.append("price", this.bo.price);
          fromData.append("sellPrice", this.bo.sellPrice);
          fromData.append("discount", this.bo.discount);
          fromData.append("pressName", this.bo.pressName);
          fromData.append("edition", this.bo.edition);
          fromData.append("mainEditor", this.bo.mainEditor);
          fromData.append("isbn", this.bo.isbn);
          fromData.append("remark", this.bo.remark);
          this.$axios_supermall.post("hnjxjy-core/eduTextbook/edit", fromData).then(resp => {
            if (resp.data.code == 2000) {
              this.$message.success("操作完成");
              this.handleSearch();
              this.dialogVisible = false;
            }
          })
        }
      });

    },
    add() {
      this.dialogVisibleAdd = true
      this.bo = {}
    },
    sumbitAdd(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$axios_supermall.post("hnjxjy-core/eduTextbook/add",
            {
              "bookName": this.bo.bookName,
              "bookNo": this.bo.bookNo,
              "id": this.bo.id,
              "bookClassId": this.bo.bookClassId,
              "price": this.bo.price,
              "sellPrice": this.bo.sellPrice,
              "discount": this.bo.discount,
              "pressName": this.bo.pressName,
              "edition": this.bo.edition,
              "mainEditor": this.bo.mainEditor,
              "isbn": this.bo.isbn,
              "remark": this.bo.remark,
            }
          ).then(resp => {
            if (resp.data.code == 2000) {
              this.$message.success("操作完成");
              this.handleSearch();
              this.dialogVisibleAdd = false;
            }
          })
        }
      });
    },
    // 导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduTextbook/export",
        responseType: "blob",
        headers: objPar,
        params: {
          "info": this.search.name,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
          this.loadingView.close()
          this.$message.success("操作成功")
        })
        .catch((err) => {
        });
    },
    importData() {
      this.dialogVisibleUploads = true
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduTextbook/importData",
        data: forms,
        headers: objPar,
      })
        .then((res) => {
          if (res.data.code == 2000) {
            this.$message.success("操作成功")
            this.dialogVisibleUploads = false
            this.loadingView.close()
            this.handleSearch()
          }
        })
        .catch((err) => {
        });
    },
    downloadbutton() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduTextbook/exportMB",
        responseType: "blob",
        headers: objPar,
        params: {
          status: 1
        }
      })
        .then((res) => {
          this.openLoadingView()
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
          this.loadingView.close()
          this.$message.success("操作成功")
        })
        .catch((err) => {
        });
    },
    handleSizeChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
html {
  font-size: 0.16rem !important;
}

/* 主题 */
.main {
  display: flex;
}

/* 侧边栏 */
.left {}

/* 右边 */
.right {
  width: 98%;
  padding: 0.1rem;
}

/* 按钮 */
.boxs {
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
  margin-left: 1.1rem;
}

.boxss {
  display: flex;
  margin-top: 10px;
  margin-left: 20px;
}

/* 输入框 */
.boxs-input {
  display: flex;
}

.boxs-input>>>.el-input__inner {
  font-size: 0.14rem;
}

.box-button {
  margin-left: 1rem;
}

.sheet {
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
  margin-left: 1.1rem;
  /*overflow-x: scroll;*/
}

@media (max-width: 765px) {
  .left {
    display: none;
  }

  .rigtht {
    width: 100%;
  }

  .box-button {
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
    margin-left: 1.1rem;
  }
}
</style>
