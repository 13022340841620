<template>
  <div id="app">
    <!-- 引用头部样式 -->

    <div class="header">

      <div class="content">
        <!-- 右侧边栏导航部分 -->
        <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
          课程关联教材
        </div>
        <!-- 搜索 添加 导出 -->
        <div style="margin-top: 10px;margin-left: 10px;margin-bottom: 10px">
          <el-input
              placeholder="课程编号/课程名称"
              v-model="search.name"
              class="input-with-select"
              style="width: 300px"
          >
            <el-button
                slot="append"
                icon="el-icon-search"
                class="append"
                @click="handleSearch"></el-button>
          </el-input>
          <el-button type="primary" @click="importData" style="margin-left: 10px">导入</el-button>
          <el-button @click="download" type="primary" v-permission="'eduTextbookCourse:export'" style="background-color: rgb(24, 144, 255); color: #fff">导出</el-button>
        </div>
        <!-- 表单 -->
        <div class="table-wrap">
          <el-table
              class="multipleTable"
              ref="multipleTable"
              border
              size="medium"
              height="650px"
              max-height="650px"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 98%;   margin-left: 10px;"
          >
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="courseCode" label="课程编号" sortable >
            </el-table-column>
            <el-table-column prop="courseName" label="课程名称" sortable>
            </el-table-column>
            <el-table-column prop="books" label="关联教材名称" sortable >
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                    @click="guanlian(scope.row)"
                    type="text"
                    size="small"
                >关联教材
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- </el-table> -->
          <div style="padding: 1rem;margin-left: 10px;" class="bottom">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total"
            >
            </el-pagination>
          </div>
        </div>

        <el-dialog
            title="关联教材"
            :visible.sync="dialogVisible2"
            width="60%"
        >
          <div class="export col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <el-input
                placeholder="教材名称/教材编号/ISBN/主编"
                v-model="searchs.name"
                class="input-with-select"
            >
              <el-button
                  slot="append"
                  icon="el-icon-search"
                  class="append"
                  @click="guanlians"></el-button>
            </el-input>
            <el-button type="primary"
                       @click="offs"><i class="el-icon-edit"></i> 关闭</el-button
            >

            <el-button
                @click="add"
                type="primary"
                v-permission="'eduTextbookCourse:add'"
                style="background-color: rgb(24, 144, 255); color: #fff"
            ><i class="el-icon-download" style="color: #fff"></i>
              添加</el-button
            >
            <el-button type="primary"
                       v-permission="'eduTextbookCourse:remove'"
                       @click="del"><i class="el-icon-edit"></i> 删除</el-button
            >
          </div>
          <div style="margin-top:1rem">
            <el-table :data="tableDatas" border style="width: 100%" @selection-change="handleSelectionChange"
                      size="medium"
                      :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column type="index" label="序号" width="60">
              </el-table-column>
              <el-table-column prop="bookName" label="教材名称" >
              </el-table-column>
              <el-table-column prop="bookNo" label="教材编号" >
              </el-table-column>
              <el-table-column prop="mainEditor" label="主编" >
              </el-table-column>
              <el-table-column prop="price" label="单价">
              </el-table-column>
              <el-table-column prop="pressName" label="出版社" >
              </el-table-column>
              <el-table-column prop="isbn" label="主编">
              </el-table-column>
              <el-table-column prop="price" label="售价">
              </el-table-column>
            </el-table>
            <div style="padding: 1rem">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="search.page"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="search.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totals"
              >
              </el-pagination>
            </div>
          </div>
        </el-dialog>

        <el-dialog
            title="关联教材1"
            :visible.sync="dialogVisible3"
            width="60%"
        >
          <div class="export col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <el-input
                placeholder="教材名称/教材编号/ISBN/主编"
                v-model="searchs.name"
                class="input-with-select"
            >
              <el-button
                  slot="append"
                  icon="el-icon-search"
                  class="append"
                  @click="add()"></el-button>
            </el-input>
            <el-button type="primary"
                       @click="offss"><i class="el-icon-edit"></i> 关闭</el-button
            >
            <el-button
                @click="adds"
                type="primary"

                style="background-color: rgb(24, 144, 255); color: #fff"
            ><i class="el-icon-download" style="color: #fff"></i>
              保存并关闭</el-button>
          </div>
          <div style="margin-top:1rem">
            <el-table :data="tableDataes" border style="width: 100%" @selection-change="handleSelectionChange"
                      size="medium"
                      :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="id" label="序号" width="60">
              </el-table-column>
              <el-table-column prop="bookName" label="教材名称" >
              </el-table-column>
              <el-table-column prop="bookNo" label="教材编号" >
              </el-table-column>
              <el-table-column prop="mainEditor" label="主编" >
              </el-table-column>
              <el-table-column prop="price" label="单价">
              </el-table-column>
              <el-table-column prop="pressName" label="出版社" >
              </el-table-column>
              <el-table-column prop="isbn" label="主编">
              </el-table-column>
              <el-table-column prop="sellPrice" label="售价">
              </el-table-column>
            </el-table>
            <div style="padding: 1rem">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="search.page"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="search.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totales"
              >
              </el-pagination>
            </div>
          </div>
        </el-dialog>

        <!-- 导入 上传文件 -->
        <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
          <div style="margin-bottom: 20px">
            <el-button type="primary" @click="downloadbutton">下载模板</el-button>
            <span style="color: red"
            >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
            >
          </div>
          <el-upload
              class="upload-demo"
              drag
              action="#"
              :show-file-list="false"
              :http-request="uploadFile"
              :before-upload="beforeUpload"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              只能上传excel文件，且不超过50M
            </div>
          </el-upload>
        </el-dialog>

      </div>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch();
  },
  data() {
    return {
      search:{
        name:"",
        page:1,
        pageSize:10,
      },
      searchs:{
        name:"",
        page:1,
        pageSize:10,
      },
      searches:{
        name:"",
        page:1,
        pageSize:10,
      },
      num: 1,
      courseId: 0,
      total: 0,
      totals: 0,
      totales: 0,
      textarea: "",
      dialogVisibleUploads: false,
      dialogVisible2: false,
      dialogVisible3: false,
      tableData: [],
      tableDatas: [],
      tableDataes: [],
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduTextbookCourse:list', () => {
      this.openLoadingView()
      this.$axios_supermall.post("/hnjxjy-core/eduTextbookCourse/list?&page="+this.search.page+"&pageSize="+this.search.pageSize+"&info="+this.search.name).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;
      })
      });
    },
    importData(){
      this.dialogVisibleUploads = true
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    offs(){
      this.dialogVisible2=false
    },
    offss(){
      this.dialogVisible3=false
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduTextbookCourse/importData",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
            if(res.data.code==2000){
              this.$message.success("操作成功")
              this.dialogVisibleUploads = false
              this.loadingView.close()
              this.handleSearch()
            }else{
              this.$message.error(res.data.message)
              this.dialogVisibleUploads = false
              this.loadingView.close()
              this.handleSearch()
            }
          })
          .catch((err) => {
          });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduTextbookCourse/exportMB",
        responseType: "blob",
        headers: objPar,
        params:{
          status:1
        }
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    guanlian(row){
      this.dialogVisible2 = true
      let formData = new FormData()
      formData.append("info",this.search.name);
      formData.append("id",row.id);
      this.$axios_supermall.post("/hnjxjy-core/eduTextbookCourse/getOne?page="+this.searchs.page+"&pageSize="+this.searchs.pageSize,formData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableDatas = resp.data.data.list;
          this.courseId = row.id
          this.totals = resp.data.data.total
        }
      })
    },
    guanlians(){
      this.openLoadingView()
      this.dialogVisible2 = true
      let formData = new FormData()
      formData.append("info",this.search.name);
      formData.append("id",this.courseId);
      this.$axios_supermall.post("/hnjxjy-core/eduTextbookCourse/getOne?page="+this.searchs.page+"&pageSize="+this.searchs.pageSize,formData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableDatas = resp.data.data.list;
          this.totals = resp.data.data.total
        }
      })
    },
    // 导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduTextbookCourse/export",
        responseType: "blob",
        headers: objPar,
        params: {
          "info":this.search.name,
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    add(){
      this.dialogVisible3 = true
      this.openLoadingView()
      this.$axios_supermall.get("/hnjxjy-core/eduTextbook/list?info="+this.searchs.name).then(resp => {
        if (resp.data.code == 2000) {
          this.tableDataes = resp.data.data.list;
          this.totales =resp.data.data.total;
        }
      })

    },
    adds(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduTextbookCourse/add",
              headers: this.loginList,
              method: "post",
              params: {
                bookIds: arr,
                courseId:this.courseId
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.dialogVisible3 = false
              this.dialogVisible2 = false
              this.handleSearch()
            })

                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "操作失败",
                  });
                });
          });
      }else{
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    del(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduTextbookCourse/remove",
            headers: this.loginList,
            method: "delete",
            params: {
              bookIds: arr,
              courseId:this.courseId
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.guanlians();
            }
          })
        });
      }else{
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
/* 通用样式 */
html {
  font-size: 16px !important;
}
/* 头部下面侧边栏css样式 */
/* 左侧 */
.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.header_top {
  width: 7%;
}
/* 右侧 */
.content {
  width: 100%;
}
/* 右侧导航栏css */
.course {
  line-height: 3.4rem;
  text-align: left;
}
/* 搜索 添加 导出 */
.export {
  line-height: 3.1rem;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0.9rem;
  padding-top: 1rem;
}
/* 搜索框样式 */
.export >>> .el-input {
  width: 33.2rem;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.export >>> .el-input {
  margin-right: 1rem;
}
.export >>> .el-input .el-input__inner::-webkit-input-placeholder {
  font-size: 1rem;
}
.append {
  background-color: rgb(24, 144, 255) !important;
  color: #fff !important;
}
/* 表单 */
.table-wrap {
  height: 750px;
  background-color: rgba(164, 174, 224, 0.11);
}
/* 底部分页 */
.bottom {
  width: 98%;
  /* background-color: #f00; */
  display: flex;
  justify-content: flex-start;
}
.table-wrap >>> .el-table th,
.table-wrap >>> .el-table td {
  text-align: center;
}
/* 编辑页面样式 */
.dialog {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.dialog >>> .el-textarea__inner {
  resize: none !important;
  margin-left: 35px;
}
.star {
  color: #f00;
}
</style>
