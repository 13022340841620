<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                公共课程资源库-订单列表
            </div>
            <div class="search">
                <el-form class="demo-ruleForm" inline>
                    <el-form-item>

                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable1"
                          :data="tableData"
                          border
                          size="medium"
                          height="520px"
                          max-height="520px"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}">
                    <el-table-column
                            label="序号"
                            type="index">
                    </el-table-column>
                    <el-table-column
                            label="订单号"
                            prop="orderNo">
                    </el-table-column>
                    <el-table-column
                            label="课程名称"
                            prop="courseName">
                    </el-table-column>
                    <el-table-column
                            label="课程封面"
                            prop="courseUrl">
                        <template slot-scope="scope">
                            <el-image :src="scope.row.courseUrl"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="站点名称"
                            prop="siteName">
                    </el-table-column>
                    <el-table-column
                            label="订单金额"
                            prop="orderAmount">
                    </el-table-column>
                    <el-table-column
                            label="实际支付金额"
                            prop="payAmount">
                    </el-table-column>
                    <el-table-column
                            label="下单时间"
                            prop="orderTime">
                    </el-table-column>
                    <el-table-column
                            label="商品价格"
                            prop="goodsPrice">
                    </el-table-column>
                    <el-table-column label="交易状态" prop="tradeStatus">
                        <template slot-scope="scope">
                            <span v-if="scope.row.tradeStatus===0">待支付</span>
                            <span v-if="scope.row.tradeStatus===1">待发货</span>
                            <span v-if="scope.row.tradeStatus===2">待收货</span>
                            <span v-if="scope.row.tradeStatus===3">已完成</span>
                            <span v-if="scope.row.tradeStatus===4">已取消</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付状态" prop="tradeStatus">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payStatus===0">未支付</span>
                            <span v-if="scope.row.payStatus===1">已支付</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryBO.page"
                            :page-sizes="[10, 50, 100, 500]"
                            :page-size="queryBO.pageSize"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="queryBO.total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "public-course-order",
        data() {
            return {
                tableData: [],
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                }
            }
        },
        methods: {
            queryBasic() {
                let data = '?page=' + this.queryBO.page + "&pageSize=" + this.queryBO.pageSize;
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduOrder/queryHklmOrderByLoginId' + data).then(res => {
                    if (res.data.code === 2000) {
                        this.tableData = res.data.data.list;
                        this.queryBO.total = res.data.data.total;
                    }
                })
            },
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            },
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            }
        },
        mounted() {
            this.queryBasic();
        }
    }
</script>

<style scoped>

</style>