<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                学位资格审核-毕业生
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduAcademiDegreeReadY:export'" @click="download">导出</el-button>
                    </el-form-item>
                    <el-form-item style="float: right">
                        <el-button type="primary" @click="$refs.selectForm.resetFields()">重置</el-button>
                    </el-form-item>
                    <el-form-item style="float: right">
                        <el-button type="primary" v-permission="'eduAcademiDegreeReadY:list'" @click="queryBasic">查询</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item prop="studentNameOrNum" label="姓名/学号">
                        <el-input v-model="queryBO.studentNameOrNum" placeholder="姓名/学号" style="width: 300px">
                            <el-button type="primary" slot="append" icon="el-icon-search"
                                       @click="queryBasic"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="函授站" prop="schoolId">
                        <el-select v-model="queryBO.schoolId" placeholder="请选择函授站" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in siteList"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学位状态" prop="degreeStatus">
                        <el-select v-model="queryBO.degreeStatus" @change="queryBasic" placeholder="请选择学位状态"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in degreeStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="startTime">
                        <el-date-picker
                                @change="queryBasic"
                                style="width: 300px"
                                v-model="queryBO.startTime"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间" prop="endTime">
                        <el-date-picker
                                @change="queryBasic"
                                style="width: 300px"
                                v-model="queryBO.endTime"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="500px"
                        max-height="500px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        width="55"
                            prop="id"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            prop="studentName"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="schoolName"
                            label="站点名称">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="degreeStatuss"
                            label="学位状态">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>



    export default {
        name: "fillOutStudent.vue",

        data() {
            return {
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    schoolId: null,
                    studentNameOrNum: '',
                    degreeStatus:null,
                    startTime: '',
                    endTime: ''
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                siteList: [],//函授站下拉框列表
                totalOne: 0,//主table分页
                multipleSelection: [],
                dialog: false,
                degreeStatusList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '未通过'},
                    {value: 1, label: '已通过'}
                ]
            }
        },
        methods: {
            download() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateDegreeSet/exportOffStuDeg",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '学位资格审核-毕业生.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduAcademiDegreeReadY:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;

                if (this.getIsNull(this.queryBO.studentNameOrNum)) {
                  bo += '&studentNameOrNum=' + this.queryBO.studentNameOrNum;
                }
                if (this.getIsNull(this.queryBO.schoolId)) {
                  bo += '&schoolId=' + this.queryBO.schoolId;
                }

                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }
                if (this.getIsNull(this.queryBO.degreeStatus)) {
                  bo += '&degreeStatus=' + this.queryBO.degreeStatus;
                }
                if (this.getIsNull(this.queryBO.startTime)) {
                  bo += '&startTime=' + this.queryBO.startTime.formatToLocalDateTime('yyyy/MM/dd HH:mm:ss');
                }
                if (this.getIsNull(this.queryBO.endTime)) {
                  bo += '&endTime=' + this.queryBO.endTime.formatToLocalDateTime('yyyy/MM/dd HH:mm:ss');
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateDegreeSet/queryOffStuDegByPage?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                    }
                })
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            },
            //endregion
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.querySiteList();
            this.queryBasic();//基础查询
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
