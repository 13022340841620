<template>
  <div class="order">
    <div :class="fullscreen?'YesScreen':'NoScreen'" id="rigth">
      <!-- 可删除标签 -->
      <div style="padding: 1rem; margin-bottom: 10px; border-bottom: 1px solid #ddd; text-align: left">订单管理</div>
       <!-- 按钮 -->
      <div class="order_btn">
        <div class="row">
          <div class="col-lg-10 col-md-9 col-sm-10 col-xs-12">
            <div class="row">
              <div style="margin-bottom: 10px" class="export_btn col-lg-1 col-md-1 col-sm-1 col-xs-1">
                <el-button
                    style="margin-left: 10px"
                  icon="el-icon-download"
                  type="primary"
                  size=""
                    v-permission="'eduOrder:export'"
                  @click="exportD()">导出</el-button>
                <el-button type="primary" style="margin-left: 10px" size="" @click="handleSearch()"
                >查询</el-button
                >
<!--                <el-button type="primary" style="margin-left: 10px" size="mini" @click="rest(search)"-->
<!--                >重置</el-button-->
<!--                >-->
              </div>
            </div>
          </div>
          <div class="cha col-lg-2 col-md-3 col-sm-2 col-xs-12">
            <div class="row">
            </div>
          </div>
        </div>
      </div>
      <!-- input -->
      <div class="input_check">
        <el-form :model="search" style="margin-left: 10px" label-width="120px" inline ref="search">
              <el-form-item label="订单号/姓名/学号" prop="name" style="font-weight: normal">
             <el-input  style="width: 200px" clearable v-model="search.name" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="缴费状态" prop="payStatus">
              <el-select clearable v-model="search.payStatus" style="width: 200px" placeholder="全部"  >
                <el-option
                  v-for="item in orderStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              </el-form-item>
              <el-form-item label="订单类型" prop="orderType">
              <el-select clearable v-model="search.orderType"  style="width: 200px" placeholder="全部" >
                <el-option
                  v-for="item in orderType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              </el-form-item>
              <el-form-item label="支付类型" prop="payType">
              <el-select clearable v-model="search.payType" style="width: 200px" placeholder="全部" >
                <el-option
                  v-for="item in payType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              </el-form-item>
              <el-form-item label="开始时间" prop="startTime">
              <el-date-picker style="width: 200px"  clearable
                v-model="search.startTime"
                type="datetime"
                placeholder="选择日期"
              >
              </el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                  style="width: 200px"
                 clearable
                v-model="search.endTime"
                type="datetime"
                placeholder="选择日期"
              >
              </el-date-picker>
              </el-form-item>
            <el-form-item prop="siteId"  label="函授站">
              <el-select
                  style="width: 200px"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
        </el-form>
      </div>
      <div class="box-card">
        <!-- 表格 -->
        <div class="order_table">
          <el-table :data="orderTable" border style="width: 100%"
                    size="medium"
                    height="600px"
                    max-height="600px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
          >
            <el-table-column label="详情" width="100">
              <template slot-scope="scope">
                <el-button @click="details(scope.row)" v-permission="'eduOrderDetail:getOne'" type="text" size="small"
                  >详情</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="210">
            </el-table-column>
            <el-table-column prop="studentNo" label="学号" width="230">
            </el-table-column>
            <el-table-column
              prop="orderNo"
              label="订单号"
              width="250"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="orderTime"
              label="订单时间"
              width="250"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="orderAmount"
              label="订单金额"
              width="250"
              sortable
            >
            </el-table-column>
            <el-table-column prop="orderType" label="订单类型" width="230">
            </el-table-column>
            <el-table-column prop="payStatus" label="订单状态" width="230">
            </el-table-column>
            <el-table-column prop="name" label="创建人" width="230">
            </el-table-column>
            <el-table-column prop="siteName" label="站点名称" width="230">
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="230">
            </el-table-column>
          </el-table>
        </div>

      </div>
       <!-- 分页器 -->
        <div class="order_Pager fixScrollbar">
          <div class="order_Pager_div">
            <div
              style="padding: 1rem"
            >
              <el-pagination
                @size-change="EachChange"
                @current-change="currentChange"
                :current-page="orderDefault"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      <!-- 订单详情对话框 -->
      <div class="order_dialog">
        <el-dialog
          title="订单详情"
          :visible.sync="orderVisibleDialog"
          width="50%"
        >
          <el-table :data="orderDialogList" border
                    size="medium"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    style="width: 100%">
            <el-table-column label="课程名" width="200">
              <template slot-scope="scope">
                <div v-if="null == scope.row.courseName || scope.row.courseName === ''">———</div>
                <div v-else>{{scope.row.courseName}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="courseCode" label="课程编号" width="150">
              <template slot-scope="scope">
                <div v-if="null == scope.row.courseCode || scope.row.courseCode === ''">———</div>
                <div v-else>{{scope.row.courseCode}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="stuAmount" label="学费" width="150">
              <template slot-scope="scope">
                <div v-if="null == scope.row.stuAmount || scope.row.stuAmount === ''">———</div>
                <div v-else>{{scope.row.stuAmount}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="bookAmount" label="教材费" width="200">
              <template slot-scope="scope">
                <div v-if="null == scope.row.bookAmount || scope.row.bookAmount === ''">———</div>
                <div v-else>{{scope.row.bookAmount}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="books" label="教材列表" width="220">
              <template slot-scope="scope">
                <div v-if="null == scope.row.books || scope.row.books === ''">———</div>
                <div v-else>{{scope.row.books}}</div>
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer"> </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
export default {
  created() {
    this.handleSearch()
    this.queryEduSite();
  },
  data() {
    return {
      // 可删除标签
      orderTag: [
        {
          type: "",
          name: "订单管理",
        },
      ],
      //   input
      input: "",
      site:[],
      search:{
        name:"",
        siteId:"",
        payStatus:"",
        orderType:"",
        payType:"",
        endTime:"",
        startTime:"",
        page:1,
        pageSize:10,
      },
      orderStatus: [
        {
          value: "0",
          label: "已确认",
        },
        {
          value: "1",
          label: "未确认",
        },
        {
          value: "-1",
          label: "已作废",
        },
      ],
      orderType: [
        {
          value: "0",
          label: "选课",
        },
        {
          value: "1",
          label: "学费",
        },
        {
          value: "2",
          label: "教材",
        },
        {
          value: "3",
          label: "电子教材",
        },
      ],
      payType: [
        {
          value: "0",
          label: "未支付",
        },
        {
          value: "1",
          label: "支付宝支付",
        },
        {
          value: "2",
          label: "网银",
        },
        {
          value: "3",
          label: "微信支付",
        },
        {
          value: "4",
          label: "线下支付",
        },
      ],
      value: "",
      value1: "",
      //   table表格
      orderTable: [],
      //   分页器
      // 前往的默认页数
      orderDefault: 1,
      //  总数据数
      total: 6,
      //   订单详情对话框
      orderVisibleDialog: false,
      // 订单详情对话框数据
      orderDialogList: [
        {
          className: "课程名",
          classNum: "课程编号",
          classMoney: "学费",
          teachMoney: "教材费",
          teachList: "教材列表",
        },
      ],
      fullscreen:false,
    };
  },
  methods: {
    handleSearch(){
      this.getPermission('eduOrder:list', () => {
      let fromData = new FormData();
      fromData.append("info",this.search.name);
      fromData.append("payStatus",this.search.payStatus);
      fromData.append("orderType",this.search.orderType);
      fromData.append("payType",this.search.payType);
      fromData.append("siteId",this.search.siteId);
      if(this.search.startTime!=""&&this.search.startTime!=null){
        let startTime = new Date(this.search.startTime).formatToLocalDateTime("yyyy-MM-dd").toString();
        fromData.append("startTime", startTime);
      }
      if (this.search.endTime!=""&&this.search.endTime!=null){
        let endTime = new Date(this.search.endTime).formatToLocalDateTime("yyyy-MM-dd").toString();
        fromData.append("endTime", endTime);
      }
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.openLoadingView();
      this.$axios_supermall.post("/hnjxjy-core/eduOrder/list",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.orderTable = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },


    // Tag关闭
      async closeTag(){
    const closeTagconfirm= await this.$confirm('是否关闭页面?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err=>err)
        if(closeTagconfirm!=='confirm'){
          return this.$message.info('已取消!')
        }
         this.$router.push('/basic')
    },
    //   点击导出
    exportD() {
      // 导出
        let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      if(this.search.startTime!=""&&this.search.startTime!=null&&this.search.endTime!=""&&this.search.endTime!=null){
        this.search.startTime =  this.search.startTime.formatToLocalDateTime().toString().substring(0, this.search.startTime.formatToLocalDateTime().toString().indexOf(' '))
        this.search.endTime =  this.search.endTime.formatToLocalDateTime().toString().substring(0, this.search.endTime.formatToLocalDateTime().toString().indexOf(' '))
      }
      this.openLoadingView()
          this.$axios({
            method: "get",
            url: "hnjxjy-core/eduOrder/export",
            responseType: "blob",
            headers: objPar,
            params: {
              "info":this.search.name,
              "orderStatus":this.search.orderStatus,
              "orderType":this.search.orderType,
              "payType":this.search.payType,
              "endTime":this.search.endTime,
              "startTime":this.search.startTime,
            },
          })
              .then((res) => {
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel",
                });
                let url = window.URL.createObjectURL(blob);
                window.location.href = url;
                this.$message.success("操作成功")
                this.loadingView.close()
              })
              .catch((err) => {
              });
    },

    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    // 点击查询
    putLook() {
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    // 点击重置
    angin() {
    },
    // 点击详情
    details(row) {
      this.orderVisibleDialog = true;
      this.orderDialogList=[]
      this.$axios_supermall.get("/hnjxjy-core/eduOrderDetail/getOne?orderId="+row.id).then(resp => {
        if (resp.data.code == 2000) {
          this.orderDialogList.push(resp.data.data);
        }
      })
    },
    //   每页数据改变时
    EachChange(sizePage) {
      this.search.pageSize = sizePage;
      this.handleSearch();
    },
    // 当前页改变时
    currentChange(curentPage) {
      this.search.page = curentPage;
      this.handleSearch();
    },

  },
};
</script>
<style scoped>
html{
  font-size: 16px!important;
}
/* 隐藏分页在火狐浏览器中存在的滚动跳 */
.fixScrollbar {
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}
/* 侧边栏 */
.left {
  width: 7%;
  display: inline-block;
height: calc(100vh - 3.3rem);
}
/* 右侧主体 */
.rigth {
  width: 93%;
  display: inline-block;
  /* background-color: #fff; */
  background: rgba(164, 174, 224, 0.11);
  float: right;
  padding: 1rem;
}

/* 可删除标签 */
.delBq {
  text-align: left;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
  margin: 0 0 .5rem 0;
}
/* 按钮 */
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.row div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row div .el-button {
  margin: 0.1rem 0;
}

.cost_stat_btn {
  margin: 0.5rem 0 0 0;
}
/* input */
.search {
  text-align: left;
  padding: 1rem;
}
.input_check {
  width: 100%;
}
.input_check .row div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.input_check .row div span {
  width: 30%;
  font-size: .7rem;
  text-align: left;
  padding: 0 0 0 8%;
}
.input_check .row div .el-select,
.input_check .row div .el-input {
  width: 68%;
  margin: 2% 2%;
}
/* 表格 */
.order_table >>> .el-table th > .cell {
  text-align: center !important;
}
.order_table >>> .el-table .cell {
  text-align: center !important;
}
.order_table >>> .el-table th {
  padding: 0.3rem 0 !important;
}
/* 分页器 */
.order_Pager {
  font-size: 1rem;
  width: 100%;
  background: #fff;
  overflow-x: scroll;
}
.order_Pager::-webkit-scrollbar {
  height: 0;
  width: 0;
}
/* 对话框 */

.order_dialog >>> .el-table th > .cell {
  text-align: center !important;
}
.order_dialog >>> .el-table .cell {
  text-align: center !important;
}
.order_dialog >>> .el-table th {
  padding: 0.3rem 0 !important;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.table-wrap {
  overflow: auto;
  padding: 1rem;
}

.el-form-input-width {
  width: 100%;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
@media (max-width: 580px) {

  /* 对话框 */
  .order_dialog{
    width: 100% !important;
  }
  .order_dialog>>>.el-dialog{
     width: 100% !important;
  }
}
.YesScreen{
  width: 100vw !important;
  height: 100vh !important;
  background: #fff !important;
}
.NoScreen{
}
.course {
  background-color: #f1f1f1;
  line-height: 54px;
  padding-left: 10px;
}

</style>
