<template>
  <div class="">
    <main>
      <!-- 左边侧边连 -->
      <!-- 右边内容 -->
      <article>
        <p style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left" class="">学生学习过程统计</p>
        <article class="job" style="padding-left: 15px;">
          <!-- 按钮 -->
          <div class="buttons" style="margin-left: 20px;margin-top: 10px">
            <el-button type="primary" icon="el-icon-download" v-permission="'eduStudentCourseDetail:export'" @click="download">导出</el-button>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <el-form :model="search" class="demo-ruleForm" inline style="margin-top: 1rem;width:100%;margin-left: 20px">
            <el-form-item label="登录账号" style="">
                <el-input style="width: 220px"  placeholder="登录账号(学生账号/教师工号)" v-model="search.name" class="input-with-select">
                </el-input>
            </el-form-item>
            <el-form-item  label="函授站" style="margin-bottom: 0; margin-left: 14px">
              <el-select
                  style="width: 220px"
                  @change="handleSearch"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级" style="margin-bottom: 0">
              <el-select clearable v-model="search.gradeId" @change="handleSearch"   class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="层次" style="margin-bottom: 0">
              <el-select clearable v-model="search.stageId" @change="handleSearch"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业" style="margin-bottom: 0">
              <el-select
                  @change="handleSearch"
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间" style="margin-bottom: 0">
              <el-date-picker
                  v-model="search.startTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" style="margin-bottom: 0">

              <el-date-picker
                  v-model="search.endTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <!-- 表格 -->
          <section class="tables">
            <el-table
              border
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              highlight-current-row
              :default-sort="{ prop: 'date', order: 'descending' }"
              style="width: 100%"
              size="medium"
              height="600px"
              max-height="600px"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <!-- 序号 -->
              <el-table-column type="index"
              label="序号"
               width="80">
              </el-table-column>
              <!-- 学习姓名 -->
              <el-table-column

                property="name"
                sortable
                label="学习姓名"
              >
              </el-table-column>
              <!-- 学生学号 -->
              <el-table-column
                property="studentNo"
                label="学生学号"
                sortable
              >
              </el-table-column>
              <!-- 课程名称 -->
              <el-table-column
                property="courseName"
                label="课程名称"
                sortable
              >
              </el-table-column>
              <!-- 课程编号 -->
              <el-table-column
                  width="180"
                property="courseCode"
                label="课程编号"
                sortable
              >
              </el-table-column>
              <!-- 公告数目 -->
              <el-table-column
                property="noticeNum"
                label="公告数目"
                sortable
              >
              </el-table-column>
              <!-- 学习时长(小时) -->
              <el-table-column
                property="learnDuration"
                label="学习时长(小时)"
                sortable
              >
              </el-table-column>
              <!-- 学生进度 -->
              <el-table-column
                property="learnProgress"
                label="学习进度(%)"
                sortable
                class="dian"
              >
              </el-table-column>
              <!-- 发帖数 -->
              <el-table-column
                property="postNum"
                label="发帖数"
                sortable
              >
              </el-table-column>
              <!-- 回帖数 -->
              <el-table-column
                property="replyNum"
                label="回帖数"
                sortable
              >
              </el-table-column>
              <!-- 通知公告点击数 -->
              <el-table-column
                property="noticeClickNum"
                label="通知公告点击数"
                sortable
              >
              </el-table-column>
              <!-- 学生导航点击数-->
              <el-table-column
                property="learnGuideClickNum"
                label="学生导航点击数"
                sortable
              >
              </el-table-column>
            </el-table>
          </section>
          <!-- 分页 -->
           <section class="paging">
            <div class="block">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="search.page"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="search.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.total"
              >
              </el-pagination>
            </div>
          </section>
        </article>
      </article>
    </main>
  </div>
</template>
<script>
// //import "bootstrap/dist/css/bootstrap.min.css";
// //import "bootstrap/dist/js/bootstrap.min.js";
export default {

  created() {
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
    this.handleSearch();
  },
  data() {
    return {
      input: "", //输入框
      total: 0, //输入框
      select: "", //下拉框
      selectfist: "", //年级下拉框
      hierarchy: "", //层次下拉框
      major: "", //专业
      value4: "", //开始日期
      value5: "", //结束日期
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      tableData: [], //表格
      site: [],
      eduProfession: [],
      eduStage: [],
      option: [],
      search:{
        name:"",
        siteId:"",
        gradeId:"",
        stageId:"",
        profId:"",
        startTime:"",
        endTime:"",
        page:1,
        pageSize:10,
      },
      currentRow: null,
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourseDetail:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("info",this.search.name);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
        if(this.search.startTime!=null&&this.search.startTime!=""){
          let  startTime =  new Date(this.search.startTime).formatToLocalDateTime("yyyy-MM-dd").toString();
          fromData.append("startTime",startTime);
        }
        if (this.search.endTime!=null&&this.search.endTime!=""){
          let endTime =  new Date(this.search.endTime).formatToLocalDateTime("yyyy-MM-dd").toString();
          fromData.append("endTime",endTime);
        }
      this.$axios_supermall.post("/hnjxjy-core/eduStudentCourseDetail/list",fromData).then(resp=>{

        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;
      })
      });
    },

    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },

    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentCourseDetail/export",
        responseType: "blob",
        headers: objPar,
        params: {
          "siteId":this.search.siteId,
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleChange(val) {
    },
  },
};
</script>
<style scoped>
html {
  font-size: calc(100vw / 7.5);
}
.jobmain {
  display: flex;
}
/* 右边 */
.right {
  width: 100%;
  padding: 0.1rem;
}
/* 标题 */
.test {
  text-align: left;
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0.2rem;
  border-bottom: 0.01rem solid rgb(221, 221, 221);
}
/* 按钮 */
.buttons {
  display: flex;
}
/* form表单 */
.forms {
  display: flex;
  flex-wrap: wrap;
}
.forms section {
  width: 25%;
}
/* 学期 */
.semester {
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0.5rem;
}
.searchs {
  margin-right: 1.3rem;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}
/* 表格 */
.tables {
  margin-top: 1rem;
}
/* 多余内容呈现... */
.dian {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
/* 分页 */
/* .paging {
  margin-top: 1rem;
  width: 100%;
  overflow-x: scroll;
  display: flex;
}
.paging::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

@media (max-width: 75rem) {
  .left {
    display: none;
  }
  .rigtht {
    width: 100%;
  }
  .forms section {
    width: 100%;
  }
  .row {
    margin-left: -15px;
  }
  .semester {
    margin-left: 1rem;
    padding: 0.5rem 0;
  }
  .block {
    margin-left: 1rem;
  }
}
</style>
