<template>
  <div class="cost_stat">
    <div >
      <!-- 可删除标签 -->
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
      费用统计
      </div>
      <div style="margin-top: 10px">
        <div style="margin-left: 20px; margin-bottom: 10px;">
          <el-button type="primary" style="margin-left: 10px" size="mini" @click="handleSearch"
          >查询</el-button
          >
          <el-button type="primary" size="mini" v-permission="'eduStatFee:export'" @click="download"
          >导出</el-button
          >
        </div>
        <el-form inline class="demo-ruleForm">
          <el-form-item label-width="80px" label="函授站">
            <el-select
                style="width:200px"
                clearable
                v-model="search.siteId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in site"
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="80px" label="年份">
            <el-date-picker
                style="width: 210px"
                v-model="search.statYear"
                type="date"
                placeholder="选择年">
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="80px" label="年级">
            <el-select
                style="width: 210px"
                clearable
                v-model="search.gradeId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in option"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <div class="box_card">
        <!-- 表格 -->
        <div class="cost_stat_table">
          <el-table :data="costStatTable" style="width: 100%" border
                    size="medium"
                    height="600px"
                    max-height="600px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column
              prop="siteName"
              label="函数站名称"
            >
            </el-table-column>
            <el-table-column
              prop="siteCode"
              label="函数站代码"
            >
            </el-table-column>
            <el-table-column
              prop="totalAmount"
              label="应缴总额"
            >
              <template slot-scope="scope">
                <el-input-number
                    disabled="disabled"
                  size="mini"
                  v-model="scope.row.totalAmount"
                  controls-position="right"
                  :min="0"
                ></el-input-number>
              </template>
            </el-table-column>
            <!-- 表格 应缴订单数-->
            <!-- 表格 实缴总额-->
            <el-table-column
              prop="realAmount"
              label="实缴总额"
              sortable
              width="250"
            >
              <template slot-scope="scope">
                <el-input-number
                    disabled="disabled"
                  v-model="scope.row.realAmount"
                  controls-position="right"
                  :min="0"
                  size="mini"
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页器 -->
        <div class="cost_stat_Pager fixScrollbar">
          <div class="row">
            <div style="padding: 1rem 0"
            >
              <el-pagination
                @size-change="EachChange"
                @current-change="currentChange"
                :current-page="cost_statDefault"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


// //import "bootstrap/dist/css/bootstrap.min.css";
// //import "bootstrap/dist/js/bootstrap.min.js";
export default {

  created() {
    this.queryEduSite();
    this.gradList()
    this.handleSearch()
  },
  data() {
    return {
      // 可删除标签
      costStatTag: [
        {
          type: "",
          name: "费用统计",
        },
      ],
      //   input
      input: "",
      search:{
        statYear:"",
        siteId:"",
        gradeId:"",
        page:1,
        pageSize:10,
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value1: "",
      value: "",
      site:[],
      option:[],
      //   table表格
      costStatTable: [

      ],
      payableMoney: 0,
      payableNum: 0,
      paidinMoney: 0,
      paidinNum: 0,
      paidInRate: 0,
      //   分页器
      // 前往的默认页数
      cost_statDefault: 1,
      //  分页器总数据数
      total: 6,
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStatFee:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("siteId",this.search.siteId);
      let reg=new RegExp('-','g')
      if(this.search.statYear!=null&&this.search.statYear!=""){
        let year = this.search.statYear.formatToLocalDateTime().toString()
        this.search.statYear = year.substring(0,year.indexOf(' '))
        fromData.append("statYear",this.search.statYear.replace(reg,'/'));
      }
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduStatFee/list",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.costStatTable = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },
//查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
        // let year = this.search.statYear.formatToLocalDateTime().toString()
        this.$axios({
          method: "get",
          url: "hnjxjy-core/eduStatFee/export",
          responseType: "blob",
          headers: objPar,
          // params: {
          //   "siteId":this.search.siteId,
          //   "statYear":year.substring(0,year.indexOf('-')),
          //   "gradeId":this.search.gradeId,
          // },
        })
            .then((res) => {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              let url = window.URL.createObjectURL(blob);
              window.location.href = url;
              this.loadingView.close()
              this.$message.success("操作成功")
            })
            .catch((err) => {
            });
    },
    // Tag关闭
    async closeTag() {
      const closeTagconfirm = await this.$confirm("是否关闭页面?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (closeTagconfirm !== "confirm") {
        return this.$message.info("已取消!");
      }
      this.$router.push("/basic");
    },
    //   点击导出
    exportD() {
    },
    // 点击查询
    putLook() {
    },

    // 实缴总额
    paidInMoneyChange() {
    },
    // 实缴订单数
    paidInNumChange() {
    },
    // 缴费率%
    paidInRateChange() {
    },
    //   每页数据改变时
    EachChange(sizePage) {
      this.search.pageSize = sizePage;
      this.handleSearch();
    },
    // 当前页改变时
    currentChange(curentPage) {
      this.search.page = curentPage;
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
html {
  font-size: 16px !important;
}
/* 隐藏分页在火狐浏览器中存在的滚动跳 */
.fixScrollbar {
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}
.cost_stat {
  height: 100vh;
  font-size: 1rem;
}
/* 侧边栏 */
.left {
  width: 7%;
  display: inline-block;
}
/* 右侧主体 */
.rigth {
  width: 93%;
  height: calc(100vh - 3.3rem);
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
  padding: 1rem;
}
/* 可删除标签 */
.delBq {
  bottom: 0.1rem solid #ddd;
  text-align: left;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  margin: 15px;
  text-align: left;
}
/* 按钮 */
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.row div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row div .el-button {
  margin: 0.1rem 0;
}
.export_btn {
  margin: 0 0 0 0.5rem;
}
.cost_stat_btn {
  margin: 0.5rem 0 0 0;
}
/* input */
.search {
  text-align: left;
  padding: 1rem;
}
.input_check {
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
}
.input_check .row div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.input_check .row div span {
  width: 20%;
  text-align: left;
  padding: 0 0 0 5%;
}

.input_check .row div .el-select,
.input_check .row div .el-input {
  width: 80%;
  margin: 2% 2%;
  height: 3rem !important;
  line-height: 3rem !important;
}
.input_check .funZh >>> .el-input {
  width: 70%;
}
/* 表格 */
.cost_stat_table >>> .el-table th {
  padding: 0.3rem 0 !important;
}
.cost_stat_table >>> .el-table th > .cell {
  text-align: center !important;
}
.cost_stat_table >>> .el-table .cell {
  text-align: center !important;
}
.cost_stat_table >>> .el-icon-arrow-down {
  color: #000 !important;
}
.el-input-number--mini {
  width: 100%;
}
/* 分页器 */
.cost_stat_Pager {
  font-size: 1rem;
  width: 100%;
  background: #fff;
  overflow-x: scroll;
}
.cost_stat_Pager::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.cost_stat_Pager .row div{
  justify-content: center;
}
/* 对话框 */
.cost_stat_dialog >>> .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden !important;
}
.cost_stat_dialog >>> .el-table th > .cell {
  text-align: center !important;
}
.cost_stat_dialog >>> .el-table .cell {
  text-align: center !important;
}
.cost_stat_dialog >>> .el-table th {
  padding: 0.3rem 0 !important;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.table-wrap {
  overflow: auto;
  padding: 1rem;
}

.el-form-input-width {
  width: 100%;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>
