<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                未填报列表
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReportNStudent:export'" @click="download">导出</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReportNStudent:images'" @click="dialog=true">发送短信</el-button>
                    </el-form-item>
                    <br/>
                    <!--                    <el-form-item>-->
                    <!--                        <el-input v-model="queryBO.studentNameOrNum" placeholder="姓名/学号" style="width: 410px">-->
                    <!--                            <el-button type="primary" slot="append" icon="el-icon-search"-->
                    <!--                                       @click="queryBasic"></el-button>-->
                    <!--                        </el-input>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                        width="50px"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            prop="studentName"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            label="站点名称">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="startTime"
                            label="开始时间">
                    </el-table-column>
                    <el-table-column
                            prop="endTime"
                            label="结束时间">
                    </el-table-column>
                    <el-table-column
                            prop="studentPhone"
                            label="手机号">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="发送短信"
                :visible.sync="dialog"
                width="30%">
            <div>
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" @click="dialog = false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveAndClose">保存后关闭</el-button>
                    </el-form-item>
                </el-form>
                <el-form style="width: 100%" :model="saveAndCloseBO" :rules="rulesBySave" ref="ruleFormTwo">
                    <el-form-item label="短信内容" style="width: 100%" prop="message">
                        <el-input type="textarea" v-model="saveAndCloseBO.message" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="短信模板" style="width: 100%">
                        <el-select v-model="saveAndCloseBO.tempId" placeholder="placeholder">
                            <el-option
                                    v-for="item in modelList"
                                    :key="item.id"
                                    :label="item.tempName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="短信签名" style="width: 100%">
                        <el-select v-model="saveAndCloseBO.singnId" placeholder="placeholder">
                            <el-option
                                    v-for="item in msgSingList"
                                    :key="item.id"
                                    :label="item.signName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "fillOutStudent.vue",

        data() {
            return {
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    checkStatus: null,
                    studentNameOrNum: '',
                    enabled: null
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                totalOne: 0,//主table分页
                multipleSelection: [],
                saveAndCloseBO: {
                    message: '',
                    tempId: '',
                    singnId: ''
                },
                rulesBySave: {
                    message: [
                        {required: true, message: '请输入内容', trigger: 'blur'}
                    ]
                },
                dialog: false,
                msgSingList: [],//签名
                modelList: []//模板
            }
        },
        methods: {
            saveAndClose() {
                this.$refs['ruleFormTwo'].validate((valid) => {
                    if (valid) {
                        let bo ='?message='+this.saveAndCloseBO.message+'&tempId='+this.saveAndCloseBO.tempId+'&singnId='+this.saveAndCloseBO.singnId;
                        this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/sendOutMessageNoEduGraduateReportBySite'+ bo).then(response => {
                            if (response.data.code === 2000) {
                                this.$refs.ruleFormTwo.resetFields();
                                this.dialog = false;
                                this.queryBasic();
                            }
                        })
                    } else {
                        return false;
                    }
                })
            },
            download() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateReportSet/exportNoEduGraduateReportBySite",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '未填报列表.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            }
            ,
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGraduateReportNStudent:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryNoEduGraduateReportBySite?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                    }
                })
            }
            ,
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            },
            //endregion
            queryMessage() {
                this.$axios_supermall.get('/hnjxjy-core/eduMsgNew/queryEduMsgSignList').then(response => {
                    if (response.data.code === 2000) {
                        this.msgSingList = response.data.data;
                        this.saveAndCloseBO.singnId = this.msgSingList[0].id
                    }
                });
                this.$axios_supermall.get('/hnjxjy-core/eduMsgTemp/queryAllMsgTemp').then(response => {
                    if (response.data.code === 2000) {
                        this.modelList = response.data.data;
                        this.saveAndCloseBO.tempId = this.modelList[0].id;
                    }
                })
            }
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBasic();//基础查询
            this.queryMessage();
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
