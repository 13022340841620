<template>
  <div id="contain">
    <div>
      <div style="padding: 1rem; border-bottom: 0.0625rem solid #ddd; text-align: left">
        缴费规则
      </div>
      <div class="search">
        <el-button class="el-icon-refresh-right
       " type="primary" v-permission="'eduFeeRule:edit'" @click="edit">
          更新规则
        </el-button>
        <el-button @click="clears" class="el-icon-circle-close" v-permission="'eduFeeRule:clearRule'" type="primary">
          清空规则
        </el-button>
        <el-form :inline="true" class="demo-ruleForm" style="margin-top:1rem">
          <el-form-item label="* 缴费开始时间" style="margin-left: 30px">
            <el-date-picker style="width: 31.25rem;" v-model="bo.payStartTime" type="datetime" placeholder="选择日期时间（必填）">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="* 缴费结束时间">
            <el-date-picker style="width: 31.25rem" v-model="bo.payEndTime" type="datetime" placeholder="选择日期时间（必填）">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="缴费间隔时间（天）">
            <el-input-number style="width: 31.25rem" v-model="bo.intervalDay" controls-position="right"
              :min="1" :max="365">
            </el-input-number>
          </el-form-item>
          <el-form-item label="使用缴费规则" style="margin-left: 5px">
            <el-select v-model="bo.useGradeRule" selec style="width: 31.25rem" placeholder="请选择（必填）">
              <el-option v-for="item in options"  :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <div class="xf" style="">
          <p>缴费开始时间和缴费结束时间设置后，当前学校学费的在线缴费只能在设置的时间段进行缴费。</p>
          <p> 缴费间隔时间的设置就是在对应的间隔时间内只能进行一次在线学费缴费，缴费完成后则提示不能继续缴费。（管理员录入学费不限制）</p>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>


  export default {

    created() {
      this.handleSearch()
    },
    data() {
      return {
        options: [{

            value: 0,
            label: '否'
          },
          {
          value: 1,
          label: '是'
        }, ],
        bo:{
          id: null,
          payStartTime:"",
          payEndTime:"",
          intervalDay:1,
          useGradeRule:0,
        },
        value: '',
        num:1,

      }
    },
    methods:{
      handleSearch(){
        this.getPermission('eduFeeRule:getRule', () => {
        this.openLoadingView()
        this.$axios_supermall.get("/hnjxjy-core/eduFeeRule/getRule").then(resp => {
          if (resp.data.code == 2000) {
            this.bo.id=resp.data.data.id
            this.bo = resp.data.data
          }
        })
        });
      },
      clears(){
        this.$axios_supermall.get("/hnjxjy-core/eduFeeRule/clearRule?id="+this.bo.id).then(resp => {
          if (resp.data.code == 2000) {
            this.$message.success("操作成功")
            this.handleSearch()
          }else{
            this.$message.error("操作失败")
          }
        })
      },
      edit(){
        let formData = new FormData();
        formData.append("id",this.bo.id);
        if (null == this.bo.payStartTime || this.bo.payStartTime == ""){
          return this.$message.error("缴费开始时间不可为空!")
        }
        if (null == this.bo.payEndTime || this.bo.payEndTime == ""){
          return this.$message.error("缴费结束时间不可为空!")
        }
        if (null == this.bo.intervalDay){
          return this.$message.error("缴费间隔时间不可为空!")
        }
        if (null == this.bo.useGradeRule){
          return this.$message.error("使用年级规则不可为空!")
        }
        formData.append("payStartTime",this.dateFormat(this.bo.payStartTime,"/"));
        formData.append("payEndTime",this.dateFormat(this.bo.payEndTime,"/"));
        formData.append("intervalDay",this.bo.intervalDay);
        formData.append("useGradeRule",this.bo.useGradeRule);
        this.openLoadingView();
        this.$axios_supermall.post("/hnjxjy-core/eduFeeRule/edit",formData).then(resp => {
          if (resp.data.code == 2000) {
            this.handleSearch()
            this.$message.success("操作成功")
          }else{
            this.$message.error("操作失败")
            this.loadingView.close();
          }
        })
        .catch(err => {
          this.$message.error("操作失败")
          this.loadingView.close();
        })
      },
      dateFormat(time,format){
        // time 日期
        // format - 格式 或 / 格式
        let date=new Date(time);
        let year=date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        let month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        let day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
        let hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
        let minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
        let seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
        // 拼接
        return year+format+month+format+day+" "+hours+":"+minutes+":"+seconds;
      },
    }
  }
</script>
<style scoped>
  .right {
    float: right;
    width: 93%;
    display: inline-block;
    height: 54.375rem;
    overflow-y: scroll;
    /* background-color: #fff; */
    background-color: rgba(164, 174, 224, 0.11);
  }

  .left {
    width: 7%;
    display: inline-block;
  }

  .search {
    text-align: left;
    margin-top: 1rem;
    /* margin-left: 1rem; */
    padding: 1rem;
  }
  .xf{
    color: #00f;;
  }
</style>
