<template>
    <div>
        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                填报时间设置
            </div>
            <div class="search">
                <el-form inline label-width="50px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReport:insert'" @click="updateInfo">设置填报时间</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReport:updateTime'" @click="addDialogTrue">添加填报信息</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReport:en'" @click="activation">激活</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateReport:export'" @click="download">导出</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" @change="queryBasic"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" @change="queryBasic" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        width="50px"
                            type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="startTime"
                            label="开始时间">
                    </el-table-column>
                    <el-table-column
                            prop="endTime"
                            label="结束时间">
                    </el-table-column>
                    <el-table-column
                            prop="enableds"
                            label="激活状态">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="设置时间"
                :visible.sync="dialog"
                width="60%">
            <div>
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" @click="dialog = false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="commitUpdate">保存后关闭</el-button>
                    </el-form-item>
                </el-form>
                <el-form style="width: 100%" :model="updateBO" :rules="rulesByTime" ref="ruleForm">
                    <el-form-item label="开始-结束时间" style="width: 100%" prop="time">
                        <el-date-picker
                                v-model="updateBO.time"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog
                title="添加填报信息"
                :visible.sync="addDialog"
                width="30%">
            <div>
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" @click="addDialog = false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveAndClose">保存后关闭</el-button>
                    </el-form-item>
                </el-form>
                <el-form style="width: 100%" :model="saveAndCloseBO" :rules="rulesBySave" ref="ruleFormTwo">
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="saveAndCloseBO.gradeId" placeholder="请选择年级"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in gradeListAdd"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="saveAndCloseBO.stageId" placeholder="请选择层次"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in stageListAdd"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="saveAndCloseBO.profId" placeholder="请选择专业"
                                   style="width: 300px">
                            <el-option
                                    v-for="item in profListAdd"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始-结束时间" style="width: 100%" prop="time">
                        <el-date-picker
                                v-model="saveAndCloseBO.time"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>



    export default {

        name: "fillInSetting",

        data() {
            return {
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null//专业id
                },
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                totalOne: 0,//主table分页
                dialog: false,
                updateBO: {
                    ids: '',
                    time: []
                },
                multipleSelection: [],
                rulesByTime: {
                    time: [
                        {type: 'array', required: true, message: '请选择日期', trigger: 'change'}
                    ]
                },
                addDialog: false,
                saveAndCloseBO: {
                    gradeId: null,
                    stageId: null,
                    profId: null,
                    time: []
                },
                gradeListAdd: [],//年级下拉框列表
                stageListAdd: [],//层次下拉框列表
                profListAdd: [],//专业下拉框列表
                rulesBySave: {
                    time: [
                        {type: 'array', required: true, message: '请选择日期', trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            activation(){
                if (this.multipleSelection==null||this.multipleSelection.length<=0){
                    this.$message.warning("请至少选择一项");
                    return;
                }
                let ids = '';
                this.multipleSelection.forEach(m => {
                    ids += m.id + ','
                });
                ids = ids.substring(0, ids.length - 1);
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/activationServiceEduGraduateReportSet',{ids:ids}).then(response=>{
                    if (response.data.code === 2000){
                        this.$message.success("激活成功");
                        this.queryBasic();
                    }
                })
            },
            saveAndClose() {
                this.$refs['ruleFormTwo'].validate((valid) => {
                    if (valid) {
                        let bo = {
                            gradeId:this.saveAndCloseBO.gradeId,
                            stageId:this.saveAndCloseBO.stageId,
                            profId:this.saveAndCloseBO.profId,
                            startTime: this.saveAndCloseBO.time[0].formatToLocalDateTime('yyyy-MM-dd HH:mm:ss'),
                            endTime: this.saveAndCloseBO.time[1].formatToLocalDateTime('yyyy-MM-dd HH:mm:ss'),
                        };
                        this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/addEduGraduateReportSet',bo).then(response=>{
                            if (response.data.code === 2000){
                                this.$refs.ruleFormTwo.resetFields();
                                this.addDialog = false;
                                this.queryBasic();
                            }
                        })
                    } else {
                        return false;
                    }
                })
            },
            download() {
              this.openLoadingView()
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateReportSet/exportAllEduGraduateReportSet",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '填报时间设置列表.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.loadingView.close()
                  this.$message.success("操作成功")
                })
            }
            ,
            commitUpdate() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        let ids = '';
                        this.multipleSelection.forEach(m => {
                            ids += m.id + ','
                        });
                        ids = ids.substring(0, ids.length - 1);
                        let bo = {
                            "ids": ids, //学生id字符串
                            startTime: this.updateBO.time[0].formatToLocalDateTime('yyyy-MM-dd HH:mm:ss'),
                            endTime: this.updateBO.time[1].formatToLocalDateTime('yyyy-MM-dd HH:mm:ss'),
                        };
                        this.openLoadingView();
                        this.$axios_supermall.post('/hnjxjy-core/eduGraduateReportSet/updateServiceEduGraduateReportSet', bo).then(response => {
                            if (response.data.code === 2000) {
                                this.$message.success("处理成功");
                                this.dialog = false;
                                this.queryBasic();
                                this.$refs.ruleForm.resetFields();
                            }
                        })
                    } else {
                        return false;
                    }
                });
            }
            ,
            //region  修改信息
            updateInfo() {
                if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
                    this.$message.warning("至少选择一项");
                    return;
                }
                this.dialog = true;
                this.updateBO.time = [];
            }
            ,
            //endregion
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            }
            ,
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduGraduateReport:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.gradeId)) {
                  bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                  bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                  bo += '&profId=' + this.queryBO.profId;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateReportSet/queryAllEduGraduateReportSet?' + bo).then(response => {
                  if (response.data.code === 2000) {
                    this.tableData = response.data.data.list;
                    this.totalOne = response.data.data.total;
                  }
                })
              });
            }
            ,
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = JSON.parse(JSON.stringify(response.data.data));
                        this.profListAdd = response.data.data;
                        this.saveAndCloseBO.profId = this.profList[0].id;
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                        this.stageListAdd = response.data.data;
                        this.saveAndCloseBO.stageId = this.stageListAdd[0].id;
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            }
            ,
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                        this.gradeList.unshift({id: null, gradeName: "全部"});
                        this.gradeListAdd = response.data.data;
                        this.saveAndCloseBO.gradeId = this.gradeListAdd[0].id;
                    }
                })
            }
            ,
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            }
            ,
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            },
          addDialogTrue(){
            this.saveAndCloseBO = {
              gradeId: null,
              stageId: null,
              profId: null,
              time: []
            }
            this.addDialog = true;
          },
            //endregion
        },
        mounted() {
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBasic();//基础查询
        }
    }
</script>

<style scoped>

    .left {
        width: 7%;
        display: inline-block;
    }

    .yingyong {
        margin-right: 100px;
        text-align: left;
    }

    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .add {
        text-align: left im !important;
    }

    .yingyong {
        font-weight: bold;
        color: #555;
    }

    #aaaaa {
        color: lightskyblue;
    }

    .top_btn {
        margin: 10px 10px 10px 10px;
        text-align: left;
    }

    .search {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }

    .main_contain {
        margin: 20px 10px 30px 10px;
        text-align: left;
    }
</style>
